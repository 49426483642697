import React from "react"

const StrikeSVG = (props: React.SVGProps<any>) => (
  <svg
    width="52"
    height="26"
    viewBox="0 0 52 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M48.7993 25.4331C43.2186 22.6708 37.6755 19.8346 32.0554 17.1584C23.8831 13.2685 15.724 9.3326 7.07701 6.54063C5.32492 5.97447 3.57939 5.39397 1.82721 4.83645C0.453965 4.39564 -0.132345 3.5333 0.111426 2.2648C0.316253 1.19981 1.19822 0.578687 1.97667 1.15564C4.67505 3.14781 8.01172 3.55706 10.9997 4.82239C16.6053 7.19246 22.2289 9.52304 27.7933 11.9915C31.8552 13.7952 35.8547 15.7526 39.8428 17.7179C43.1399 19.3457 46.3813 21.087 49.6473 22.783C49.8371 22.8828 50.0414 22.9848 50.1776 23.1512C50.7115 23.8073 51.6702 24.4796 50.9258 25.4586C50.5698 25.9252 49.4982 25.7871 48.7511 25.9187C48.7672 25.7569 48.7864 25.5964 48.8025 25.4345L48.7993 25.4331Z"
      fill="white"
    />
  </svg>
)

export default StrikeSVG
