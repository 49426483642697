import {
  Button,
  Menu,
  MenuItem,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { ToolType } from "api/resources/aiTools/types"
import AIspark from "assets/AITools/ai-sparkle.webp"
import clsx from "clsx"
import ErrorPage404 from "components/ErrorPage404"
import TopHeadingBar from "components/TopHeadingBar"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import DynamicContainer from "features/Home/DynamicContainer"
import { useFeatureToggle } from "global/FeatureToggleProvider"
import { PlusCircleSolid } from "iconoir-react"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import styles from "./AItools.module.css"
import Card from "./components/Card"
import History from "./components/History"
import HomeSidebar from "./components/History/HomeSidebar"
import { getToolName } from "./helper"
import ClaimProTrial from "./PRO/ClaimProTrial"

const tools = [
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.lessonPlan)} Generator`,
    description: "Generate engaging lesson plans in seconds",
    to: routes.aiTools.lessonPlan,
    gradient: "#E5A256",
    isPro: true,
    isProAvailable: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.rubrics)} Generator`,
    description: "Generate custom rubrics for precise assignment evaluation",
    to: routes.aiTools.rubrics,
    gradient: "#49B7CC",
    isPro: true,
    isProAvailable: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.quiz)} Generator`,
    description: "Generate accurate quizzes that enhance student learning",
    to: routes.aiTools.quiz,
    gradient: "#97BC62",
    isPro: true,
    isProAvailable: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.handout)} Generator`,
    description: "Generate informative lesson handouts effortlessly",
    to: routes.aiTools.handout,
    gradient: "#60A5FA",
    isFree: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.narration)} Generator`,
    description: "Transform your lesson plans into engaging narratives",
    to: routes.aiTools.narration,
    isFree: true,
    gradient: "#42A491",
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.subjectiveAssessment)} Generator`,
    description: "Generate highly relevant subjective assessments",
    to: routes.aiTools.subjectiveAssessment,
    gradient: "#9A7BBA",
    isPro: true,
    isProAvailable: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.assignment)} Generator`,
    description: "Generate assignments tailored to your lesson objectives",
    to: routes.aiTools.assignment,
    gradient: "#EEC239",
    isPro: true,
    isProAvailable: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.coverLetter)} Generator`,
    description: "Grab recruiters’ attention with customized cover letters",
    to: routes.profile.self.concat("?action=generateCoverLetter"),
    gradient: "#496AC9",
    isPro: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.activityPlanner)} Generator`,
    description: "Plan engaging classroom activities easily",
    to: routes.aiTools.activityPlanner,
    gradient: "#FEAE8D",
    isPro: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.videoSummary)} Generator`,
    description: "Generate concise YouTube video summaries in seconds",
    to: routes.aiTools.videoSummary,
    gradient: "#D86565",
    isPro: true,
  },
  {
    title: "Suraasa AI",
    subtitle: `${getToolName(ToolType.unitPlanner)} Generator`,
    description: "Draft comprehensive unit plans faster than ever",
    to: routes.aiTools.unitPlanner,
    gradient: "#E478B5",
    isPro: true,
  },
]

const AItools = () => {
  const navigate = useNavigate()

  const featureToggle = useFeatureToggle()
  const { isActive } = useSuraasaPlusContext()

  const isAIToolsEnabled = featureToggle.aiTools.isEnabled

  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  if (!isAIToolsEnabled) {
    return <ErrorPage404 />
  }

  return (
    <div className={clsx(styles.pageContainer, "flex flex-col")}>
      <TopHeadingBar
        heading={
          <span className="flex">
            AI Tools
            <img src={AIspark} alt="" className="ml-0.5 h-3" />
          </span>
        }
        endSlot={
          <Menu
            menuButton={
              <Button
                variant="text"
                size="lg"
                startAdornment={<PlusCircleSolid />}
              >
                Generate New
              </Button>
            }
          >
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.lessonPlan)
              }}
            >
              {getToolName(ToolType.lessonPlan)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.rubrics)
              }}
            >
              {getToolName(ToolType.rubrics)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.quiz)
              }}
            >
              {getToolName(ToolType.quiz)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.handout)
              }}
            >
              {getToolName(ToolType.handout)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.narration)
              }}
            >
              {getToolName(ToolType.narration)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.subjectiveAssessment)
              }}
            >
              {getToolName(ToolType.subjectiveAssessment)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.assignment)
              }}
            >
              {getToolName(ToolType.assignment)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.activityPlanner)
              }}
            >
              {getToolName(ToolType.activityPlanner)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.videoSummary)
              }}
            >
              {getToolName(ToolType.videoSummary)}
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(routes.aiTools.unitPlanner)
              }}
            >
              {getToolName(ToolType.unitPlanner)}
            </MenuItem>
          </Menu>
        }
      />
      <div className="relative h-full grow pb-10 md:pb-0">
        <DynamicContainer className="relative ">
          <ClaimProTrial className="mt-2" />
          <div>
            <div className="mb-3 mt-2 grid grid-cols-1 gap-1.5 sm:mb-6 sm:grid-cols-2 xl:grid-cols-3">
              {tools.map(i => (
                <Card {...i} key={i.subtitle} isProSubscribed={isActive} />
              ))}
            </div>
          </div>
          {isSmDown && <History />}
        </DynamicContainer>

        {!isSmDown && <HomeSidebar />}
      </div>
    </div>
  )
}

export default AItools
