import { Button, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { Xmark } from "iconoir-react"
import React from "react"
import { useNavigate } from "react-router-dom"

import PlusWhiteSVG from "./assets/PlusWhiteSVG"

const Card: React.FC<React.PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => (
  <div
    className={clsx(
      "flex w-full flex-col gap-2 rounded-2xl border border-solid border-onSurface-700 bg-onSurface-800 px-3 py-2",
      className
    )}
  >
    {children}
  </div>
)
const Item = ({ title }: { title: string }) => (
  <div className="flex items-center gap-1">
    <div className="h-0.25 w-1 rounded-full bg-decorative-one-100" />
    <Typography variant="smallBody">{title}</Typography>
  </div>
)

const Congratulations = ({ handleClose }: { handleClose: () => void }) => {
  const navigate = useNavigate()

  const powers = [
    "Access to 11 Advanced AI Teaching Tools",
    "Matching with your school's books & curriculum",
    "Easy to use - as simple as filling out a Google Form!",
    "Downloadable for further use or editing in Microsoft Word / Google Docs",
  ]

  return (
    <div className=" relative bg-[#0F172A] bg-[radial-gradient(circle_at_0%_-30%,rgba(7,206,136,0.8)_0px,transparent_50%),radial-gradient(circle_at_50%_-40%,rgba(37,75,198,1)_0px,transparent_50%),radial-gradient(circle_at_100%_-25%,rgba(146,88,168,0.8)_0px,transparent_50%)] p-3 text-white">
      <button
        onClick={handleClose}
        className="absolute right-2 top-3 grid size-[30px] place-items-center text-white"
      >
        <Xmark className="size-3" />
      </button>
      <span className="relative mb-0.5 text-base font-extrabold leading-6 tracking-[-0.02em] text-white">
        Suraasa Plus
        <PlusWhiteSVG className="absolute -right-1 -top-0.25 size-[11.28px]" />
      </span>
      <Typography variant="smallBody" className="mb-3">
        Teaching Made Easier
      </Typography>
      <Typography variant="title1" className="mb-1.5">
        You now have the Plus Advantage! 😎
      </Typography>
      <Typography variant="largeBody" className="mb-1.5">
        Your new superpowers:
      </Typography>
      <div className="flex flex-col gap-2">
        <Card>
          <div className="flex flex-col gap-2">
            <Typography variant="title4" className="mb-2">
              Access to Pro AI Teaching Tools
            </Typography>
            {powers.map(item => (
              <Item title={item} key={item} />
            ))}
          </div>
        </Card>
        <Card>
          <Typography variant="title4">
            Access to 150+ hours of Recorded Masterclasses by International
            Teaching Experts
          </Typography>
        </Card>
        <Card>
          <Typography variant="title4">
            Certificates on attending Live Masterclasses
          </Typography>
        </Card>
        <Button
          className="rounded-full"
          size="sm"
          onClick={() => {
            navigate("/ai-tools?tab=All")
            handleClose()
          }}
        >
          Explore Suraasa Plus
        </Button>
      </div>
    </div>
  )
}

export default Congratulations
