import { Button } from "@suraasa/placebo-ui"
import React from "react"
import { trackingService } from "utils/tracking"

const PosthogTest = () => {
  return (
    <Button
      onClick={() => {
        trackingService.trackEvent(
          "TEST_ADBLOCK_EVENT_1",
          {
            extraPayload: "extraPayload",
          },
          ["PostHog"]
        )
      }}
    >
      Test PostHog Event
    </Button>
  )
}

export default PosthogTest
