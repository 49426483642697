import { Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import Monitor from "assets/Fallback/monitor.svg"
import Fallback from "components/Fallback"
import { learningItemSlugs } from "utils/constants"

import { useQualificationDetails } from ".."
import AcademicsGuide from "./AcademicsGuide"
import CourseWiseBreakDown from "./CourseWiseBreakDown"
import Graph from "./Graph"
import HowGraphWorks from "./HowGraphWorks"
import OverviewItem from "./OverviewItem"
import { getAdjustedScore } from "./scoreHelpers"
import Weightage from "./Weightage"

const GradeBook = () => {
  const { qualification } = useQualificationDetails()

  const { data, isLoading, isError } = useQuery({
    enabled: Boolean(qualification.id),
    queryFn: () =>
      api.learningItems.retrieveGradeBookScore({
        urlParams: {
          qualificationId: qualification.id,
        },
      }),
    queryKey: queries.learningItems.retrieveGradeBookScore(qualification.id)
      .queryKey,
  })

  if (isError || isLoading) {
    return (
      <Fallback
        data={{
          title: "Something went wrong",
          description: "Please contact care@suraasa.com",
          image: Monitor,
        }}
        isLoading={isLoading || !data}
        hasError={isError}
        className="pt-4"
      />
    )
  }

  return (
    <div className="flex flex-col pb-10">
      <Typography variant="title3" className="mb-2">
        Gradebook
      </Typography>
      <div className="mb-3 flex flex-col items-center gap-2 sm:flex-row">
        <OverviewItem
          title="Overall GPA"
          value={
            data.score
              ? getAdjustedScore(data.score.gpa, qualification.slug).toFixed(1)
              : null
          }
        />

        <OverviewItem
          title="Total Credits Earned"
          value={data.score?.creditsEarned?.toFixed(0) || null}
        />
      </div>
      {/* <HowGraphWorks className="mb-1" /> */}
      {/* <Graph className="mb-3" data={data.items} /> */}
      <CourseWiseBreakDown
        className="mb-3"
        items={data.items}
        slug={qualification.slug}
      />
      <AcademicsGuide className="mb-3" slug={qualification.slug} />
      <Weightage className="mb-3" />
    </div>
  )
}

export default GradeBook
