import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import {
  RubricsProForm,
  RubricType,
  ToolType,
} from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import {
  criteriaLevelOptions,
  getToolName,
  proPointScaleOptions,
  rubricsTypeOptions,
} from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect } from "react"
import { Controller, useForm } from "react-hook-form"

import { RubricsReturnType } from "../useRubrics"

const toolType = ToolType.rubricsPro

type Props = Pick<RubricsReturnType, "generateProTool">

const ProForm = ({ generateProTool }: Props) => {
  const {
    register,
    control,
    setError,
    resetField,
    clearErrors,
    setValue,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RubricsProForm>({
    defaultValues: {
      grade: "6th",
      curriculum: "CBSE",
      objective:
        "Evaluate creativity of stories written using literary techniques",
      rubricType: rubricsTypeOptions[0].value,
      pointOfScale: 5,
      criteriaLevel: 5,
      assignmentDescription:
        "Students will write original short stories inspired by a theme or prompt, using literary techniques like imagery and dialogue. They should focus on clear structure, character/setting development, and personal voice.",
    },
  })

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    ensurePlusAccess(async () => {
      await generateProTool.mutateAsync({ data, setError })
    })
  })

  const rubricType = watch("rubricType")

  useEffect(() => {
    resetField("pointOfScale")
    clearErrors("pointOfScale")
    setValue("pointOfScale", undefined)

    resetField("criteriaLevel")
    clearErrors("criteriaLevel")
    setValue("criteriaLevel", undefined)
  }, [rubricType])

  return (
    <>
      <Paper>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
            <TextField
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Class/Grade"
              placeholder="Ex: 6th Grade"
              errors={errors.grade?.message}
            />
            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Curriculum"
              placeholder="Ex: CBSE"
              errors={errors.curriculum?.message}
            />

            <TextField
              {...register("objective", {
                required: { value: true, message: "Required" },
              })}
              required
              placeholder="Ex: Evaluate creativity of stories written using literary techniques"
              label="Standard/Objective"
              errors={errors.objective?.message}
            />
            <Controller
              control={control}
              name="rubricType"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.rubricType?.message}
                  label="Rubric Type"
                  options={rubricsTypeOptions}
                  placeholder="Ex: Analytic"
                  mountOnBody
                  onBlur={onBlur}
                  value={rubricsTypeOptions.find(c => c.value === value)}
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          </div>

          {rubricType && rubricType !== RubricType.singlePoint && (
            <Controller
              control={control}
              name="pointOfScale"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  required
                  errors={errors.pointOfScale?.message}
                  label="Performance Level"
                  options={proPointScaleOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    proPointScaleOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
          )}

          {rubricType && rubricType !== RubricType.holistic && (
            <Controller
              control={control}
              name="criteriaLevel"
              render={({ field: { value, onChange, onBlur } }) => (
                <Select
                  errors={errors.criteriaLevel?.message}
                  label="Criteria Level"
                  options={criteriaLevelOptions}
                  placeholder="Ex: 5"
                  mountOnBody
                  onBlur={onBlur}
                  value={
                    criteriaLevelOptions.find(c => c.value === value) ?? null
                  }
                  onChange={val => onChange(val?.value)}
                />
              )}
            />
          )}

          <TextArea
            {...register("assignmentDescription", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Description"
            placeholder={`Ex: Students will write original short stories inspired by a theme or prompt, using literary techniques like imagery and dialogue. They should focus on clear structure, character/setting development, and personal voice. The assignment includes drafting, revising, and editing. Rubrics should assess creativity, technique, structure, and revisions.`}
            errors={errors.assignmentDescription?.message}
            rows={6}
          />

          <Button type="submit" loading={isSubmitting}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
    </>
  )
}

export default ProForm
