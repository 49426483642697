import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import { QuizForm, ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { Controller, useForm } from "react-hook-form"

import { QuizReturnType } from "../useQuiz"

const NUMBER_OF_QUESTION_CHOICES = [
  { value: 5, label: "5" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
]

const toolType = ToolType.quiz

type Props = Pick<QuizReturnType, "generateTool">

const BasicForm = ({ generateTool }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<QuizForm>({
    defaultValues: {
      grade: "UKG",
      curriculum: "CBSE",
      numberOfQuestions: 5,
      description: "Action Words",
      objective:
        "Students should be able to differentiate action words from other parts of speech and apply them appropriately.",
    },
  })

  const onSubmit = handleSubmit(async data => {
    await generateTool.mutateAsync({ data, setError })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex flex-col gap-2">
        <TextField
          {...register("grade", {
            required: { value: true, message: "Required" },
          })}
          required
          label="Grade"
          placeholder="Ex: UKG"
          errors={errors.grade?.message}
        />
        <TextField
          {...register("curriculum", {
            required: { value: true, message: "Required" },
          })}
          required
          label="Curriculum"
          placeholder="Ex: CBSE"
          errors={errors.curriculum?.message}
        />

        <Controller
          control={control}
          name="numberOfQuestions"
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              required
              label="Number of Questions"
              errors={errors.numberOfQuestions?.message}
              options={NUMBER_OF_QUESTION_CHOICES}
              placeholder="Ex: 10"
              value={NUMBER_OF_QUESTION_CHOICES.find(c => c.value === value)}
              mountOnBody
              onBlur={onBlur}
              onChange={val => onChange(val?.value)}
            />
          )}
          rules={{ required: { value: true, message: "Required" } }}
        />
        <TextArea
          rows={4}
          required
          {...register("description", {
            required: { value: true, message: "Required" },
          })}
          label="Assessment Description"
          placeholder="Ex: Action Words"
          errors={errors.description?.message}
        />
        <TextArea
          rows={4}
          required
          {...register("objective", {
            required: { value: true, message: "Required" },
          })}
          label="Learning Objectives"
          placeholder="Ex: Students should be able to differentiate action words from other parts of speech and apply them appropriately."
          errors={errors.objective?.message}
        />

        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
