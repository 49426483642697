import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import InputDisplay from "../../helpers/InputDisplay"
import { UnitPlannerReturnType } from "../../UnitPlanGenerator/useUnitPlanner"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"

type Props = Pick<
  UnitPlannerReturnType,
  | "output"
  | "isLoading"
  | "isPositiveResponse"
  | "currentResponseId"
  | "setCurrentResponseId"
  | "regenerateResponse"
  | "overviewData"
  | "responseIds"
  | "userVote"
  | "onBack"
>
const UnitPlanOutput = (props: Props) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props
  // const { overviewData, refetchOverviewData } = useContext(AIToolsContext)

  // if (!overviewData)
  //   return (
  //     <div className="flex flex-col items-center justify-center p-5">
  //       <CircularProgress />
  //     </div>
  //   )

  // const { unitPlanResponses, unitDivisionResponses } = overviewData

  // const getContent = () => {
  //   if (!unitPlanResponses || !unitDivisionResponses)
  //     throw new Error(
  //       "unitPlanResponses and unitDivisionResponses should not be undefined"
  //     )

  //   const outputMode = getOutputMode({
  //     unitPlanResponses,
  //     unitDivisionResponses,
  //   })

  //   if (outputMode === "unitPlanTopic") {
  //     return <UnitPlanTopicOutput refetchPromptData={refetchOverviewData} />
  //   }

  //   if (outputMode === "unitPlanIntermediate") {
  //     return (
  //       <UnitPlanIntermediateOutput
  //         unitDivisionResponses={unitDivisionResponses}
  //         refetchPromptData={refetchOverviewData}
  //       />
  //     )
  //   }

  //   if (outputMode === "unitPlanDivision") {
  //     return (
  //       <UnitPlanDivisionOutput
  //         unitPlanResponses={unitPlanResponses}
  //         refetchPromptData={refetchOverviewData}
  //       />
  //     )
  //   }

  //   return (
  //     <div className="flex flex-col items-center justify-center p-5">
  //       <CircularProgress />
  //     </div>
  //   )
  // }

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            title: overviewData.title,
            subject: overviewData.subject,
            book: overviewData.book,
            topic: overviewData.topic,
            grade: overviewData.grade,
            country: overviewData.country,
            curriculum: overviewData.curriculum,
            classDuration: overviewData.classDuration || "",
            unitPlanType: overviewData.unitPlanType,
            semesterDuration: overviewData.semesterDuration,
            numberOfDays: overviewData.numberOfDays,
            unitPlanResponses: overviewData.unitPlanResponses,
            //Handle This
            responseIds: [],
            unitDivisionResponses: [],
          }}
        />
      )}
      <Paper
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div className={clsx("overflow-x-auto")}>
          <ToolOutput isLoading={isLoading} onClick={onBack}>
            {output}
          </ToolOutput>
        </div>
      </Paper>
      {/* <UnitPlanTopicOutput /> */}
    </div>
  )
}

export default UnitPlanOutput
