import "katex/dist/katex.min.css"

import { Typography } from "@suraasa/placebo-ui"
import Markdown from "markdown-to-jsx"
import Latex from "react-latex-next"

type Props = {
  children?: string
  showTitle?: boolean
  isLoading?: boolean
}

const MarkdownRenderer = (props: Props) => {
  if (!props.children) return null

  let data = props.children

  const regexToFilter = /#+\s*[Tt]itle\s*\n([^\n]+)/
  const titleMatch = data.match(regexToFilter)

  const regexForToken =
    /\[\[__STOP__\]\]|\[\[__ERROR__\]\]|\[\[__BREAK__\]\]|\[\[__END__\]\]|\{'status':\s*'success'\}|\{'status':\s*'failed'\}/g
  const tokenMatch = data.match(regexForToken)

  if (titleMatch && !props.showTitle) {
    data = data.replace(regexToFilter, "")
  }
  if (tokenMatch) {
    data = data.replace(regexForToken, "")
  }

  const chunks = data.split("$$")

  let newText = ""
  chunks.forEach((chunk, index) => {
    if (index % 2 === 0) newText += chunk
    // Adding backticks so markdown renderer treats it as a code block
    else newText += `\`\`\`${chunk}\`\`\``
  })

  return (
    <>
      <Markdown
        options={{
          renderRule(next, node) {
            // 3 means RuleType.codeBlock || 5 means RuleType.inlineCode
            if (node.type === "3" || node.type === "5") {
              if (node.text.startsWith("*")) {
                return <p>{node.text.replace(/\*\*/g, "")}</p>
              }
              return <Latex>{String.raw`$$${node.text}$$`}</Latex>
            }
            if (next() === "`") return ""
            return next()
          },
          overrides: {
            h1: {
              component: Typography,
              props: {
                variant: "title1",
                className: "mt-4",
              },
            },
            h2: {
              component: Typography,
              props: {
                variant: "title2",
                className: "mt-3",
              },
            },
            h3: {
              component: Typography,
              props: {
                variant: "title3",
                className: "mt-2 mb-0.5",
              },
            },
            h4: {
              component: Typography,
              props: {
                variant: "title4",
                className: "mt-1",
              },
            },
            h5: {
              component: Typography,
              props: {
                variant: "largeBody",
                className: "mt-1",
              },
            },
            h6: {
              component: Typography,
              props: {
                variant: "strong",
              },
            },
            body: {
              component: Typography,
              props: {
                variant: "body",
                className: "mt-1",
              },
            },
            strong: {
              component: Typography,
              props: {
                variant: "strong",
                className: "mt-1 inline",
              },
            },
            li: {
              component: "li",
              props: {
                className: "mt-1",
              },
            },
            ul: {
              component: "ul",
              props: {
                style: {
                  paddingInlineStart: "20px",
                  listStyleType: "disc",
                },
                className: "mb-1.5",
              },
            },
            ol: {
              component: "ul",
              props: {
                style: {
                  paddingInlineStart: "20px",
                  listStyleType: "decimal",
                },
                className: "mb-1.5",
              },
            },
            p: {
              component: Typography,
              props: {
                variant: "body",
                className: "mt-0.5",
              },
            },
          },
        }}
      >
        {newText}
      </Markdown>
    </>
  )
}

export default MarkdownRenderer
