import Paper from "../../../Paper"
import InputDisplay from "../../helpers/InputDisplay"
import { QuizReturnType } from "../../QuizGenerator/useQuiz"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"

const QuizOutput = (
  props: Pick<
    QuizReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props

  const isPro =
    (overviewData?.numberOfIndividualQuestions || 0) > 0 ||
    (overviewData?.numberOfComprehensions || 0) > 0

  if (isPro && overviewData?.numberOfQuestions) {
    overviewData.numberOfQuestions = 0
  }

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            numberOfIndividualQuestions:
              overviewData.numberOfIndividualQuestions,
            numberOfQuestions: overviewData.numberOfQuestions,
            curriculum: overviewData.curriculum,
            grade: overviewData.grade,
            topic: overviewData.topic,
            country: overviewData.country || "",
            subject: overviewData.subject || "",
            book: overviewData.book,
            objective: overviewData.objective || "",
            numberOfComprehensionQuestions:
              overviewData.numberOfComprehensionQuestions,
            numberOfComprehensions: overviewData.numberOfComprehensions,
            difficultyLevel: overviewData.difficultyLevel,
            isPro: overviewData.isPro,
            title: overviewData.title || "",
            responseIds: overviewData.responseIds || [],
            //To handle this
            unitDivisionResponses: [],
            classDuration: "",
          }}
        />
      )}
      <Paper
        className="overflow-x-auto p-0"
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div>
          <ToolOutput isLoading={isLoading} onClick={onBack}>
            {output}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default QuizOutput
