import { Country, State } from "../global/types"
import {
  Award,
  Certification,
  Gender,
  Interest,
  LanguageResponse,
  Project,
  Publication,
  Qualification,
  Skill,
  SubjectResponse,
  TestScore,
  WorkExperienceType,
  WorkLocationInterest,
} from "../profile/types"
import { PhoneNumber } from "../users/types"

export enum ToolType {
  lessonPlan = "lesson_plan",
  lessonPlanPro = "lesson_plan_pro",
  rubrics = "rubrics",
  rubricsPro = "rubrics_pro",
  quiz = "quiz",
  quizPro = "quiz_pro",
  handout = "handout",
  narration = "narration",
  subjectiveAssessment = "subjective_assessment",
  subjectiveAssessmentPro = "subjective_assessment_pro",
  assignment = "assignment",
  assignmentPro = "assignment_pro",
  activityPlanner = "activity_planner",
  videoSummary = "video_summary",
  unitPlanner = "unit_plan",
  coverLetter = "cover_letter",
}

export type LessonPlanForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  grade: string
  topic: string
  duration: string
}

export type LessonPlanProForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  numberOfClasses?: string
  grade: string
  book?: string
  topic?: string
  duration: string
}

export type LessonPlanPromptData = {
  id: number
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  country: string
  curriculum: string
  subject: string
  grade: string
  topic: string
  duration: string
  title: string | undefined
  book: string | null | undefined
  numberOfClasses: number | null
  isPro: boolean
  responseIds: {
    id: number
    responseIds: number[]
    narrationIds: {
      id: number
      responseIds: number[]
    }[]
    handoutIds: {
      id: number
      responseIds: number[]
    }[]
  }[]
}
export type LessonPlanResponse = {
  id: number
  responseId: number
  subject: string
  country: string
  curriculum: string
  grade: string
  topic: string
  duration: string
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
}
export type RetrieveLessonPlan = {
  id: number
  output: string
  isPositiveResponse: boolean | null
  promptData: number
  isPro: boolean
}

export type LessonPlanProResponse = {
  id: number
  responseIds: number[]
  subject: string
  country: string
  curriculum: string
  grade: string
  duration: string
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  topic: string
  numberOfClasses: number
  book: string
}

// export type LessonPlanResponseGenerate = {
//   id: number
//   title: string
//   subject: string
//   country: string
//   curriculum: string
//   grade: string
//   topic: string
//   duration: string
//   bloom_taxonomy_level: BloomTaxonomyLevels[]
// }

export type RubricsForm = {
  objective: string
  grade: string
  assignmentDescription: string
  pointOfScale: number
  curriculum: string
  additionalCustomisation?: string | null
}

export type RubricsProForm = {
  objective: string
  assignmentDescription: string
  grade: string
  pointOfScale?: number | null
  curriculum: string
  rubricType: RubricType
  criteriaLevel?: number | null
}

export type RubricsPromptData = {
  id: number
  objective?: string
  assignmentDescription?: string
  grade?: string
  pointOfScale?: number | null
  criteriaLevel?: number | null
  curriculum?: string
  responseIds?: number[]
  title?: string
  isPro?: boolean
  rubricType?: RubricType
}
export type RubricsGenerateResponse = {
  id: number
  objective: string
  grade: string
  pointOfScale: number
  curriculum: string
  responseId: number
}
export type RubricsGenerateProResponse = {
  id: number
  objective: string
  grade: string
  pointOfScale: number | null
  criteriaLevel: number | null
  curriculum: string
  rubricType: RubricType
  responseId: number
}
export type RubricsResponse = {
  id: number
  isPositiveResponse: boolean | null
  output: string
  promptData: number
}

export type DifficultyLevel = "easy" | "medium" | "difficult" | "expert"

export enum RubricType {
  holistic = "holistic",
  analytic = "analytic",
  singlePoint = "single_point",
}

export type RubricsProResponse = {
  id: number
  objective: string
  grade: string
  pointOfScale: number
  curriculum: string
  rubricType: RubricType
  title: string
  output: string
}

export type QuizForm = {
  numberOfQuestions: number
  curriculum: string
  grade: string
  description: string
  objective?: string
}

export type QuizProForm = {
  grade: string
  curriculum: string
  numberOfIndividualQuestions: number
  difficultyLevel: DifficultyLevel
  topic: string
  country: string
  subject: string
  numberOfComprehensions?: number
  numberOfComprehensionQuestions?: number
}

export type QuizPromptData = {
  id: number
  numberOfIndividualQuestions?: number
  numberOfQuestions?: number
  curriculum?: string
  grade?: string
  description?: string | null
  topic?: string | null
  country?: string | null
  subject?: string | null
  book?: string | null
  objective?: string | null
  numberOfComprehensionQuestions?: number | null
  numberOfComprehensions?: number | null
  difficultyLevel?: DifficultyLevel
  responseIds?: number[]
  title?: string
  isPro?: boolean
}

export type QuizGenerateResponse = {
  id: number
  numberOfQuestions: number
  curriculum: string
  grade: string
  objective: string
  responseId: number
}

export type QuizGenerateProResponse = {
  id: number
  numberOfIndividualQuestions: number
  curriculum: string
  grade: string
  topic: string
  book: string
  difficultyLevel: DifficultyLevel
  country: string
  subject: string
  numberOfComprehensionQuestions: number
  numberOfComprehensions: number
  responseId: number
}
export type QuizResponse = {
  id: number
  isPositiveResponse: boolean | null
  output: string
  promptData: number
}

export type QuizProResponse = {
  id: number
  numberOfQuestions: number
  curriculum: string
  grade: string
  topic: string
  book: string
  difficultyLevel: DifficultyLevel
  country: string
  subject: string
  numberOfComprehensions: number
  numberOfComprehensionQuestions: number
  title: string
  isPro: boolean
  output: string
}
// Check Start
export type HandoutForm = {
  bloomTaxonomyLevel: string[]
  country: string
  curriculum: string
  subject: string
  grade: string
  topic: string
  duration: string
  lessonPlanObjectives: string
  title: string
}

export type HandoutResponse = {
  id: number
  isPositiveResponse: boolean | null
  promptDataId: number
  output: string
}
export type HandoutGeneratorType = HandoutForm & HandoutResponse
// Check End

// New Handout Start
export type HandoutGenerateResponse = {
  id: number
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  subject: string
  grade: string
  lessonPlanObjectives: string[]
  duration: string
  topic: string
  country: string
  curriculum: string
  responseId: number
  generatedWithLessonPlan?: boolean
  lessonPlanPromptId: number | null
  lessonPlanResponseId: number | null
}
export type HandoutPromptData = {
  id: number
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  duration: string
  country: string
  grade: string
  subject: string
  curriculum: string
  topic: string
  generatedWithLessonPlan: boolean | undefined
  lessonPlanPromptId: number | null | undefined
  lessonPlanResponseIds: number[] | null | undefined
  groupId: string | null | undefined
  title?: string
  responseIds:
    | undefined
    | {
        id: number
        lessonPlanResponseId?: number
        responseIds: number[]
      }[]
}

//  Check Start
// These types are same
export type NarrationForm = HandoutForm
export type NarrationResponse = {
  id: number
  isPositiveResponse: boolean | null
  promptDataId: number
  output: string
}
export type NarrationGeneratorType = NarrationForm & NarrationResponse
// Check End
// Narration Type Start
export type NarrationPromptData = {
  id: number
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  duration: string
  country: string
  grade: string
  subject: string
  curriculum: string
  topic: string
  generatedWithLessonPlan: boolean
  lessonPlanPromptId: number | null
  lessonPlanResponseIds: number[] | null
  groupId: string | null
  title: string
  responseIds: {
    id: number
    lessonPlanResponseId: number | null | undefined
    responseIds: number[]
  }[]
}
export type NarrationGenerateResponse = {
  id: number
  bloomTaxonomyLevel: BloomTaxonomyLevels[]
  subject: string
  grade: string
  lessonPlanObjectives: string[]
  duration: string
  topic: string
  country: string
  curriculum: string
  responseId: number
  generatedWithLessonPlan?: boolean
  lessonPlanPromptId: number | null
  lessonPlanResponseId: number | null
}
// Narration Type End
export type SubjectiveAssessmentFormType = {
  curriculum: string
  subject: string
  grade: string
  country: string
  topic: string
  duration: string
  assessmentObjective: string
}

export type SubjectiveAssessmentProFormType = {
  grade: number
  subject: string
  curriculum: string
  topic: string
  country: string
  assessmentObjectives: string
  book?: string | null
  difficultyLevel: DifficultyLevel
  questionDetails: {
    marks: number
    numberOfQuestions: number
  }[]
}
export type SubjectiveAssessmentPromptData = {
  id: number
  totalMarks?: number
  totalQuestions?: number
  grade?: string
  subject?: string
  curriculum?: string
  topic?: string
  lessonPlanPromptId?: number | null
  lessonPlanResponseId?: number | null
  country?: string
  isPro?: boolean
  responseIds?: number[]
  title?: string
  book?: string | null
  difficultyLevel?: DifficultyLevel | null
}
export type SubjectiveAssessmentGenerateResponse = {
  id: number
  subject: string
  grade: string
  curriculum: string
  topic: string
  country: string
  totalMarks: number
  totalQuestions: number
  lessonPlanResponseId: number
  responseId: number
}
export type SubjectiveAssessmentGenerateProResponse = {
  id: number
  subject: string
  curriculum: string
  country: string
  grade: string
  topic: string
  book: string | null
  difficultyLevel: DifficultyLevel
  lessonPlanResponseId: number | null
  responseId: number
  questionDetails: {
    marks: number
    numberOfQuestions: number
  }[]
}
export type SubjectiveAssessmentResponse = {
  id: number
  output: string
  isPositiveResponse: boolean | null
  promptData: number
}

export type SubjectiveAssessmentProResponse = {
  id: number
  subject: string
  curriculum: string
  country: string
  grade: string
  topic: string
  book: string | null
  questionDetails: {
    marks: number
    number_of_questions: number
  }[]
  difficultyLevel: DifficultyLevel
  isPro: boolean
  title: string
  output: string
}

export type AssignmentForm = {
  grade: string
  rubricScale: number
  subject: string
  curriculum: string
  assignmentObjectives: string
}

export type AssignmentProForm = {
  grade: string
  rubricScale: number
  subject: string
  curriculum: string
  assignmentObjectives: string
  topic: string
  book?: string | null
}

export type ActivityPlannerForm = {
  subject: string
  curriculum: string
  grade: string
  topic: string
  duration: string
}
export type ActivityPlannerPromptData = {
  id: number
  duration?: string
  grade?: string
  subject?: string
  curriculum?: string
  topic?: string
  responseIds?: number[]
  title?: string
}

export type ActivityPlannerResponse = {
  id: number
  subject: string
  grade: string
  duration: string
  topic: string
  curriculum: string
  output: string
  // title: string
  isPositiveResponse?: boolean | null
  responseId: number
}

export type AssignmentOutput = string
export type AssignmentPromptData = {
  id: number
  grade?: string
  rubricScale?: number
  subject?: string
  curriculum?: string
  topic?: string
  book?: string | null
  isPro?: boolean
  responseIds?: number[]
  title?: string
}
export type AssignmentGenerateResponse = {
  id: number
  responseId: number
  rubricScale: number
  curriculum: string
  grade: string
  subject: string
}
export type AssignmentProGenerateResponse = {
  id: number
  responseId: number
  subject: string
  curriculum: string
  grade: string
  rubricScale: number
  assignmentObjectives: string
  book: string
  topic: string
}
export type AssignmentResponse = {
  id: number
  output: string
  isPositiveResponse: boolean | null
  promptData: number
}

export type AssignmentProResponse = {
  id: number
  title: string
  subject: string
  curriculum: string
  grade: string
  rubricScale: number
  assignmentObjectives: string
  book: string
  topic: string
  isPro: boolean
  output: AssignmentOutput
}

export type AssignmentGeneratorType = {
  id: number
  title: string
  rubricScale: number
  curriculum: string
  grade: string
  subject: string
  output: AssignmentOutput
}
export type VideoSummaryForm = {
  url: string
  language: string
  startTime: string
  endTime: string
}
export type VideoSummaryPromptData = {
  id: number
  url?: string
  language?: string | null
  startTime?: string | null
  endTime?: string | null
  responseIds?: number[]
  title?: string
}

export type VideoSummaryGenerateResponse = {
  id: number
  url: string
  language: string
  startTime: string
  endTime: string
  responseId: number
}

export type VideoSummaryResponse = {
  id: number
  isPositiveResponse: boolean | null
  output: string
  promptData: number
}
export type VideoDurationType = {
  url: string
  startTime?: string
  duration: string
}

export enum UnitPlanTypeEnum {
  academicYear = "academic_year",
  topic = "topic",
}

export type UnitPlanForm = {
  subject: string
  book: string | null
  topic: string | null
  grade: string
  country: string
  curriculum: string
  classDuration: string
  unitPlanType: UnitPlanTypeEnum
  semesterDuration: string | null
  numberOfDays: string | null
}
export type UnitPlanPromptData = {
  id: number
  title: string
  subject: string | undefined
  book?: string | undefined
  topic: string | undefined
  grade: string | undefined
  country: string | undefined
  curriculum: string | undefined
  classDuration: string | undefined
  unitPlanType: UnitPlanTypeEnum
  semesterDuration?: string | undefined
  numberOfDays: number | undefined
  unitPlanResponses?: {
    id: number
    responseIds: number[]
  }[]
}

export type UnitPlanGenerateResponse = {
  id: number
  unitPlanType: UnitPlanTypeEnum
  responseId: number
  grade: string | undefined
  classDuration: string | undefined
  subject: string | undefined
  curriculum: string | undefined
  numberOfDays: number
  country: string | undefined
  topic: string | undefined
}

export type UnitPlanPromptResponse = {
  title: string
  unitDivisionResponses: number[]
  grade: string
  classDuration: string
  subject: string
  curriculum: string
  unitPlanType: UnitPlanTypeEnum
  country: string
  promptDataId: number
  book?: string
  semesterDuration?: string
  topic?: string
  numberOfDays?: number
  unitPlanResponses: {
    id: number
    response_id: number[]
  }[]
}

export type UnitPlanTerms = {
  title: string
  description: string
  terms: {
    term: string
    units: {
      unitNumber: number
      unitTitle: string
      numberOfWeeks: string
      details: string
    }[]
  }[]
}
export type UnitPlanDivisionResponse = {
  id: number
  output: UnitPlanTerms
  isPositiveResponse: boolean | null
  promptData: number
}
export type UnitPlanResponse = {
  id: number
  output: string
  isPositiveResponse: boolean | null
  promptData: number
}

export type RetrievePrompt<T = ToolType> = {
  id: number
} & (T extends ToolType.quiz
  ? QuizResponse
  : T extends ToolType.quizPro
  ? QuizProResponse
  : T extends ToolType.rubrics
  ? RubricsResponse
  : T extends ToolType.rubricsPro
  ? RubricsProResponse
  : T extends ToolType.lessonPlanPro
  ? LessonPlanProResponse
  : T extends ToolType.lessonPlan
  ? LessonPlanResponse
  : T extends ToolType.handout
  ? HandoutGeneratorType
  : T extends ToolType.narration
  ? NarrationGeneratorType
  : T extends ToolType.activityPlanner
  ? ActivityPlannerResponse
  : T extends ToolType.subjectiveAssessment
  ? SubjectiveAssessmentResponse
  : T extends ToolType.subjectiveAssessmentPro
  ? SubjectiveAssessmentProResponse
  : T extends ToolType.assignment
  ? AssignmentGeneratorType
  : T extends ToolType.assignmentPro
  ? AssignmentProResponse
  : T extends ToolType.videoSummary
  ? VideoSummaryResponse
  : T extends ToolType.unitPlanner
  ? UnitPlanPromptResponse
  : never)

export type ListPromptHistory = {
  dateCreated: string
  title: string
  promptDataId: number
  generatedWithLessonPlan?: boolean
  toolType: ToolType
  responseIds?: number[] | { id: number; responseIds: number[] }[]
  isPro?: boolean
}

type BloomTaxonomyLevels =
  | "remembering"
  | "understanding"
  | "application"
  | "analysis"
  | "evaluation"
  | "creativity"

export type AIToolPrompts = {
  id: number
  title: string
  country?: string
  curriculum?: string
  subject?: string | null
  numberOfClasses?: number | null
  numberOfIndividualQuestions?: number | null
  book?: string | null
  criteriaLevel?: number | null
  topic?: string | null
  grade?: string
  duration?: string
  objective?: string
  pointOfScale?: number | null
  rubricScale?: number
  taxonomy?: string[]
  bloomTaxonomyLevel?: BloomTaxonomyLevels[]
  numberOfQuestions?: number
  responseIds: number[] | { id: number; responseIds: number[] }[]
  totalMarks?: number
  totalQuestions?: number
  numberOfComprehensions?: number | null
  numberOfComprehensionQuestions?: number | null
  difficultyLevel?: DifficultyLevel | null
  startTime?: string
  endTime?: string
  language?: string
  rubricType?: RubricType
  unitPlanResponses?: {
    id: number
    responseIds: number[]
  }[]
  unitDivisionResponses?: number[]
  classDuration?: string
  unitPlanType?: UnitPlanTypeEnum
  promptDataId: number
  semesterDuration?: string
  numberOfDays?: number
  isPro?: boolean
}

export type CoverLetterHistory = {
  id: number
  schoolName: string
  position: number
  subject: number
  dateCreated: string
}

export type RetrieveCoverLetter = {
  dateCreated?: string | null
  position: string | null
  subject: string | null
  schoolName: string | null
  recipientName?: string | null
  id?: number
  fullName: string | null
  email: string | null
  phoneNumber: string | null
  country: string | null
  state: string | null
  output: string | null
  isPositiveResponse: boolean | null
}

export interface ResumeFillerType {
  firstName?: {
    firstName: string
  }
  lastName?: {
    lastName: string
  }
  gender?: {
    gender: Gender
  }
  dateOfBirth?: {
    dateOfBirth: string
  }
  phoneNumber?: {
    phoneNumber: PhoneNumber
  }
  lookingForJobs?: {
    lookingForJobs: boolean
  }
  nationality?: { nationality: Country }
  country?: Country
  careerAspiration?: {
    careerAspiration: string
  }
  state?: State
  qualifications?: Qualification[]
  certification?: Certification[]
  workExperience?: WorkExperienceType[]
  skills?: Skill[]
  language?: LanguageResponse[]
  subjects?: SubjectResponse[]
  interest?: Interest[]
  awards?: Award[]
  project?: Project[]
  publication?: Publication[]
  testScores?: TestScore[]
  workLocationInterests?: WorkLocationInterest[]
}
export type UpdateField = { updatedField?: string[] }
