import { CircularProgress } from "@suraasa/placebo-ui"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { useNavigate } from "react-router-dom"
import routes from "routes"

import Heading from "../../Heading"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import HandoutOutput from "../GenerationOutput/HandoutOutput"
import BasicForm from "./BasicForm"
import { useHandout } from "./useHandout"
// import SelectLessonPlanSheet from "../helpers/SelectLessonPlanSheet"
// import NoLessonPlannerDialog from "../SubjectiveAssessmentGenerator/NoLessonPlannerDialog"

const toolType = ToolType.handout

const HandoutGenerator = () => {
  const navigate = useNavigate()

  const {
    currentResponseId,
    setCurrentResponseId,
    pageLoading,
    promptDataId,
    onBack,
    ...handoutContext
  } = useHandout()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={() => {
            setCurrentResponseId(null)
            navigate(routes.aiTools.handout, { replace: true })
          }}
          currentResponseId={currentResponseId}
          toolType={toolType}
          title={handoutContext.overviewData?.title}
        />

        <HandoutOutput
          {...handoutContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      {/* <SelectLessonPlanSheet
        open={lessonPlanSheetOpen}
        onOpenChange={setLessonPlanSheetOpen}
        onSelect={lp => {
          mutate({
            lessonPlan: lp.promptDataId,
            generatedWithLessonPlan: false,
          })
        }}
      />
      <NoLessonPlannerDialog
        open={openDialog}
        onClose={onClose}
        onCreateLessonPlan={onCreateLessonPlan}
        description="Looks like you haven’t generated any Lesson Plans. You can either generate a Lesson Plan to create a handout from or create a handout from scratch."
      /> */}
      <Heading
        title={`${getToolName(toolType)} Generator`}
        // slot={
        //   <Button
        //     onClick={() => {
        //       lessonPlanList.refetch()
        //     }}
        //     className="shrink-0"
        //     loading={lessonPlanList.isFetching}
        //   >
        //     Select Lesson Plan
        //   </Button>
        // }
        subtitle="Your go-to tool for creating customised lesson materials for students. Create one from scratch or select a lesson plan to get started."
      />
      <BasicForm {...handoutContext} />
    </>
  )
}

export default HandoutGenerator
