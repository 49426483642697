import { Button, cn, Typography } from "@suraasa/placebo-ui"
import Stars from "assets/AITools/stars-gradient.json"
import { useEffect, useState } from "react"
import Lottie from "react-lottie"

import MarkdownRenderer from "../MarkdownRenderer"
type Props = {
  children?: string
  showTitle?: boolean
  isLoading?: boolean
  onClick?: () => void
  className?: string
}

const ToolOutput = ({
  children = "",
  isLoading,
  onClick,
  className,
  showTitle,
}: Props) => {
  const [hasError, setHasError] = useState(false)

  const regexForError = /\[\[__ERROR__\]\]|\{'status':\s*'failed'\}/g
  const errorTokenMatch = children.match(regexForError)

  useEffect(() => {
    if (errorTokenMatch) setHasError(true)
  }, [errorTokenMatch])

  return (
    <div
      className={cn("p-2", className, {
        "bg-critical-100 text-critical-800 grid place-items-center": hasError,
      })}
    >
      {!children && isLoading && (
        <div className="flex flex-row items-center">
          <Typography variant="subtitle2">Generating.....</Typography>
          <Lottie
            isClickToPauseDisabled
            style={{
              cursor: "default",
              marginLeft: 8,
              width: 24,
              height: 24,
              transform: "scale(1.4)",
            }}
            speed={1.75}
            options={{
              loop: true,
              autoplay: true,
              animationData: Stars,
            }}
          />
        </div>
      )}

      <div
        className={cn({
          "flex flex-col items-center gap-2": hasError,
        })}
      >
        <MarkdownRenderer showTitle={showTitle}>{children}</MarkdownRenderer>
        {hasError && (
          <Button
            variant="outlined"
            color="critical"
            rounded
            size="sm"
            onClick={onClick}
          >
            Review Input
          </Button>
        )}
      </div>
    </div>
  )
}

export default ToolOutput
