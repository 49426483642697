export const urls = {
  list: (itemId: string | number, itemType: string | number) =>
    `/v1/${itemType}/${itemId}/assessments/`,

  listAttempts: (assessmentId: number | string) =>
    `/assessments/user/highlights/${assessmentId}/`,

  attempt: (assessmentId: string | number) =>
    `/assessments/${assessmentId}/attempts/`,

  updateAttempt: (attemptId: string | number) =>
    `/assessments/attempts/${attemptId}/`,

  markAnswer: (tempAssessmentPerformanceId: number | string) =>
    `/assessments/performance/${tempAssessmentPerformanceId}/`,

  assessment: (attemptId: string | number) =>
    `/assessments/${attemptId}/performances/`,

  solutions: (attemptId: string | number) =>
    `/v1/assessments/attempts/${attemptId}/solutions/`,

  topicScores: (attemptId: string | number) =>
    `/assessments/attempts/${attemptId}/scores/`,
}
