import clsx from "clsx"

import Paper from "../../../Paper"
import InputDisplay from "../../helpers/InputDisplay"
import { RubricsReturnType } from "../../RubricsGenerator/useRubrics"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"
import styles from "./Rubrics.module.css"

const cleanOutputText = (output: string | undefined) => {
  if (!output) return ""

  return output
    .replace("### Rubrics:", "")
    .replace("### Rubric:", "")
    .replace("**Rubrics:**", "")
    .replace("**Rubric:**", "")
}

const RubricOutput = (
  props: Pick<
    RubricsReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            pointOfScale: overviewData.pointOfScale,
            criteriaLevel: overviewData.criteriaLevel,
            curriculum: overviewData.curriculum,
            isPro: overviewData.isPro,
            rubricType: overviewData.rubricType,
            grade: overviewData.grade,
            objective: overviewData.objective,
            promptDataId: overviewData.id,
            title: overviewData.title || "",
            responseIds: overviewData.responseIds || [],
          }}
        />
      )}
      <Paper
        className="p-0"
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div className={clsx("overflow-x-auto", styles.tableContainer)}>
          <ToolOutput isLoading={isLoading} onClick={onBack}>
            {cleanOutputText(output)}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default RubricOutput
