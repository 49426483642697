import axios from "api/axios"
import { APIResponse, ConfigWithURLParams } from "api/types"
import { NoContentType } from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  AssessmentListItem,
  AssessmentSolutions,
  Attempt,
  AttemptResponse,
  AutoSubmitted,
  ShowAnswers,
  TopicScore,
} from "./types"
import { urls } from "./urls"

export default {
  listAssessmentsSchedules: async ({
    urlParams: { itemId, itemType },
  }: ConfigWithURLParams): Promise<APIResponse<AssessmentListItem[]>> => {
    try {
      const res = await axios.get(urls.list(itemId, itemType))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  listAttempts: async ({
    urlParams: { assessmentId },
  }: ConfigWithURLParams): Promise<APIResponse<Attempt[]>> => {
    try {
      const res = await axios.get(urls.attempt(assessmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  getAttempt: async ({
    urlParams: { assessmentId },
  }: ConfigWithURLParams): Promise<APIResponse<Attempt>> => {
    try {
      const res = await axios.post(urls.attempt(assessmentId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  updateAttempt: async ({
    urlParams: { attemptId },
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(urls.updateAttempt(attemptId), data)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  markAnswer: async ({
    urlParams: { questionId },
    data,
  }: ConfigWithURLParams): Promise<
    APIResponse<NoContentType | AutoSubmitted>
  > => {
    try {
      const res = await axios.put(urls.markAnswer(questionId), data)
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  listQuestions: async ({
    urlParams: { attemptId },
  }: ConfigWithURLParams): Promise<
    APIResponse<AttemptResponse | AutoSubmitted>
  > => {
    try {
      const res = await axios.get(urls.assessment(attemptId))
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  getSolutions: async ({
    urlParams: { attemptId },
    params,
  }: ConfigWithURLParams): Promise<
    APIResponse<AssessmentSolutions | ShowAnswers>
  > => {
    try {
      const res = await axios.get(urls.solutions(attemptId), { params })
      return formatSuccessResponse(res)
    } catch (e) {
      const error = formatErrorResponse(e)
      throw error
    }
  },

  getTopicWiseScore: async ({
    urlParams: { attemptId },
  }: ConfigWithURLParams): Promise<APIResponse<TopicScore>> => {
    try {
      const res = await axios.get(urls.topicScores(attemptId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
