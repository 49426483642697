import { cn, Typography } from "@suraasa/placebo-ui"
import { UserReattemptCounterType } from "api/resources/assessments/types"
import { InfoCircle } from "iconoir-react"
import { pluralize } from "utils/helpers"

export const calculateReattemptsLeft = (
  data?: UserReattemptCounterType | null
): number | undefined => {
  if (!data) return undefined
  const { reattemptsAllowed, reattemptsConsumed, activeReattemptsRequests } =
    data
  const reattemptsLeft =
    reattemptsAllowed - (reattemptsConsumed + activeReattemptsRequests)
  return reattemptsLeft >= 0 ? reattemptsLeft : undefined
}

const ReattemptCount = ({
  className,
  userReattemptCounter,
}: {
  className?: string
  userReattemptCounter?: UserReattemptCounterType | null
}) => {
  if (!userReattemptCounter) return null
  const reattemptsLeft = calculateReattemptsLeft(userReattemptCounter)
  if (reattemptsLeft === undefined) return null
  return (
    <div
      className={cn("flex items-center gap-1 text-onSurface-500", className, {
        "text-critical-500": reattemptsLeft === 0,
      })}
    >
      <InfoCircle />
      <Typography variant="smallBody">
        You have {pluralize("re-attempt", reattemptsLeft)} left for all
        assignments and assessments.
      </Typography>
    </div>
  )
}

export default ReattemptCount
