import BasicOutput, { BasicOutputProps } from "./BasicOutput"
import ProOutput, { ProOutputProps } from "./ProOutput"

const LessonPlanOutput = ({
  autoGenerateHandout,
  autoGenerateNarration,
  resetState,
  ...lessonContext
}: {
  autoGenerateNarration?: boolean
  autoGenerateHandout?: boolean
  resetState: () => void
} & BasicOutputProps &
  ProOutputProps) => {
  if (
    lessonContext.lessonPlanResponseIds ||
    lessonContext.overviewData?.isPro
  ) {
    return <ProOutput {...lessonContext} />
  }

  return (
    <div>
      <BasicOutput
        {...lessonContext}
        autoGenerateHandout={autoGenerateHandout}
        autoGenerateNarration={autoGenerateNarration}
        resetState={resetState}
      />
    </div>
  )
}

export default LessonPlanOutput
