import {
  Button,
  DialogBody,
  DialogFooter,
  ErrorMessage,
  InputLabel,
  RadioGroup,
  RadioGroupItem,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { UserReattemptCounterType } from "api/resources/assessments/types"
import { calculateReattemptsLeft } from "features/ReattemptCount"
import { InfoCircle } from "iconoir-react"
import { Controller, useForm } from "react-hook-form"
import { mapErrors, pluralize } from "utils/helpers"

const otherOptionValue = "other"

type Form = {
  subject: string
  description?: string
  numberOfDays?: string
  requestedStartTime?: string
}

const reattemptOptions = [
  {
    label: "Missed my due date",
    value: "Missed my due date",
  },
  {
    label: "Failed my last attempt",
    value: "Failed my last attempt",
  },
  {
    label: "For score improvement",
    value: "For score improvement",
  },
  {
    label: "Other Reason",
    value: otherOptionValue,
  },
]

const Reason = ({
  onSubmit,
  type,
  learningContentId,
  userPlannerItemId,
  userReattemptCounter,
}: {
  onSubmit: () => void
  type: "assessment" | "assignment"
  learningContentId?: string
  userPlannerItemId?: number
  userReattemptCounter: UserReattemptCounterType
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<Form>()

  const { mutateAsync } = useMutation({
    mutationFn: (data: Form) =>
      api.batch.createReattemptRequest({
        data: {
          userPlannerItemId: userPlannerItemId,
          subject: data.subject,
          description: data.description || undefined,
          numberOfDays: data.numberOfDays || undefined,
          requestedStartTime: data.requestedStartTime
            ? new Date(data.requestedStartTime).toISOString()
            : undefined,
          learningContentId: learningContentId,
          learningContentType: type,
        },
      }),
    onSuccess: () => {
      onSubmit()
    },
    onError: error => {
      mapErrors(setError, error, [
        ["subject"],
        ["description"],
        ["numberOfDays"],
        ["requestedStartTime"],
      ])
    },
  })

  const onSubmitForm = handleSubmit(async data => {
    if (isOtherOptionSelected) {
      if (!data.description) {
        setError("description", {
          type: "required",
          message: "Required",
        })
        return
      }
    }

    if (type === "assessment") {
      if (!data.requestedStartTime) {
        setError("requestedStartTime", {
          type: "required",
          message: "Required",
        })
        return
      }
    }

    if (type === "assignment") {
      if (!data.numberOfDays) {
        setError("numberOfDays", {
          type: "required",
          message: "Required",
        })
        return
      }
    }

    await mutateAsync(data)
  })

  const reattemptsLeft = calculateReattemptsLeft(userReattemptCounter)

  const subject = watch("subject")
  const isOtherOptionSelected = subject === otherOptionValue

  return (
    <>
      <DialogBody className="py-2.5">
        <form
          onSubmit={onSubmitForm}
          id="reason-form"
          className="flex flex-col gap-2"
        >
          {type === "assignment" && (
            <div>
              <InputLabel
                label="Select the number of days you would need to reattempt this assignment"
                required
              />
              <Controller
                control={control}
                name="numberOfDays"
                render={({ field: { value, onChange } }) => (
                  <RadioGroup defaultValue={value} onValueChange={onChange}>
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label="7 days"
                      value="7"
                    />
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label="15 days"
                      value="15"
                    />
                  </RadioGroup>
                )}
              />
              {errors.numberOfDays && (
                <ErrorMessage title={errors.numberOfDays.message} />
              )}
            </div>
          )}

          {type === "assessment" && (
            <TextField
              helperText="Please select a reattempt date for the assessment within the next 15 days."
              errors={errors.requestedStartTime?.message}
              label="Assessment Reattempt Time"
              className="mb-1.5"
              placeholder="Type here..."
              type="datetime-local"
              required
              {...register("requestedStartTime")}
            />
          )}

          <div>
            <InputLabel label="Reason for reattempt request" required />

            <Controller
              control={control}
              name="subject"
              render={({ field: { value, onChange } }) => (
                <RadioGroup defaultValue={value} onValueChange={onChange}>
                  {reattemptOptions.map(option => (
                    <RadioGroupItem
                      key={option.value}
                      containerClass="flex items-center gap-1"
                      label={option.label}
                      value={option.value}
                    />
                  ))}
                </RadioGroup>
              )}
              rules={{
                required: { value: true, message: "Required" },
              }}
            />
            {errors.subject && <ErrorMessage title={errors.subject.message} />}
          </div>

          <TextArea
            errors={errors.description?.message}
            rows={5}
            label={
              isOtherOptionSelected
                ? "Additional Details"
                : "Additional Details (optional)"
            }
            className="mb-1.5"
            placeholder="Type here..."
            {...register("description")}
          />
        </form>
        {reattemptsLeft !== undefined && reattemptsLeft - 1 >= 0 && (
          <div className="flex items-center gap-1 rounded-lg bg-warning-100 p-1.5 text-warning-900">
            <InfoCircle className="shrink-0" />
            <Typography variant="smallBody">
              Upon approval of this request, you will have{" "}
              {pluralize("re-attempt", reattemptsLeft - 1)} left.
            </Typography>
          </div>
        )}
      </DialogBody>
      <DialogFooter>
        <Button
          type="submit"
          size="sm"
          form="reason-form"
          loading={isSubmitting}
        >
          Submit Request
        </Button>
      </DialogFooter>
    </>
  )
}

export default Reason
