import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  Container,
  theme as themeObj,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { UserReattemptCounterType } from "api/resources/assessments/types"
import { ReattemptRequestStatusEnum } from "api/resources/assignments/types"
import clsx from "clsx"
import { calculateReattemptsLeft } from "features/ReattemptCount"
import { InfoCircle } from "iconoir-react"
import { pluralize } from "utils/helpers"

export enum ReattemptReason {
  missed,
  failed,
  scoreImprovement,
}

const getDisplayText = (reason: ReattemptReason) => {
  switch (reason) {
    case ReattemptReason.missed:
      return "You missed the deadline. You need to request a re-attempt."
    case ReattemptReason.failed:
      return "You did not meet the passing criteria."
    case ReattemptReason.scoreImprovement:
      return "Do you want to improve your score?"
  }
}

const ReattemptBanner = ({
  onClick,
  reason,
  reattemptRequest,
  userReattemptCounter,
}: {
  onClick: () => void
  reason: ReattemptReason
  reattemptRequest?: ReattemptRequestStatusEnum | null
  userReattemptCounter?: UserReattemptCounterType | null | undefined
}) => {
  const isXsDown = useMediaQuery(themeObj.breakpoints.down("xs"))
  const displayReattemptCount = (count: number) => (
    <Typography className="inline" variant="smallBody">
      You have {pluralize("re-attempt", count)} left.
    </Typography>
  )

  const reAttemptsLeft = calculateReattemptsLeft(userReattemptCounter)

  const requestIsUnderReview =
    reattemptRequest === ReattemptRequestStatusEnum.UNDER_REVIEW
  const forceReattempt =
    reason === ReattemptReason.missed || reason === ReattemptReason.failed

  return (
    <div
      className={clsx({
        "bg-highlight-50": requestIsUnderReview || !forceReattempt,
        "bg-critical-50": !requestIsUnderReview && forceReattempt,
      })}
    >
      <Container className="py-1">
        {reattemptRequest === ReattemptRequestStatusEnum.UNDER_REVIEW ? (
          <Typography
            variant="strongSmallBody"
            className="flex items-center gap-1 py-1 text-highlight-900"
          >
            <InfoCircle />
            <span>
              You have requested a reattempt for this submission. You will be
              notified via email when your request is reviewed.
            </span>
          </Typography>
        ) : (
          <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
            <Typography
              variant="strongSmallBody"
              className={clsx({
                "text-primary-900": !forceReattempt,
                "text-critical-900": forceReattempt,
              })}
            >
              {getDisplayText(reason)}{" "}
              {reAttemptsLeft !== undefined &&
                reAttemptsLeft > 0 &&
                displayReattemptCount(reAttemptsLeft)}
            </Typography>
            {/* User can only manually request a re-attempt if reattempt request type is ON_REQUEST OR the person has missed the deadline */}
            <Button
              fullWidth={isXsDown}
              onClick={onClick}
              color={forceReattempt ? "critical" : "primary"}
              variant="text"
            >
              Request a Reattempt
            </Button>
          </div>
        )}
      </Container>
    </div>
  )
}

export default ReattemptBanner
