import {
  Button,
  DialogBody,
  DialogFooter,
  Typography,
} from "@suraasa/placebo-ui"
import { pluralize } from "utils/helpers"

const FirstModule = ({
  onClick,
  reattemptCount,
  title,
}: {
  onClick: () => void
  reattemptCount: number
  title?: string
}) => {
  return (
    <>
      <DialogBody className="py-2.5">
        <Typography variant="strong" className="mb-1">
          Since its your 1st Module, we&apos;re not counting your re-attempts.
        </Typography>
        <Typography variant="smallBody" className="mb-1">
          Once you complete your 1st module, you will get{" "}
          <Typography variant="strongSmallBody" className="inline">
            {pluralize("re-attempt", reattemptCount)}
          </Typography>{" "}
          throughout {title}.
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClick} size="sm">
          I understand
        </Button>
      </DialogFooter>
    </>
  )
}

export default FirstModule
