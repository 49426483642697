import { Button, Typography } from "@suraasa/placebo-ui"
import { MasterclassCertificate } from "api/resources/profile/types"
import MasterClassPlaceholder from "assets/Placeholder/default-masterclass-thumbnail.svg"
import clsx from "clsx"
import { formatDate } from "utils/helpers"

const ItemRow = (
  props: {
    className?: string
  } & MasterclassCertificate
) => {
  return (
    <div className={clsx(props.className, "flex flex-wrap gap-2")}>
      <div className="shrink-0">
        <img
          alt="Masterclass Thumbnail"
          src={props.masterclass.thumbnail || MasterClassPlaceholder}
          className="h-8 rounded-xl border border-onSurface-300"
        />
      </div>

      <div>
        <Typography variant="strongSmallBody">
          {props.masterclass.title}
        </Typography>
        <Typography className="text-muted" variant="smallBody">
          Attended on {formatDate(props.issuedAt, "d MMMM, yyyy")}
        </Typography>
        <a href={props.certificate} target="_blank" rel="noreferrer">
          <Button variant="text" className="p-0">
            View Certificate
          </Button>
        </a>
      </div>
    </div>
  )
}

export default ItemRow
