import { createQueryKeys } from "@lukemorales/query-key-factory"

export default createQueryKeys("batch", {
  listBatches: () => ({
    queryKey: ["listBatches"],
  }),
  retrieveReattemptData: userPlannerItemId => ({
    queryKey: ["retrieveReattemptData", userPlannerItemId],
  }),
})
