import { Typography } from "@suraasa/placebo-ui"
// import { ArrowDownCircle } from "iconoir-react"
import React from "react"

const OverviewItem = ({
  title,
  value,
}: // progressDirection,
{
  title: string
  value?: string | null
  progressDirection?: "up" | "down" | null
}) => {
  return (
    <div className="flex w-full items-start justify-between rounded-xl border border-onSurface-200 px-2 py-1.5">
      {/* pt-2.5 */}
      <div>
        <Typography variant="title1">{value ? value : "-"}</Typography>
        <Typography variant="preTitle">{title}</Typography>
      </div>
      {/* {progressDirection === "down" && (
        <ArrowDownCircle width={20} height={20} className="text-critical-500" />
      )}
      {progressDirection === "up" && (
        <ArrowDownCircle
          width={20}
          height={20}
          className="rotate-180 text-success-500"
        />
      )} */}
    </div>
  )
}

export default OverviewItem
