import { Button, Typography } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { useSearchParams } from "react-router-dom"
import { getPlatformURL } from "utils/helpers"

type Props = {
  name: string
}

export const redirectToSchoolLogin = () => {
  const url = new URL(getPlatformURL("hire", ""))
  url.searchParams.set(
    "next",
    "/teacher" + window.location.pathname + window.location.search
  )
  window.location.href = url.toString()
}

const FakePDF = ({ name }: Props) => {
  const [searchParams] = useSearchParams()
  const isEmbedded = searchParams.get("embed") === "true"
  return (
    <div className="relative flex justify-center bg-slate-50">
      <div className="relative blur-[3px]">
        <img
          src="https://assets.suraasa.com/lms/profile-assets/placeholder-resume.png"
          alt="resume"
        />
        <div
          className={clsx(
            "absolute flex w-full items-center justify-center bg-white !text-onSurface-900",
            "left-[6vw] top-[6vw] h-[5vw] max-w-[33vw]",
            "sm:h-[4vw] sm:max-w-[29vw]",
            "!text-[min(2vw,24px)] md:left-[50px] md:top-[50px] md:h-[45px] md:max-w-[280px]"
          )}
        >
          <span>{name}</span>
        </div>
      </div>
      <div className="absolute left-0 top-0 flex size-full items-center justify-center rounded-lg bg-black/30 text-white">
        {isEmbedded ? (
          <Typography className="mx-auto px-5 text-center">
            You need an account on Suraasa to view this resume
          </Typography>
        ) : (
          <Button onClick={redirectToSchoolLogin}>
            Sign in as school to view resume
          </Button>
        )}
      </div>
    </div>
  )
}

export default FakePDF
