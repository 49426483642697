import { Typography } from "@suraasa/placebo-ui"
import { Button } from "@suraasa/placebo-ui-legacy"
import { MainColors } from "@suraasa/placebo-ui-legacy/dist/cjs/utils/createColors"
import {
  Review,
  REVIEW_STATUS,
  Submission,
  SUBMISSION_STATUS,
  SubmissionResultEnum,
} from "api/resources/assignments/types"
import RubricsPopup from "features/Assignments/RubricsPopup"
import VerificationProcess from "features/Assignments/VerificationProcess"
import { InfoCircle } from "iconoir-react"
import { useState } from "react"
import { ValueOf } from "utils/types"

import Paper from "../Paper"
import TagWithAdornment from "../TagWithAdornment"

type Props = {
  status: ValueOf<typeof SUBMISSION_STATUS>
  review: Review | null
  iqaReview?: Review | null
  hideAction?: boolean
  useDialogForRubrics?: boolean
  result: Submission["result"]
}

const getScoreProps = (review: Review) => {
  const label = review.marks != null ? `Score: ${review.marks.toFixed(1)}` : ""
  const color: MainColors = "primary"

  return { label, color }
}

type LabelType = "Accepted" | "Rejected" | "Under Review" | "Not Reviewed"
const getStatusTagProps = (
  review: Review,
  submissionResult: SubmissionResultEnum | null
): {
  label: LabelType
  color?: MainColors
} => {
  if (submissionResult) {
    switch (submissionResult) {
      case SubmissionResultEnum.PASSED:
        return { label: "Accepted", color: "success" }
      case SubmissionResultEnum.FAILED:
        return { label: "Rejected", color: "critical" }
    }
  } else {
    switch (review.status) {
      case REVIEW_STATUS.accepted:
        return { label: "Accepted", color: "success" }
      case REVIEW_STATUS.plagiarised:
      case REVIEW_STATUS.rejected:
        return { label: "Rejected", color: "critical" }
      case REVIEW_STATUS.underReview:
        return { label: "Under Review", color: "secondary" }
      case REVIEW_STATUS.notReviewed:
        return { label: "Not Reviewed", color: "secondary" }
    }
  }
}

const getComments = (review: Review) => review.comments

const ProfessorReviewCard = ({
  review,
  status,
  iqaReview,
  hideAction,
  useDialogForRubrics,
  result,
}: Props) => {
  const reviewData = iqaReview || review

  const [showRubrics, setShowRubrics] = useState(false)
  const [showVerificationProcess, setShowVerificationProcess] = useState(false)

  const isRejected =
    status === SUBMISSION_STATUS.rejected ||
    iqaReview?.status === REVIEW_STATUS.rejected ||
    review?.status === REVIEW_STATUS.rejected ||
    review?.status === REVIEW_STATUS.plagiarised ||
    iqaReview?.status === REVIEW_STATUS.plagiarised

  const isAccepted =
    iqaReview?.status === REVIEW_STATUS.accepted ||
    review?.status === REVIEW_STATUS.accepted

  if (
    (!isAccepted && !isRejected && review === null) ||
    review?.status === REVIEW_STATUS.notReviewed ||
    review?.status === REVIEW_STATUS.underReview
  ) {
    return (
      <Paper className="flex items-center border-y border-interactive-100 bg-interactive-50 sm:border">
        <InfoCircle className="me-1 shrink-0 text-interactive-500" />
        <Typography variant="strongSmallBody" className="text-interactive-900">
          Your submission is currently under review
        </Typography>
      </Paper>
    )
  }

  return (
    <div>
      {iqaReview?.status === REVIEW_STATUS.underReview && (
        <Paper className="mb-3  flex items-center border-y border-interactive-100 bg-interactive-50 sm:border">
          <InfoCircle className="me-1 shrink-0 text-interactive-500" />
          <Typography
            variant="strongSmallBody"
            className="text-interactive-900"
          >
            Your submission is currently under Internal Quality Assurance (IQA).
            You can learn more about IQA{" "}
            <button
              onClick={() => setShowVerificationProcess(true)}
              className="underline underline-offset-2"
            >
              here.
            </button>
          </Typography>
        </Paper>
      )}
      <Paper>
        {!hideAction && (
          <div className="mb-2 flex items-center gap-2 sm:hidden">
            <Button onClick={() => setShowRubrics(true)} size="sm" fullWidth>
              View Rubrics
            </Button>
          </div>
        )}
        <div className="mb-2 flex items-center justify-between">
          <div className="flex gap-2">
            {reviewData && <TagWithAdornment {...getScoreProps(reviewData)} />}

            {!hideAction && (
              <div className="hidden items-center gap-2 sm:flex">
                <Button size="sm" onClick={() => setShowRubrics(true)}>
                  View Rubrics
                </Button>
              </div>
            )}
          </div>
          {reviewData && (
            <TagWithAdornment {...getStatusTagProps(reviewData, result)} />
          )}
        </div>
        <Typography variant="title4" className="mb-1">
          Professor’s Remarks
        </Typography>
        {reviewData && <Typography>{getComments(reviewData)}</Typography>}
      </Paper>

      {review && (
        <RubricsPopup
          mode={useDialogForRubrics ? "dialog" : "sheet"}
          open={showRubrics}
          onClose={() => setShowRubrics(false)}
          review={review}
          iqaReview={iqaReview || undefined}
        />
      )}

      <VerificationProcess
        open={showVerificationProcess}
        onClose={() => setShowVerificationProcess(false)}
      />
    </div>
  )
}

export default ProfessorReviewCard
