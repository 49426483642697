import { Typography } from "@suraasa/placebo-ui"
import { GradeBookScore } from "api/resources/learningItems/types"
import React from "react"

import CourseItem from "./CourseItem"

const CourseWiseBreakDown = ({
  className,
  slug,
  items,
}: {
  className?: string
  slug: string
  items: GradeBookScore["items"]
}) => {
  return (
    <div className={className}>
      <Typography variant="title4" className="mb-2">
        Course-wise Breakdown
      </Typography>
      <div className="flex flex-col gap-1">
        {items
          .sort((a, b) => {
            if (a.score && a.score.creditsEarned !== null) {
              if (!b.score || b.score.creditsEarned === null) return -1
              return 0
            }
            if (a.score && a.score.creditsEarned === null) {
              if (!b.score || b.score === null) return -1
              if (b.score.creditsEarned !== null) return 1
              return 0
            }
            return 1
          })
          .map((item, index) => (
            <CourseItem data={item} key={index} slug={slug} />
          ))}
      </div>
    </div>
  )
}

export default CourseWiseBreakDown
