import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getHermesUrl = getServiceURL(SERVICE_PREFIX.hermes)

export const urls = {
  retrieve: (id: string | number, type: string | number) =>
    getHermesUrl(`/v1/prompt-history/${id}/${type}/`),
  history: () => getHermesUrl(`/v1/usage/history`),
  lessonPlan: {
    pro: {
      generate: () => getHermesUrl("/v1/lesson-plan/pro/"),
    },

    export: (id: number | string) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/lesson-plan/responses/export/`),

    generate: () => getHermesUrl("/v1/lesson-plan/"),
    list: () => getHermesUrl("/v1/lesson-plan/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/lesson-plan/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/lesson-plan/prompts/${id}/`),
  },
  rubrics: {
    generate: () => getHermesUrl("/v1/rubrics/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/rubrics/responses/export/`),

    generatePro: () => getHermesUrl("/v1/rubrics/pro/"),
    list: () => getHermesUrl("/v1/rubrics/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/rubrics/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/rubrics/prompts/${id}/`),
  },
  quiz: {
    generatePro: () => getHermesUrl("/v1/quiz/pro/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/quiz/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/quiz/responses/export/`),

    generate: () => getHermesUrl("/v1/quiz/"),
    list: () => getHermesUrl("/v1/quiz/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/quiz/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/quiz/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/quiz/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/quiz/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/quiz/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/quiz/prompts/${id}/`),
  },
  handout: {
    generate: () => getHermesUrl("/v1/handouts/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/handouts/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/handouts/responses/export/`),

    list: () => getHermesUrl("/v1/handouts/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/handouts/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/handouts/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/handouts/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/handouts/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/handouts/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/handouts/prompts/${id}/`),
  },
  narration: {
    generate: () => getHermesUrl("/v1/narrations/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/narrations/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/narrations/responses/export/`),

    list: () => getHermesUrl("/v1/narrations/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/narrations/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/narrations/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/narrations/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/narrations/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/narrations/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/narrations/prompts/${id}/`),
  },
  subjectiveAssessment: {
    generate: () => getHermesUrl("/v1/subjective-assessment/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/export/`),
    exportPost: () =>
      getHermesUrl(`/v1/subjective-assessment/responses/export/`),

    generatePro: () => getHermesUrl("/v1/subjective-assessment/pro/"),
    list: () => getHermesUrl("/v1/subjective-assessment/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/subjective-assessment/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/subjective-assessment/prompts/${id}/`),
  },
  assignment: {
    generate: () => getHermesUrl("/v1/assignment/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/assignment/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/assignment/responses/export/`),

    generatePro: () => getHermesUrl("/v1/assignment/pro/"),
    list: () => getHermesUrl("/v1/assignment/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/assignment/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/assignment/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/assignment/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/assignment/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/assignment/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/assignment/prompts/${id}/`),
  },
  activityPlanner: {
    generate: () => getHermesUrl("/v1/activity-planner/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/activity-planner/responses/export/`),

    list: () => getHermesUrl("/v1/activity-planner/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/activity-planner/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/activity-planner/prompts/${id}/`),
  },
  videoSummary: {
    generate: () => getHermesUrl("/v1/video-summary/"),

    export: (id: number | string) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/video-summary/responses/export/`),

    retrieveVideoDuration: () => getHermesUrl("/v1/video-summary/duration/"),
    list: () => getHermesUrl("/v1/video-summary/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/video-summary/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/video-summary/prompts/${id}/`),
  },
  unitPlanner: {
    export: (id: number | string) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/unit-planner/prompt/export/`),

    generateUnitPlanPrompt: () => getHermesUrl("/v1/unit-planner/prompt/"),
    retrieveUnitPlanStructure: () => getHermesUrl("/v1/unit-planner/"),
    regenerateUnitPlanDivision: (id: number | string) =>
      getHermesUrl(
        `/v1/unit-planner/unit-division/responses/${id}/regenerate/`
      ),
    updateReactionUnitPlanDivision: (id: number | string) =>
      getHermesUrl(`/v1/unit-planner/unit-division/responses/${id}/reaction/`),
    generateUnitPlan: () => getHermesUrl("/v1/unit-planner/"),
    list: () => getHermesUrl("/v1/unit-planner/"),
    retrieve: (id: number | string) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/`),
    updateUserReaction: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/reaction/`),

    regenerateResponse: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/regenerate/`),
    stream: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/stream/`),
    updateData: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/`),

    getPromptData: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/prompt/${id}/`),
    generateUnitDivision: () => getHermesUrl(`/v1/unit-planner/unit-division/`),

    retrieveUnitDivisionResponse: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/unit-division/responses/${id}/`),
    retrieveUnitPlanResponse: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/responses/${id}/`),
    updateUnitDivisionData: (id: string | number) =>
      getHermesUrl(`/v1/unit-planner/unit-division/responses/${id}/`),
  },
  coverLetter: {
    export: (id: number | string) =>
      getHermesUrl(`/v1/cover-letter/responses/${id}/export/`),
    exportPost: () => getHermesUrl(`/v1/cover-letter/responses/export/`),

    list: () => getHermesUrl(`/v1/cover-letter/`),
    detail: (id: string | number) => getHermesUrl(`/v1/cover-letter/${id}/`),
    reaction: (id: string | number) =>
      getHermesUrl(`/v1/cover-letter/${id}/reaction/`),
  },

  download: (id: string | number, type: string | number) =>
    getHermesUrl(`/v1/download/ai-tool/pdf/${id}/${type}/`),
  resumeFiller: {
    create: () => getHermesUrl(`/v1/resume-profile-filler/`),
    retrieve: () => getHermesUrl(`/v1/resume-profile-filler/`),
    update: (id: string | number) =>
      getHermesUrl(`/v1/resume-profile-filler/${id}/user-selection/`),
  },
}
