import { CircularProgress, Typography } from "@suraasa/placebo-ui"
import { useInfiniteQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ListPromptHistory, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import FullPageLoading from "components/FullPageLoading"
import { getToolNamePlural } from "features/AItools/helper"
import usePaginationScroll from "utils/hooks/usePaginationScroll"

import HistoryItem from "../HistoryItem"

const TabContent = ({
  type,
  tempHistory,
  useReplaceWhileNavigating = false,
  isGenerator,
}: {
  type?: ToolType
  tempHistory?: ListPromptHistory[]
  useReplaceWhileNavigating?: boolean
  isGenerator?: boolean
}) => {
  const {
    isFetching,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    data,
    isLoading,
    isError,
  } = useInfiniteQuery({
    queryKey: queries.aiTools.listHistory(type).queryKey,
    queryFn: x =>
      api.aiTools.listHistory({
        type: type,
        params: { page: x.pageParam || 1 },
      }),
    onError: err => {
      if (err instanceof APIError) {
        console.log(err.statusCode)
        console.log(err.is500)
        console.log(err.__errors)
      }
    },

    getNextPageParam: lastPage => {
      return lastPage.nextPage ?? undefined
    },
  })

  const { ref } = usePaginationScroll({
    loading: isFetching,
    hasNextPage,
    actionFunc: fetchNextPage,
  })

  const getContentLength = () => {
    const items = (Boolean(data?.pages?.length) && data?.pages[0]?.total) || 0
    const tempItems = tempHistory?.length || 0

    return items + tempItems
  }

  return (
    <div>
      {isLoading && <FullPageLoading />}
      {isError && (
        <div className="p-2">
          History not available. Please try again later.
        </div>
      )}
      {tempHistory?.map(i => (
        <HistoryItem
          isGenerator={isGenerator}
          {...i}
          toolType={type || i.toolType}
          key={i.title}
          showType={type ? false : true}
          useReplaceWhileNavigating={useReplaceWhileNavigating}
        />
      ))}
      {getContentLength() === 0 && type && (
        <Typography variant="smallBody" className="mt-2">
          Start generating {getToolNamePlural(type)} to see your generation
          history!
        </Typography>
      )}
      {isSuccess &&
        data?.pages?.map((page, pageNumber) =>
          page.data?.map((prompt, index) => {
            const PAGE_SIZE = 10
            // Doing +1 because index starts from zero
            const currentIndex = pageNumber * PAGE_SIZE + (index + 1)

            // Doing -1 because page length should start from 0
            const totalLoadedItems =
              (data.pages.length - 1) * PAGE_SIZE + page.data.length

            const isLastItem = currentIndex === totalLoadedItems

            const toolType = prompt.toolType

            return (
              <div
                key={`${pageNumber}-${index}-${prompt.promptDataId}`}
                ref={isLastItem ? ref : null}
              >
                <HistoryItem
                  isGenerator={isGenerator}
                  {...prompt}
                  toolType={toolType}
                  showType={type ? false : true}
                  useReplaceWhileNavigating={useReplaceWhileNavigating}
                />
              </div>
            )
          })
        )}
      {isFetching && (
        <div className="flex items-center justify-center py-2">
          <CircularProgress />
        </div>
      )}
    </div>
  )
}

export default TabContent
