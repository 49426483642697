import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import { AssignmentForm, ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { getToolName, pointScaleOptions } from "features/AItools/helper"
import { Controller, useForm } from "react-hook-form"

import { AssignmentReturnType } from "../useAssignment"

const toolType = ToolType.assignment

type Props = Pick<AssignmentReturnType, "generateTool">

const BasicForm = ({ generateTool }: Props) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<AssignmentForm>()

  const onSubmit = handleSubmit(async data => {
    await generateTool.mutateAsync({ data, setError })
  })
  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className=" grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            required
            label="Class/Grade"
            placeholder="Ex: 10th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            required
            label="Curriculum"
            placeholder="Ex: CBSE"
            errors={errors.curriculum?.message}
          />

          <TextField
            required
            label="Subject"
            placeholder="Ex: Economics"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="rubricScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                required
                errors={errors.rubricScale?.message}
                label="Rubric Point Scale"
                options={pointScaleOptions}
                placeholder="Ex: 5"
                mountOnBody
                onBlur={onBlur}
                value={pointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
        </div>
        <TextArea
          required
          rows={6}
          label="Objective/Description"
          placeholder={`Ex: Define resources and their contribution to economic development.\nRecognize the different types of resources.\nUnderstand the importance of resource planning and management.\nEvaluate the role of technology in sustainable resource management.\nExamine resource-related issues and challenges in contemporary India.`}
          errors={errors.assignmentObjectives?.message}
          {...register("assignmentObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
