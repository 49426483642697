import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import Heading from "features/AItools/components/Heading"
import TogglePro from "features/AItools/components/TogglePro"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"

import ContentActions from "../../GenerationOutput/Export/ContentActions"
import SubjectiveAssessmentOutput from "../../GenerationOutput/SubjectiveAssessmentOutput"
import { useSubjectiveAssessment } from "../useSubjectiveAssessment"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"

type Props = {
  openLessonPlanSelector?: boolean
}

const SubjectiveAssessmentForm = ({
  openLessonPlanSelector = false,
}: Props) => {
  const [enableProMode, setEnableProMode] = usePlusFeature({
    ensurePlusAccess: false,
  })

  const {
    currentResponseId,
    setCurrentResponseId,
    promptDataId,
    pageLoading,
    onBack,
    ...subjectiveAssessmentContext
  } = useSubjectiveAssessment()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.subjectiveAssessment}
          title={subjectiveAssessmentContext.overviewData?.title}
        />
        <SubjectiveAssessmentOutput
          {...subjectiveAssessmentContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.subjectiveAssessment)} Generator`}
        subtitle="Craft customised subjective evaluations, ensuring a comprehensive learning experience."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm
          {...subjectiveAssessmentContext}
          openLessonPlanSelector={openLessonPlanSelector}
        />
      ) : (
        <BasicForm
          {...subjectiveAssessmentContext}
          openLessonPlanSelector={openLessonPlanSelector}
        />
      )}
    </>
  )
}

export default SubjectiveAssessmentForm
