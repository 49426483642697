import { Button, IconButton, Tooltip, Typography } from "@suraasa/placebo-ui"
import Plus from "assets/TeachersMonthOnboarding/Plus.webp"
import AsyncBuilder from "components/AsyncBuilder"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { InfoCircle, Xmark } from "iconoir-react"
import { trackingService } from "utils/tracking"

import MonthSVG from "./assets/MonthSVG"
import StrikeSVG from "./assets/StrikeSVG"

const Content = ({
  handleClose,
  hasVerifiedPhoneNumber,
  openPhoneNumberVerificationDialog,
  openProfileDetailsDialog,
}: {
  handleClose: () => void
  hasVerifiedPhoneNumber: boolean
  openPhoneNumberVerificationDialog: () => void
  openProfileDetailsDialog: () => void
}) => {
  const { claimSuraasaPlus } = useSuraasaPlusContext()

  return (
    <div className="relative bg-[#0F172A] bg-[radial-gradient(at_0%_-30%,rgba(7,206,136,0.8)_0px,transparent_50%),radial-gradient(at_50%_-40%,rgba(37,75,198,1)_0px,transparent_50%),radial-gradient(at_100%_-25%,rgba(146,88,168,0.8)_0px,transparent_50%)] pb-3 pt-4">
      <IconButton
        color={null}
        onClick={() => {
          handleClose()
          trackingService.trackEvent("wtm_popup_dismissed")
        }}
        className="absolute right-2 top-3 grid size-[30px] place-items-center text-white"
      >
        <Xmark className="size-3" />
      </IconButton>
      <Typography variant="title2" className="mb-1 text-center text-white">
        World Teachers{" "}
        <span className="relative">
          Day
          <StrikeSVG className="absolute right-0 top-0" />
        </span>{" "}
        <span className="inline-block">
          <MonthSVG />
        </span>
      </Typography>
      <Typography className="mx-auto mb-3.5 max-w-[355px] text-center text-onSurface-300">
        Have more time for yourself and everything you love with a{" "}
        <div className="inline-block">
          <Tooltip
            content={
              <>
                <span className="block text-left text-sm font-bold leading-[18.2px]">
                  What’s Included?
                </span>
                <span className="block text-left text-sm font-normal leading-[18.2px]">
                  AI Lesson Plan Generator, Quiz Generator, and 9 other AI
                  tools. 150+ hours of recorded masterclasses by international
                  experts
                </span>
              </>
            }
          >
            <Typography
              variant="strong"
              className="inline-flex cursor-pointer items-center"
            >
              Suraasa Plus{" "}
              <InfoCircle className="inline-block size-2 stroke-[3px]" />
            </Typography>
          </Tooltip>
        </div>{" "}
        subscription.
      </Typography>
      <div className="mx-2 mb-0.5 rounded-2xl bg-white px-2 pb-2 pt-3">
        <Typography
          variant="title1"
          className="mb-1.5 text-center text-onSurface-900"
        >
          Get 2 Months of <br /> Suraasa{" "}
          <span className="relative">
            Plus
            <img
              src={Plus}
              alt="plus"
              className="absolute -right-1.25 -top-px size-2 md:-right-1.75 md:-top-0.25 md:size-3"
            />
          </span>
          {"  "}
          for Free!
        </Typography>
        <Typography
          className="mx-auto mb-3.5 max-w-[480px] text-center text-onSurface-500"
          variant="largeBody"
        >
          Help us with your work experience, subjects, and grades for a
          personalised experience. 😊
        </Typography>
        <Button
          className="w-full"
          onClick={
            hasVerifiedPhoneNumber
              ? openProfileDetailsDialog
              : openPhoneNumberVerificationDialog
          }
          rounded
          size="sm"
        >
          Share Details & Get 2 Months Free
        </Button>
      </div>
      <div className="mt-2 flex justify-center">
        <AsyncBuilder
          handler={async () =>
            await claimSuraasaPlus({ hasFilledProfile: false })
          }
          render={({ loading, onClick }) => (
            <Button
              onClick={onClick}
              loading={loading}
              variant="text"
              color={null}
              className="text-white"
            >
              Skip personalisation & get 1 month of Suraasa Plus free
            </Button>
          )}
        />
      </div>
    </div>
  )
}

export default Content
