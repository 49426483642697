import {
  Button,
  Select,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import { NarrationForm, ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
  getToolName,
} from "features/AItools/helper"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import { NarrationReturnType } from "../useNarration"

const toolType = ToolType.narration

type Props = Pick<NarrationReturnType, "generateTool">

const BasicForm = ({ generateTool }: Props) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NarrationForm>({
    defaultValues: {
      bloomTaxonomyLevel: ["remembering"],
      country: "India",
      curriculum: "CBSE",
      duration: "00:45:00",
      grade: "10th",
      subject: "English",
      topic: "Direct and Indirect speech",
      lessonPlanObjectives:
        "Students should learn the difference between direct and indirect speech\nStudents should be able to convert direct speech to indirect speech",
    },
  })

  const onSubmit = handleSubmit(async data => {
    await generateTool.mutateAsync({ data, setError })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Class/Grade"
            placeholder="Ex: 12th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            label="Curriculum"
            placeholder="Ex: CBSE"
            errors={errors.curriculum?.message}
          />
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Subject"
            placeholder="Ex: Arts and Craft"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="Ex: 60 Minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />
        </div>
        <TextField
          label="topic"
          placeholder="Ex: Rajasthani Paintings"
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <TextArea
          rows={3}
          label="Objective/Description"
          placeholder="Ex: To understand the history of Rajasthani paintings and its significance in Indian art."
          errors={errors.lessonPlanObjectives?.message}
          {...register("lessonPlanObjectives", {
            required: { value: true, message: "Required" },
          })}
        />
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
            label="Country"
            placeholder="Ex: India"
            errors={errors.country?.message}
            helperText={`We use this to tailor the ${getToolName(
              toolType
            )} to your region`}
          />
          <div className="flex flex-[50%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Evaluate, Create"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default BasicForm
