import { Typography } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { NarrationWithLessonPlannerReturnType } from "features/AItools/components/Generator/LessonPlanGenerator/useNarrationWithLessonPlanner"
import { getToolName } from "features/AItools/helper"
import { SoundLow } from "iconoir-react"
import React, { useState } from "react"

import GenerateButton from "../../../GenerateButton"
import RegenerateOutput from "../../../RegenerateOutput"
import ToolOutput from "../../../ToolOutput"
import { generateUuidV4 } from "./helper"

const toolType = ToolType.narration

const NarrationContent = ({
  narrationIds: narrationList,
  finalizedOutputs,
  isLoading,
  generateToolWithLessonPlan,
  lessonPlanId,
  output,
  userVote,
  regenerateResponse,
  onBack,
  ...props
}: {
  narrationIds: { id: number; responseIds: number[] }[]
  lessonPlanId: number | undefined
  onBack: () => void
} & Pick<
  NarrationWithLessonPlannerReturnType,
  | "isLoading"
  | "finalizedOutputs"
  | "generateToolWithLessonPlan"
  | "output"
  | "userVote"
  | "regenerateResponse"
  | "setCurrentResponseId"
>) => {
  const narration = narrationList.length === 1 ? narrationList[0] : undefined
  const [currentResponseId, setState] = useState<number | null>(
    narration && narration.responseIds.length > 0
      ? narration.responseIds[0]
      : null
  )

  const setCurrentResponseId = (id: number, t?: { skipCall: boolean }) => {
    setState(id)
    if (!t?.skipCall) {
      props.setCurrentResponseId(id)
    }
  }

  const generate = () => {
    generateToolWithLessonPlan.mutate({
      data: {
        lessonPlanResponse: lessonPlanId!,
        generatedWithLessonPlan: true,
        groupId: generateUuidV4(),
      },
      onSuccess(id) {
        setCurrentResponseId(id, { skipCall: true })
      },
    })
  }
  if (!narration && !currentResponseId) {
    return (
      <div className="grid place-content-center py-5">
        <div className="flex flex-col items-center gap-2">
          <div className="grid size-15 place-items-center rounded-full bg-success-50 text-success-500">
            <SoundLow className="size-10" strokeWidth={1} />
          </div>
          <Typography variant="title3" className="max-w-[400px] text-center">
            Looks like you haven’t generated any {getToolName(toolType)} for
            this Lesson Plan.
          </Typography>
          <GenerateButton
            onClick={() => {
              generate()
            }}
            loading={isLoading}
            label="Generate"
          />
        </div>
      </div>
    )
  }

  const currentData = finalizedOutputs.find(
    item => item.id === currentResponseId
  )
  const currentOutput = currentData?.output || output
  return (
    <div>
      <div>
        <ToolOutput
          showTitle
          isLoading={isLoading}
          onClick={onBack}
          className="px-4 py-3"
        >
          {currentOutput}
        </ToolOutput>
      </div>
      {!isLoading && (
        <RegenerateOutput
          responseIds={narration?.responseIds}
          currentResponseId={currentResponseId}
          copyText={currentOutput}
          isPositiveResponse={currentData?.isPositiveResponse}
          onResponseIdChange={responseId => {
            setCurrentResponseId(responseId)
          }}
          userReaction={{
            userReaction: reaction => {
              userVote.mutate({ reaction, id: currentResponseId })
            },
          }}
          regenerate={{
            regenerateResponse: reason => {
              regenerateResponse.mutate({
                reason,
                id: currentResponseId,
                onSuccess(id) {
                  setCurrentResponseId(id, { skipCall: true })
                },
              })
            },
          }}
        />
      )}
    </div>
  )
}

export default React.memo(NarrationContent)
