import api from "api"
import FullPageLoading from "components/FullPageLoading"
import { usePostHog } from "posthog-js/react"
import { useEffect, useState } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { BROWSER_STORAGE_KEYS } from "utils/constants"
import { buildUserName, getAuthInfo } from "utils/helpers"
import { GA } from "utils/tracking/ga"
import { getUTMParams } from "utils/tracking/utmParams"

const GlobalAuthObserver = () => {
  const [authIsValid, setAuthIsValid] = useState<boolean>(false)

  const posthog = usePostHog()

  useEffect(() => {
    GA.init()
  }, [])

  useEffect(() => {
    const utmParams = getUTMParams(false)

    try {
      sessionStorage.setItem(
        BROWSER_STORAGE_KEYS.sessionUtmParams,
        JSON.stringify(utmParams, null, 2)
      )
    } catch (e) {
      /* empty */
    }
  }, [])

  const location = useLocation() // new

  useEffect(() => {
    // console.log(`> Capturing page view: ${location.pathname}`)
    posthog.capture("$pageview")

    // return () => {
    // posthog.capture("$pageleave")
    // console.log(`> Capturing page leave: ${location.pathname}`)
    // }
  }, [location])

  useEffect(() => {
    const checkAuth = async () => {
      const authInfo = getAuthInfo()
      try {
        if (authInfo) {
          const res = await api.users.verifyAccessToken({
            data: {
              token: authInfo.accessToken,
            },
            headers: {
              Authorization: null,
            },
          })

          posthog?.identify(authInfo.user.uuid, {
            email: authInfo.user.email,
            name: buildUserName(authInfo.user),
          })

          setAuthIsValid(res.verified)
        }
      } catch (e) {
        console.log("> Access token is invalid", e)
      }
    }

    if (getAuthInfo()) {
      checkAuth()
    } else {
      setAuthIsValid(true)
    }
  }, [])

  return (
    <>
      {!authIsValid && <FullPageLoading />}
      {authIsValid && <Outlet />}
    </>
  )
}

export default GlobalAuthObserver
