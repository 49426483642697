import Paper from "features/AItools/components/Paper"

import { HandoutReturnType } from "../../HandoutGenerator/useHandout"
import InputDisplay from "../../helpers/InputDisplay"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"

const HandoutOutput = (
  props: Pick<
    HandoutReturnType,
    | "output"
    | "isLoading"
    | "responseIds"
    | "currentResponseId"
    | "output"
    | "isPositiveResponse"
    | "setCurrentResponseId"
    | "userVote"
    | "regenerateResponse"
    | "overviewData"
    | "onBack"
  >
) => {
  const {
    output,
    isLoading,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    overviewData,
    onBack,
  } = props
  return (
    <div className="mb-4 space-y-4">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            bloomTaxonomyLevel: overviewData.bloomTaxonomyLevel,
            subject: overviewData.subject,
            grade: overviewData.grade,
            duration: overviewData.duration,
            topic: overviewData.topic,
            country: overviewData.country,
            curriculum: overviewData.curriculum,
            title: overviewData.title || "",
            responseIds: overviewData.responseIds || [],
            //To handle this
            unitDivisionResponses: [],
            classDuration: "",
          }}
        />
      )}
      <Paper
        className="p-0"
        endSlot={
          !isLoading ? (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate({ reaction })
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate({ reason })
                },
              }}
            />
          ) : undefined
        }
      >
        {/* {overviewData?.title && (
          <div className="flex items-center justify-between gap-1">
            <Typography variant="title2" className="grow">
              {capitalizeFirstLetter(overviewData.title)}
            </Typography>
          </div>
        )} */}

        <ToolOutput isLoading={isLoading} onClick={onBack}>
          {output}
        </ToolOutput>
      </Paper>
    </div>
  )
}

export default HandoutOutput
