import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"

import Heading from "../../Heading"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import UnitPlanOutput from "../GenerationOutput/UnitPlanOutput"
import ProForm from "./ProForm"
import { useUnitPlanner } from "./useUnitPlanner"

const UnitPlanGenerator = () => {
  // const {
  //   promptDataId,
  //   overviewData,
  //   setCurrentResponseId,
  //   setPromptDataId,
  //   // currentResponseId,
  //   setResponseIds,
  //   // responseIds,
  // } = useContext(AIToolsContext)

  // const outputMode = overviewData ? getOutputMode(overviewData) : ""

  // useEffect(() => {
  //   console.log(overviewData)
  //   if (!overviewData) return

  //   const { unitPlanResponses, unitDivisionResponses } = overviewData

  //   if (outputMode === "unitPlanIntermediate") {
  //     setCurrentResponseId(unitDivisionResponses[0])
  //     setResponseIds(unitDivisionResponses)
  //     return
  //   }

  //   if (outputMode === "unitPlanTopic" && unitPlanResponses) {
  //     setCurrentResponseId(unitPlanResponses[0]?.responseIds[0])
  //     setResponseIds(unitPlanResponses[0]?.responseIds)
  //     return
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [overviewData])

  // if (promptDataId && !overviewData) {
  //   return (
  //     <div className="flex flex-col items-center justify-center p-5">
  //       <CircularProgress />
  //     </div>
  //   )
  // }
  const {
    currentResponseId,
    setCurrentResponseId,
    onBack,
    pageLoading,
    promptDataId,
    ...unitPlannerContext
  } = useUnitPlanner()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.unitPlanner}
          title={unitPlannerContext.overviewData?.title}
          // Don't show export button on intermediate output
          // hideExportOutputButton={outputMode === "unitPlanIntermediate"}
        />

        {/* <pre>
          {JSON.stringify(
            {
              promptDataId,
              id,
              currentResponseId,
              responseIds,
            },
            null,
            2
          )}
        </pre> */}

        <UnitPlanOutput
          {...unitPlannerContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.unitPlanner)} Generator`}
        subtitle="Effortlessly design detailed unit plans that organize your curriculum and streamline your teaching process."
        isPro
      />
      <ProForm {...unitPlannerContext} />
    </>
  )
}

export default UnitPlanGenerator
