import {
  Button,
  CircularProgress,
  cn,
  Dialog,
  DialogBody,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Select,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import api from "api"
import { OTPResponse } from "api/resources/settings/types"
import { PhoneNumber } from "api/resources/users/types"
import { APIError } from "api/utils"
import clsx from "clsx"
import ResendOTPButton from "components/ResendOTPButton"
import { useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import OTPInput from "react-otp-input"
import countryCodes from "utils/countryCodes"
import { getPhoneNumber, handleErrors, mapErrors } from "utils/helpers"
import useCurrentCountry from "utils/hooks/useCurrentCountry"
import { useGRecaptcha } from "utils/hooks/useGRecaptcha"

const formIds = {
  genOTP: "gen-otp",
  verifyOTP: "verify-otp",
}
const PhoneNumberVerificationDialog = ({
  open,
  onClose,
  onSubmit,
  userPhoneNumber,
  setPhoneNumber,
}: {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  userPhoneNumber: string | null
  setPhoneNumber: (number: string) => void
}) => {
  const [otpResponse, setOtpResponse] = useState<OTPResponse | null>(null)

  const { submitWithCaptcha } = useGRecaptcha({
    action: "auth",
    checkboxContainer: "#phone-number-checkbox-captcha",
  })

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    getValues,
    formState: { errors },
  } = useForm<{
    phoneNumber: PhoneNumber
    otp: string
  }>()

  const country = useCurrentCountry()
  const defaultDialCode = countryCodes.find(
    item => item.code === country?.isoCode
  )

  useEffect(() => {
    if (open) setOtpResponse(null)
  }, [open])

  const generateOTP = useMutation({
    mutationFn: ({ number, captcha }: { number: string; captcha: any }) =>
      api.settings.addPhoneNumber({
        data: { phoneNumber: number, channel: "both", captcha },
      }),
    onSuccess: (data, formData) => {
      setPhoneNumber(formData.number)
      toast.success("OTP has been sent to your whatsapp/SMS.")
      setOtpResponse(data)
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })
  const resendOtpMutate = useMutation({
    mutationFn: ({ captcha }: { captcha: any }) =>
      api.settings.sendOTP({
        data: {
          token: otpResponse?.token,
          captcha,
        },
      }),
    onSuccess: data => {
      toast.info("We've sent you a new OTP")
      setOtpResponse(data)
    },
    onError: err => {
      if (err instanceof APIError) {
        handleErrors(err)
      }
    },
  })

  const resendOtp = async () => {
    await submitWithCaptcha(async captcha => {
      await resendOtpMutate.mutateAsync({ captcha })
    })()
  }

  const queryClient = useQueryClient()

  const verifyOTP = useMutation({
    mutationFn: (otp: string) =>
      api.settings.verifyOtp({
        data: {
          token: otpResponse?.token,
          otp,
        },
      }),
    onSuccess: data => {
      toast.success("Phone number verified successfully.")
      onSubmit()

      api.users
        .updateUser({
          data: {
            phoneNumber: getPhoneNumber(getValues("phoneNumber")),
            smsOtpToken: data.token,
          },
        })
        .then(() => {
          queryClient.invalidateQueries(["retrieve-solis-user"])
        })
    },
    onError: err => {
      if (err instanceof APIError) {
        mapErrors(setError, err, [["otp"]])
      }
    },
  })

  useEffect(() => {
    if (defaultDialCode) {
      setValue("phoneNumber.code", defaultDialCode.dialCode)
      reset({ phoneNumber: { code: defaultDialCode.dialCode } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDialCode])

  const onSubmitToGetOTP = handleSubmit(async data => {
    const { phoneNumber } = data

    let hasError = false

    if (!phoneNumber.code || !phoneNumber.number) {
      hasError = true
    }
    if (hasError) return
    await submitWithCaptcha(async captcha => {
      if (!captcha) {
        toast.error("Please verify the captcha")
        return
      }
      await generateOTP.mutateAsync({
        number: getPhoneNumber(data.phoneNumber),
        captcha,
      })
    })()
  })

  const onSubmitToVerifyOTP = handleSubmit(async data => {
    const { otp } = data

    let hasError = false

    if (!otp) {
      hasError = true
    }
    if (hasError) return

    await verifyOTP.mutateAsync(otp)
  })

  return (
    <Dialog modal open={open} onOpenChange={onClose}>
      <DialogContent className="max-w-[420px]">
        <DialogHeader>
          <DialogTitle
            onBack={
              otpResponse
                ? () => {
                    setOtpResponse(null)
                  }
                : undefined
            }
          >
            <Typography variant="strong">
              {otpResponse
                ? "Verify your Phone Number"
                : "Enter your Phone Number"}
            </Typography>
          </DialogTitle>
        </DialogHeader>
        <DialogBody className="relative p-0">
          {(generateOTP.isLoading || verifyOTP.isLoading) && (
            <div className="absolute left-0 top-0 z-1 grid size-full place-items-center bg-white/75">
              <CircularProgress />
            </div>
          )}
          {!otpResponse && (
            <div className={cn("py-3 px-1.5")}>
              <form
                id={formIds.genOTP}
                onSubmit={onSubmitToGetOTP}
                className="flex items-start gap-1"
              >
                <Controller
                  control={control}
                  name="phoneNumber.code"
                  render={({ field: { onBlur, onChange, value } }) => (
                    <Select
                      required
                      label="Country Code"
                      errors={errors.phoneNumber?.code?.message}
                      getOptionLabel={option => option.dialCode}
                      getOptionValue={option => option.dialCode}
                      options={countryCodes}
                      // placeholder="+91"
                      className="w-[117px] shrink-0"
                      value={countryCodes.find(c => c.dialCode === value)}
                      mountOnBody
                      onBlur={onBlur}
                      onChange={val => {
                        if (val) onChange(val.dialCode)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
                <TextField
                  label="Phone Number"
                  required
                  placeholder="Ex: 991008899"
                  errors={errors.phoneNumber?.number?.message}
                  {...register("phoneNumber.number", {
                    required: { value: true, message: "Required" },
                  })}
                />
              </form>
              <div id="phone-number-checkbox-captcha" className="mt-2" />
            </div>
          )}
          {otpResponse && (
            <form
              className="px-2.5 py-3"
              id={formIds.verifyOTP}
              onSubmit={onSubmitToVerifyOTP}
            >
              <Typography className="mb-2">
                OTP sent to <b>{userPhoneNumber}</b> on <b>SMS & WhatsApp</b>
              </Typography>

              <Controller
                control={control}
                name="otp"
                render={({ field }) => (
                  <OTPInput
                    numInputs={6}
                    {...field}
                    containerStyle="justify-start gap-1"
                    inputStyle={clsx(
                      "h-[50px] !w-[41px] rounded-md border-2 border-solid border-onSurface-500 text-title2 focus:ring-2 focus:ring-interactive-500 focus:ring-offset-1",
                      {
                        "border-critical-500 text-critical-500": Boolean(
                          errors.otp
                        ),
                      }
                    )}
                    inputType="number"
                    renderInput={props => <input {...props} />}
                    shouldAutoFocus
                  />
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {Boolean(errors.otp) && (
                <Typography
                  className="mt-1 text-critical-500"
                  variant="smallBody"
                >
                  {errors.otp?.message}
                </Typography>
              )}

              <div className="mt-2 flex flex-wrap items-center">
                <Typography className="me-1 shrink-0">
                  Didn’t receive OTP?
                </Typography>
                <ResendOTPButton
                  resendAt={otpResponse?.resendAt}
                  text="Send Again"
                  onClick={() => resendOtp()}
                />
              </div>
            </form>
          )}
        </DialogBody>
        <DialogFooter>
          <Button
            type="submit"
            form={otpResponse ? formIds.verifyOTP : formIds.genOTP}
            size="sm"
            loading={
              generateOTP.isLoading ||
              verifyOTP.isLoading ||
              resendOtpMutate.isLoading
            }
          >
            {otpResponse ? "Verify Number" : "Verify"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default PhoneNumberVerificationDialog
