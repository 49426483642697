import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Select,
  TextArea,
  TextField,
  Typography,
} from "@suraasa/placebo-ui"
import {
  DifficultyLevel,
  ListPromptHistory,
  SubjectiveAssessmentProFormType,
  ToolType,
} from "api/resources/aiTools/types"
import ErrorMessage from "components/ErrorMessage"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { Edit } from "iconoir-react"
import { useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useSearchParams } from "react-router-dom"

import SelectLessonPlanSheet from "../../../helpers/SelectLessonPlanSheet"
import AssessmentStructure, {
  AssessmentStructureRow,
} from "../../AssessmentStructure"
import { SubjectiveAssessmentReturnType } from "../../useSubjectiveAssessment"

type Form = SubjectiveAssessmentProFormType

const LEVEL_OF_DIFFICULTY: { value: DifficultyLevel; label: string }[] = [
  { value: "easy", label: "Easy" },
  { value: "medium", label: "Medium" },
  { value: "difficult", label: "Difficult" },
  { value: "expert", label: "Expert" },
]

const toolType = ToolType.subjectiveAssessmentPro

type Props = { openLessonPlanSelector?: boolean } & Pick<
  SubjectiveAssessmentReturnType,
  "generateProTool"
>

const ProForm = ({ openLessonPlanSelector, generateProTool }: Props) => {
  const [searchParams] = useSearchParams()
  const mode = searchParams.get("mode") // "new" | "template"
  const isTemplateMode = mode === "template"

  const [structure, setStructure] = useState<AssessmentStructureRow[] | null>(
    null
  )
  const [difficultyLevel, setDifficultyLevel] = useState<{
    value: DifficultyLevel
    label: string
  } | null>(null)

  const [showErrors, setShowErrors] = useState<{
    selectedLessonPlan?: string
    difficultyLevel?: string
    structure?: string
  } | null>(null)

  const [openSidebar, setOpenSidebar] = useState(false)

  const [selectedLessonPlan, setSelectedLessonPlan] =
    useState<ListPromptHistory | null>(null)
  const [lessonPlanResponse, setLessonPlanResponse] = useState<number>(-1)

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
  } = useForm<Form>()

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (isTemplateMode) {
      let hasError = false

      if (!selectedLessonPlan) {
        setShowErrors(prev => ({
          ...prev,
          selectedLessonPlan: "Lesson Plan is required",
        }))
        hasError = true
      }

      if (!difficultyLevel) {
        setShowErrors(prev => ({
          ...prev,
          difficultyLevel: "Difficulty level is required",
        }))
        hasError = true
      }

      if (!structure || structure.length === 0) {
        setShowErrors(prev => ({
          ...prev,
          structure: "Assessment structure is required",
        }))
        hasError = true
      }

      if (hasError) return
      setShowErrors(null)

      ensurePlusAccess(async () => {
        await generateProTool.mutateAsync({
          data: {
            // lessonPlanResponse:
            //   selectedLessonPlan &&
            //   selectedLessonPlan.responseIds &&
            //   selectedLessonPlan.responseIds.length > 1
            //     ? lessonPlanResponse
            //     : selectedLessonPlan?.promptDataId,
            lessonPlanResponse,
            questionDetails: structure,
            difficultyLevel: difficultyLevel!.value,
          },
          setError,
        })
      })
      return
    }
    let hasError = false
    if (!structure || structure.length === 0) {
      setShowErrors(prev => ({
        ...prev,
        structure: "Assessment structure is required",
      }))
      hasError = true
    }

    if (hasError) return
    setShowErrors(null)

    ensurePlusAccess(async () => {
      await generateProTool.mutateAsync({
        data: {
          grade: data.grade,
          subject: data.subject,
          curriculum: data.curriculum,
          topic: data.topic,
          country: data.country,
          assessmentObjectives: data.assessmentObjectives,
          difficultyLevel: data.difficultyLevel,
          questionDetails: structure,
          ...(data.book ? { book: data.book } : {}),
        },
        setError,
      })
    })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="space-y-2">
        {!isTemplateMode ? (
          <div className="flex flex-col gap-2">
            <div className=" grid grid-cols-1 gap-2 sm:grid-cols-2">
              <TextField
                required
                label="Class/Grade"
                placeholder="Ex: 12th Grade"
                errors={errors.grade?.message}
                {...register("grade", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                {...register("country", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Country"
                placeholder="Ex: India"
                errors={errors.country?.message}
                helperText="We use this to tailor the lesson plan to your region"
              />
              <TextField
                required
                label="Subject"
                placeholder="Ex: Business Studies"
                errors={errors.subject?.message}
                {...register("subject", {
                  required: { value: true, message: "Required" },
                })}
              />
              <TextField
                {...register("curriculum", {
                  required: { value: true, message: "Required" },
                })}
                required
                label="Curriculum"
                placeholder="Ex: CBSE"
                errors={errors.curriculum?.message}
              />
              <TextField
                {...register("book")}
                label="Book Name (Optional)"
                placeholder="Ex: NCERT"
                errors={errors.book?.message}
              />
              <Controller
                control={control}
                name="difficultyLevel"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Select
                    required
                    label="Level of Difficulty"
                    errors={errors.difficultyLevel?.message}
                    options={LEVEL_OF_DIFFICULTY}
                    placeholder="Ex: Difficult"
                    value={LEVEL_OF_DIFFICULTY.find(c => c.value === value)}
                    mountOnBody
                    onBlur={onBlur}
                    onChange={val => onChange(val?.value)}
                  />
                )}
                rules={{ required: { value: true, message: "Required" } }}
              />
            </div>

            <TextArea
              required
              rows={3}
              label="topic"
              placeholder="Ex: Modern Business Practices."
              errors={errors.topic?.message}
              {...register("topic", {
                required: { value: true, message: "Required" },
              })}
            />

            <TextArea
              required
              rows={3}
              label="Objective"
              placeholder="Ex: Evaluate students' understanding of modern business practices by analyzing current trends, technologies, and strategies in business management and their impact on organizational success."
              errors={errors.assessmentObjectives?.message}
              {...register("assessmentObjectives", {
                required: { value: true, message: "Required" },
              })}
            />
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <div>
                <Typography variant="strong">Lesson Plan</Typography>
                {selectedLessonPlan && (
                  <Typography variant="body">
                    {selectedLessonPlan.title}
                  </Typography>
                )}
              </div>
              <Button
                variant="text"
                onClick={() => {
                  setOpenSidebar(true)
                }}
                startAdornment={selectedLessonPlan ? <Edit /> : undefined}
              >
                {selectedLessonPlan ? "Edit" : "Select Lesson Plan"}
              </Button>
            </div>
            {showErrors?.selectedLessonPlan && (
              <ErrorMessage title={showErrors?.selectedLessonPlan} />
            )}
            <SelectLessonPlanSheet
              open={openSidebar}
              onOpenChange={() => setOpenSidebar(false)}
              onSelect={(subjectiveAssessment, lessonPlanResponseId) => {
                setSelectedLessonPlan(subjectiveAssessment)
                setLessonPlanResponse(lessonPlanResponseId)
                setOpenSidebar(false)
              }}
            />
          </div>
        )}
        <AssessmentStructure
          setStructure={setStructure}
          structure={structure}
          openLessonPlanSelector={openLessonPlanSelector}
        />
        {showErrors?.structure && (
          <ErrorMessage title={showErrors?.structure} />
        )}

        {selectedLessonPlan && (
          <>
            <div className="flex flex-wrap items-center justify-between gap-2">
              <Typography variant="strong">Level of Difficulty</Typography>

              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="text">
                    {difficultyLevel?.label || "Select"}
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {LEVEL_OF_DIFFICULTY.map(item => (
                    <DropdownMenuItem
                      onClick={() => setDifficultyLevel(item)}
                      key={item.value}
                    >
                      {item.label}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {showErrors?.difficultyLevel && (
              <ErrorMessage title={showErrors?.difficultyLevel} />
            )}
          </>
        )}

        <Button
          variant="filled"
          type="submit"
          loading={isSubmitting}
          className="w-full"
        >
          Generate {getToolName(toolType)}
        </Button>
      </form>
    </Paper>
  )
}

export default ProForm
