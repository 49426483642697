import { Button, TextArea, TextField } from "@suraasa/placebo-ui"
import { ActivityPlannerForm, ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { getToolName } from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useForm } from "react-hook-form"

import { ActivityPlannerReturnType } from "../useActivityPlanner"

const toolType = ToolType.activityPlanner

const ProForm = ({
  generateTool,
}: Pick<ActivityPlannerReturnType, "generateTool">) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<ActivityPlannerForm>({
    defaultValues: {
      curriculum: "CBSE",
      duration: "30",
      grade: "10th",
      subject: "Physics",
      topic: "Flemings left hand rule",
    },
  })

  const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!isActive) {
      showGetPlusSheet()
      return
    }

    await generateTool.mutateAsync({
      data: { ...data, duration: `00:${data.duration}:00` },
      setError,
    })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="grid grid-cols-1 gap-2 sm:grid-cols-2">
          <TextField
            label="Class/Grade"
            required
            placeholder="Ex: 6th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            label="Curriculum"
            required
            placeholder="Ex: IB"
            errors={errors.curriculum?.message}
          />

          <TextField
            label="Subject"
            required
            placeholder="Ex: Maths"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            label="Duration (in minutes)"
            required
            placeholder="Ex: 30"
            errors={errors.duration?.message}
            {...register("duration", {
              required: {
                value: true,
                message: "Required",
              },
              pattern: {
                value: /^\d+$/,
                message: "Only numbers are allowed",
              },
            })}
          />
        </div>
        <TextArea
          rows={4}
          label="Objective/Description"
          required
          placeholder="Ex: Apply the Pythagorean Theorem to solve problems involving right-angled triangles, including finding missing side lengths, and demonstrate understanding through hands-on activities and real-world examples."
          errors={errors.topic?.message}
          {...register("topic", {
            required: { value: true, message: "Required" },
          })}
        />
        <Button type="submit" loading={isSubmitting}>
          Generate {getToolName(toolType)}
        </Button>
      </form>

      {/* <AiToolsLoading type={toolType} open={isLoading} /> */}
    </Paper>
  )
}

export default ProForm
