import { TagColors } from "@suraasa/placebo-ui-legacy"
import { AttemptResult, AttemptStatus } from "api/resources/assessments/types"

export function getTagStyle({
  result,
  status,
  startTime,
}: {
  status: AttemptStatus | null | undefined
  result?: AttemptResult | null
  startTime: string
  endTime?: string
}): {
  label:
    | "Passed"
    | "Failed"
    | "Completed"
    | "Missed"
    | "Continue"
    | "Ongoing"
    | "Up Next"
  color: TagColors
} {
  const now = new Date()

  if (status === AttemptStatus.MISSED) {
    return { label: "Missed", color: "critical" }
  }
  if (result === AttemptResult.PASS) {
    return { label: "Passed", color: "success" }
  }
  if (result === AttemptResult.FAIL) {
    return { label: "Failed", color: "critical" }
  }
  if (status === AttemptStatus.COMPLETED) {
    return { label: "Completed", color: "primary" }
  }

  if (status === AttemptStatus.IN_PROGRESS) {
    return { label: "Continue", color: "primary" }
  }

  if (now > new Date(startTime)) {
    return { label: "Ongoing", color: "primary" }
  }

  return { label: "Up Next", color: "primary" }
}
