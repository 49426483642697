import { getToolName } from "features/AItools/helper"

import Heading from "../../Heading"
import ActivityPlannerOutput from "../GenerationOutput/ActivityPlannerOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import ProForm from "./ProForm"
import { useActivityPlanner } from "./useActivityPlanner"

const ActivityPlannerGenerator = () => {
  const {
    currentResponseId,
    setCurrentResponseId,
    responseIds,
    overviewData,
    generateTool,
    type,
    regenerateResponse,
    userVote,
    isPositiveResponse,
    output,
    isLoading,
    onBack,
  } = useActivityPlanner()

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={type}
          title={overviewData?.title}
        />

        <ActivityPlannerOutput
          regenerateResponse={regenerateResponse}
          isPositiveResponse={isPositiveResponse}
          userVote={userVote}
          overviewData={overviewData}
          responseIds={responseIds}
          currentResponseId={currentResponseId}
          isLoading={isLoading}
          output={output}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }
  return (
    <>
      <Heading
        title={`${getToolName(type)} Generator`}
        subtitle="Elevate your teaching approach with the Activity Plan Generator, fostering interactive and impactful learning experiences."
        isPro
      />
      <ProForm generateTool={generateTool} />
    </>
  )
}

export default ActivityPlannerGenerator
