import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import InputDisplay from "../../helpers/InputDisplay"
import { SubjectiveAssessmentReturnType } from "../../SubjectiveAssessmentGenerator/useSubjectiveAssessment"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"
import styles from "./SubjectiveAssessmentContent/SubjectiveAssessmentContent.module.css"

const SubjectiveAssessmentOutput = (
  props: Pick<
    SubjectiveAssessmentReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props
  return (
    <div className="mb-4 flex flex-col gap-3">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            totalMarks: overviewData.totalMarks,
            totalQuestions: overviewData.totalQuestions,
            grade: overviewData.grade,
            subject: overviewData.subject,
            curriculum: overviewData.curriculum,
            topic: overviewData.topic,
            country: overviewData.country,
            isPro: overviewData.isPro,
            responseIds: overviewData.responseIds || [],
            title: overviewData.title || "",
            book: overviewData.book,
            difficultyLevel: overviewData.difficultyLevel,
            //To handle this
            unitDivisionResponses: [],
            classDuration: "",
          }}
        />
      )}

      <Paper
        className="p-0"
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div className={clsx("overflow-x-auto", styles.tableContainer)}>
          <ToolOutput onClick={onBack} isLoading={isLoading}>
            {output}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default SubjectiveAssessmentOutput
