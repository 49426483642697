import { CircularProgress, cn, Typography } from "@suraasa/placebo-ui"
import { Tabs } from "components/TabBar/hooks/useTabs"
import { Notes, Page, SoundLow } from "iconoir-react"
import React from "react"

export enum TabNames {
  lessonPlan = "Lesson Plan",
  handout = "Handout",
  narration = "Narration",
}

const getIcon = (tabName: string) => {
  const svgProps = {
    width: "20px",
    height: "20px",
  }
  switch (tabName) {
    case TabNames.lessonPlan:
      return <Page {...svgProps} />
    case TabNames.handout:
      return <Notes {...svgProps} />
    case TabNames.narration:
      return <SoundLow {...svgProps} />
    default:
      return null
  }
}
const getIconColor = (tabName?: string): { base: string; active: string } => {
  switch (tabName) {
    case TabNames.lessonPlan:
      return {
        base: "bg-decorative-one-50 text-decorative-one-100",
        active: "bg-decorative-one-100 text-decorative-one-50",
      }
    case TabNames.handout:
      return {
        base: "bg-interactive-50 text-interactive-500",
        active: "bg-interactive-500 text-interactive-50",
      }
    case TabNames.narration:
      return {
        base: "bg-success-50 text-success-500",
        active: "bg-success-500 text-success-50",
      }
    default:
      return {
        base: "bg-onSurface-100 text-onSurface-500",
        active: "bg-onSurface-400 text-onSurface-100",
      }
  }
}

const LessonPlanTabs = ({
  tabs,
  activeTabName,
  onClick,
  className,
  disable,
  isHandoutLoading,
  isNarrationLoading,
}: {
  tabs: Tabs
  activeTabName: string
  onClick: (name: string) => void
  className?: string
  disable?: string[]
  isHandoutLoading?: boolean
  isNarrationLoading?: boolean
}) => {
  return (
    <div
      className={cn(
        "mx-3 mb-1 flex flex-nowrap gap-3 border-b-2 border-solid border-onSurface-200 pb-3",
        className
      )}
    >
      {tabs.map(i => {
        const isDisabled = disable?.includes(i.name)
        const isLoadingBtn =
          (i.name === TabNames.handout && isHandoutLoading) ||
          (i.name === TabNames.narration && isNarrationLoading)
        return (
          <button
            key={i.name}
            className={cn("flex items-center gap-0.5", {
              "text-onSurface-500": isDisabled,
            })}
            onClick={() => {
              onClick(i.name)
            }}
            disabled={isDisabled || isLoadingBtn}
          >
            <div
              className={cn(
                "grid size-4 place-items-center rounded-lg",
                getIconColor(i.name).base,
                {
                  [getIconColor(i.name).active]: i.name === activeTabName,
                  [getIconColor().base]: i.name !== activeTabName && isDisabled,
                  [getIconColor().active]:
                    i.name !== activeTabName && isDisabled,
                }
              )}
            >
              {getIcon(i.name)}
            </div>
            <Typography
              variant={i.name === activeTabName ? "strong" : "body"}
              className={cn("border-solid border-onSurface-900", {
                "border-b": i.name === activeTabName,
              })}
            >
              {i.name}
            </Typography>
            {isLoadingBtn && <CircularProgress size="xs" />}
          </button>
        )
      })}
    </div>
  )
}

export default LessonPlanTabs
