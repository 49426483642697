export enum SubscriptionStatus {
  active = "active",
  cancelled = "cancelled",
  paymentOverdue = "payment_overdue",
  expired = "expired",
}

export type Subscription = {
  status: SubscriptionStatus
  validThrough: string
}
