import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"

import Heading from "../../Heading"
import TogglePro from "../../TogglePro"
import AssignmentOutput from "../GenerationOutput/AssignmentOutput"
import ContentActions from "../GenerationOutput/Export/ContentActions"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"
import { useAssignment } from "./useAssignment"

const AssignmentGenerator = () => {
  const [enableProMode, setEnableProMode] = usePlusFeature({
    ensurePlusAccess: false,
  })
  const {
    currentResponseId,
    setCurrentResponseId,
    promptDataId,
    pageLoading,
    onBack,
    ...assignmentContext
  } = useAssignment()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }
  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.assignment}
          title={assignmentContext.overviewData?.title}
        />

        <AssignmentOutput
          {...assignmentContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.assignment)} Generator`}
        subtitle="A tool for creating assignments based on your preferences and specifications."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm {...assignmentContext} />
      ) : (
        <BasicForm {...assignmentContext} />
      )}
    </>
  )
}

export default AssignmentGenerator
