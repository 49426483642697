import { Typography } from "@suraasa/placebo-ui"
import {
  CircularProgress,
  Container,
  Divider,
  IconButton,
  Theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import api from "api"
import {
  ContactDetails as ContactDetailsType,
  Email,
  SocialAccount,
  UserPreference,
} from "api/resources/settings/types"
import { BlockedUser, PhoneNumber } from "api/resources/users/types"
import { APIError } from "api/utils"
import TopHeadingBar from "components/TopHeadingBar"
import LearningItemNav from "features/LearningItems/Nav"
import { context } from "global/Context/context"
import { NavArrowRight } from "iconoir-react"
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react"
import { useTheme } from "react-jss"
import {
  NavLink,
  Outlet,
  useLocation,
  useOutletContext,
} from "react-router-dom"
import routes from "routes"

type ContextType = {
  userPreferences: UserPreference[]
  updateUserPreference: (slug: string, value: string) => void
  privacyDetails: BlockedUser[]
  setPrivacyDetails: Dispatch<SetStateAction<BlockedUser[]>>
  contactDetails: ContactDetailsType
  updateContactDetails: (details: ContactDetailsType) => void
  socialAccounts: SocialAccount[]
  setSocialAccounts: Dispatch<SetStateAction<SocialAccount[]>>
}

type NavData = { name: string; link: string }

const navData: NavData[] = [
  { name: "Account Details", link: routes.accountDetails },
  { name: "Privacy", link: routes.privacy },
  { name: "Notification Settings", link: routes.notificationSettings },
]

const LearningItemNavMobile = ({ name, link }: NavData) => (
  <NavLink to={link}>
    <div className="flex items-center justify-between px-1 py-2 hover:bg-primary-50">
      <Typography variant="subtitle2">{name}</Typography>
      <IconButton color="black">
        <NavArrowRight />
      </IconButton>
    </div>
  </NavLink>
)

const Settings = () => {
  const theme = useTheme<Theme>()
  const { pathname } = useLocation()
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))
  const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  const { emails } = useContext(context)

  const [userPreferences, setUserPreferences] = useState<UserPreference[]>([])
  const [privacyDetails, setPrivacyDetails] = useState<BlockedUser[]>([])

  const [phoneNumber, setPhoneNumber] = useState<PhoneNumber | null>(null)
  const [accountEmails, setAccountEmails] = useState<Email[]>([])

  const [socialAccounts, setSocialAccounts] = useState<SocialAccount[]>([])

  const contactDetails: ContactDetailsType = {
    phoneNumber,
    emails: accountEmails,
  }

  const [loading, setLoading] = useState(false)

  const isSettingsHomePage = pathname === routes.settings

  const updateUserPreference = (slug: string, value: string) => {
    setUserPreferences(prevState =>
      prevState.map(e => {
        if (e.setting.slug === slug) {
          e.value = value
        }
        return e
      })
    )
  }

  const orderEmails = (emails: Email[]) =>
    emails.sort((a, b) =>
      a.isPrimary === b.isPrimary ? 0 : a.isPrimary ? -1 : 1
    )

  const updateContactDetails = (details: ContactDetailsType) => {
    setPhoneNumber(details.phoneNumber)
    setAccountEmails(orderEmails(details.emails))
  }

  useEffect(() => {
    if (emails.length > 0) {
      setAccountEmails(orderEmails(emails))
    }
  }, [emails])

  useEffect(() => {
    async function getData() {
      setLoading(true)
      try {
        const [profileRes, preferencesRes, privacyRes, socialAccountRes] =
          await Promise.all([
            await api.users.getUser(),
            await api.settings.getUserPreferences(),
            await api.users.block.list(),
            await api.settings.socialAuth.list(),
          ])

        setPhoneNumber(
          profileRes.phoneNumber
            ? {
                number: `${profileRes.phoneNumber?.number}`,
                code: `${profileRes.phoneNumber?.code}`,
              }
            : null
        )
        setUserPreferences(preferencesRes)
        setPrivacyDetails(privacyRes)
        setSocialAccounts(socialAccountRes)
        setLoading(false)
      } catch (err) {
        if (err instanceof APIError) console.error(err)
      }
    }
    getData()
  }, [])

  const getOutlet = () => (
    <>
      {loading ? (
        <div className="flex items-center justify-center pt-5">
          <CircularProgress />
        </div>
      ) : (
        <Outlet
          context={{
            userPreferences,
            updateUserPreference,
            privacyDetails,
            setPrivacyDetails,
            contactDetails,
            updateContactDetails,
            socialAccounts,
            setSocialAccounts,
          }}
        />
      )}
    </>
  )

  const getMobileSettings = () => (
    <Container className="mt-3">
      {!isSettingsHomePage ? (
        <div>{getOutlet()}</div>
      ) : (
        navData.map((nav, idx) => (
          <div key={idx}>
            <LearningItemNavMobile {...nav} />
            {idx !== navData.length - 1 && (
              <Divider weight="light" color="onSurface.200" />
            )}
          </div>
        ))
      )}
    </Container>
  )

  const getHeadingBar = () => {
    // In Mobile view all different tabs have distinct headers
    if (!isSettingsHomePage && isXs) return null
    return (
      <>
        {/* For tablet View and Full Screen View*/}
        {isSmDown ? (
          <Container fullWidth={isXs}>
            <div className="mb-3 mt-4 flex flex-col px-2 sm:px-0">
              <Typography variant="title2">Settings</Typography>
              <Typography className="mt-0.5">
                Manage your account settings and preferences
              </Typography>
            </div>
            <Divider weight="light" color="onSurface.200" />
          </Container>
        ) : (
          <TopHeadingBar
            subheading="Manage your account settings and preferences"
            heading="Settings"
          />
        )}
      </>
    )
  }

  return (
    <div>
      {getHeadingBar()}

      {isXs ? (
        <div>{getMobileSettings()}</div>
      ) : (
        <Container className="mt-3.5">
          <div className="grid grid-cols-12">
            <div className="col-span-3 me-3 flex flex-col">
              <LearningItemNav data={navData} />
            </div>
            <div className="col-span-9">{getOutlet()}</div>
          </div>
        </Container>
      )}
    </div>
  )
}

export default Settings

export function useSettings() {
  return useOutletContext<ContextType>()
}
