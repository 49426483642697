import {
  Button,
  IconButton,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import {
  NavArrowDown,
  Plus,
  WarningCircleSolid,
  XmarkCircle,
} from "iconoir-react"
import { useEffect, useState } from "react"
import { useFieldArray, useForm, useWatch } from "react-hook-form"
import { pluralize } from "utils/helpers"

export type AssessmentStructureRow = {
  marks: number | undefined
  numberOfQuestions: number | undefined
}

const MAX_QUESTIONS = 15

const Structure = ({
  onUpdate = () => {},
  structure,
  openLessonPlanSelector,
}: {
  onUpdate: (r: AssessmentStructureRow[]) => void
  structure: AssessmentStructureRow[] | null
  openLessonPlanSelector?: boolean
}) => {
  const {
    control,
    register,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      rows: structure,
    },
  })
  const { fields, append, remove, insert } = useFieldArray({
    control,
    name: "rows",
  })
  const result = useWatch({
    name: "rows",
    control,
  })

  useEffect(() => {
    if (!structure) {
      insert(0, {
        marks: undefined,
        numberOfQuestions: undefined,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (result) {
      onUpdate(
        result
          .filter(
            (r: AssessmentStructureRow) => !!r.marks && !!r.numberOfQuestions
          )
          .map((r: AssessmentStructureRow) => ({
            marks: r.marks || 0,
            numberOfQuestions: r.numberOfQuestions || 0,
          }))
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result])

  const totalQuestion =
    (result &&
      result.length > 0 &&
      result
        .filter(
          (r: AssessmentStructureRow) => !!r.marks && !!r.numberOfQuestions
        )
        .map((r: AssessmentStructureRow) =>
          r.marks && r.marks > 0 ? r.numberOfQuestions || 0 : 0
        )
        .reduce((a, b) => a + b, 0)) ||
    0

  const totalMarks =
    (result &&
      result.length > 0 &&
      result
        .filter(
          (r: AssessmentStructureRow) => !!r.marks && !!r.numberOfQuestions
        )
        .map(
          (r: AssessmentStructureRow) =>
            (r.marks || 0) * (r.numberOfQuestions || 0)
        )
        .reduce((a, b) => a + b, 0)) ||
    0

  return (
    <div>
      <div className="mt-1.5 flex gap-2 rounded-lg border border-highlight-200 bg-highlight-50 p-2">
        <div>
          <Typography variant="smallBody">Total Questions</Typography>
          <Typography variant="title3"> {totalQuestion || "-"} </Typography>
        </div>
        <div>
          <Typography variant="smallBody">Total Marks</Typography>
          <Typography variant="title3"> {totalMarks || "-"} </Typography>
        </div>
      </div>

      <div>
        <div className="mt-2 flex gap-5">
          <Typography variant="strong" className="w-[110px]">
            Marks
          </Typography>
          <Typography variant="strong">No. of Questions</Typography>
        </div>
        <div>
          {fields.map((r, i) => (
            <div
              key={r.id}
              className="flex items-center justify-between border-b border-onSurface-200 py-2"
            >
              <div className="flex items-start gap-5">
                <div className="w-[110px]">
                  <TextField
                    placeholder={openLessonPlanSelector ? "Ex: 2" : "Ex: 4"}
                    key={r.id}
                    defaultValue={undefined}
                    type="number"
                    className="[&_input]:h-4 [&_input]:min-h-0"
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                      }
                    }}
                    errors={errors.rows?.[i]?.marks?.message}
                    {...register(`rows.${i}.marks` as const, {
                      required: {
                        value: true,
                        message: "Required",
                      },
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: "Cannot be less than 1",
                      },
                    })}
                  />
                </div>
                <div className="w-[170px]">
                  <TextField
                    placeholder={openLessonPlanSelector ? "Ex: 8" : "Ex: 5"}
                    defaultValue={undefined}
                    type="number"
                    className="[&_input]:h-4 [&_input]:min-h-0"
                    errors={errors.rows?.[i]?.numberOfQuestions?.message}
                    onKeyDown={e => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                      }
                    }}
                    {...register(`rows.${i}.numberOfQuestions` as const, {
                      required: { value: true, message: "Required" },
                      valueAsNumber: true,
                      min: {
                        value: 1,
                        message: "Cannot be less than 1",
                      },
                    })}
                  />
                </div>
              </div>
              <div>
                <IconButton
                  onClick={e => {
                    e.preventDefault()
                    remove(i)
                  }}
                >
                  <XmarkCircle className="text-critical-500" />
                </IconButton>
              </div>
            </div>
          ))}
          {totalQuestion > MAX_QUESTIONS && (
            <div className="mt-2">
              <Typography variant="button" className="flex text-critical-500">
                <div className="flex items-center gap-1">
                  <WarningCircleSolid />
                  You can&apos;t generate more than{" "}
                  {pluralize("question", MAX_QUESTIONS)}.
                </div>
              </Typography>
            </div>
          )}
          <div>
            <Button
              className="mt-1"
              variant="text"
              onClick={() => {
                if (totalQuestion < MAX_QUESTIONS) {
                  append({
                    marks: undefined,
                    numberOfQuestions: undefined,
                  })
                } else {
                  toast.error(
                    `You can't generate more than ${pluralize(
                      "question",
                      MAX_QUESTIONS
                    )}.`
                  )
                }
              }}
              startAdornment={<Plus />}
            >
              Add Row
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

type Props = {
  setStructure: (r: AssessmentStructureRow[]) => void
  structure: AssessmentStructureRow[] | null

  openLessonPlanSelector?: boolean
}

const AssessmentStructure = ({
  structure,
  setStructure,
  openLessonPlanSelector,
}: Props) => {
  const [showCreateStructure, setShowCreateStructure] = useState(false)

  const hasValidStructure =
    structure && structure.some(r => r.marks && r.numberOfQuestions)
  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <Typography variant="strong">Assessment Structure</Typography>
          {hasValidStructure && (
            <Typography variant="body" className="text-onSurface-800">
              {structure
                .map(r => `${r.numberOfQuestions}x${r.marks} Marks`)
                .join(", ")}
            </Typography>
          )}
        </div>
        <Button
          variant="text"
          onClick={() => setShowCreateStructure(show => !show)}
        >
          {hasValidStructure ? <NavArrowDown /> : "Create Structure"}
        </Button>
      </div>
      {showCreateStructure && (
        <Structure
          onUpdate={result => setStructure(result)}
          structure={structure}
          openLessonPlanSelector={openLessonPlanSelector}
        />
      )}
    </div>
  )
}

export default AssessmentStructure
