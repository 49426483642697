import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import { Batch, ReattemptData } from "./types"
import { urls } from "./urls"

export default {
  listBatches: async (): Promise<APIResponse<Batch[]>> => {
    try {
      const res = await axios.get(urls.listBatches())
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  allocateReattemptRequest: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.allocateReattemptRequest(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  createReattemptRequest: async ({
    data,
  }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.createReattemptRequest(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  retrieveReattemptData: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<ReattemptData>> => {
    try {
      const res = await axios.get(
        urls.retrieveReattemptData(urlParams.userPlannerItemId),
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
