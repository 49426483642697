import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
  PaginatedAPIResponse,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  ActivityPlannerPromptData,
  ActivityPlannerResponse,
  AIToolPrompts,
  AssignmentGenerateResponse,
  AssignmentGeneratorType,
  AssignmentProGenerateResponse,
  AssignmentPromptData,
  AssignmentProResponse,
  AssignmentResponse,
  CoverLetterHistory,
  HandoutGenerateResponse,
  HandoutGeneratorType,
  HandoutPromptData,
  HandoutResponse,
  LessonPlanPromptData,
  LessonPlanProResponse,
  LessonPlanResponse,
  ListPromptHistory,
  NarrationGenerateResponse,
  NarrationGeneratorType,
  NarrationPromptData,
  NarrationResponse,
  QuizGenerateProResponse,
  QuizGenerateResponse,
  QuizPromptData,
  QuizProResponse,
  QuizResponse,
  ResumeFillerType,
  RetrieveCoverLetter,
  RetrieveLessonPlan,
  RetrievePrompt,
  RubricsGenerateProResponse,
  RubricsGenerateResponse,
  RubricsPromptData,
  RubricsProResponse,
  RubricsResponse,
  SubjectiveAssessmentGenerateProResponse,
  SubjectiveAssessmentGenerateResponse,
  SubjectiveAssessmentPromptData,
  SubjectiveAssessmentProResponse,
  SubjectiveAssessmentResponse,
  ToolType,
  UnitPlanDivisionResponse,
  UnitPlanGenerateResponse,
  UnitPlanPromptData,
  UnitPlanPromptResponse,
  UnitPlanResponse,
  VideoDurationType,
  VideoSummaryGenerateResponse,
  VideoSummaryPromptData,
  VideoSummaryResponse,
} from "./types"
import { urls } from "./urls"

export default {
  generateLessonPlan: async ({
    data,
  }: Config): Promise<APIResponse<LessonPlanResponse>> => {
    try {
      const res = await axios.post(urls.lessonPlan.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateLessonPlanPro: async ({
    data,
  }: Config): Promise<APIResponse<LessonPlanProResponse>> => {
    try {
      const res = await axios.post(urls.lessonPlan.pro.generate(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateRubrics: async ({
    data,
  }: Config): Promise<APIResponse<RubricsResponse>> => {
    try {
      const res = await axios.post(urls.rubrics.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateRubricsPro: async ({
    data,
  }: Config): Promise<APIResponse<RubricsProResponse>> => {
    try {
      const res = await axios.post(urls.rubrics.generatePro(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateQuiz: async ({
    data,
  }: Config): Promise<APIResponse<QuizResponse>> => {
    try {
      const res = await axios.post(urls.quiz.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateQuizPro: async ({
    data,
  }: Config): Promise<APIResponse<QuizProResponse>> => {
    try {
      const res = await axios.post(urls.quiz.generatePro(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateHandout: async ({
    data,
  }: Config): Promise<APIResponse<HandoutGeneratorType>> => {
    try {
      const res = await axios.post(urls.handout.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateNarration: async ({
    data,
  }: Config): Promise<APIResponse<NarrationGeneratorType>> => {
    try {
      const res = await axios.post(urls.narration.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateSubjectiveAssessment: async ({
    data,
  }: Config): Promise<APIResponse<SubjectiveAssessmentResponse>> => {
    try {
      const res = await axios.post(urls.subjectiveAssessment.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateSubjectiveAssessmentPro: async ({
    data,
  }: Config): Promise<APIResponse<SubjectiveAssessmentProResponse>> => {
    try {
      const res = await axios.post(
        urls.subjectiveAssessment.generatePro(),
        data
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateAssignment: async ({
    data,
  }: Config): Promise<APIResponse<AssignmentGeneratorType>> => {
    try {
      const res = await axios.post(urls.assignment.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateAssignmentPro: async ({
    data,
  }: Config): Promise<APIResponse<AssignmentProResponse>> => {
    try {
      const res = await axios.post(urls.assignment.generatePro(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  generateVideoSummary: async ({
    data,
  }: Config): Promise<APIResponse<VideoSummaryResponse>> => {
    try {
      const res = await axios.post(urls.videoSummary.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateActivityPlanner: async ({
    data,
  }: Config): Promise<APIResponse<ActivityPlannerResponse>> => {
    try {
      const res = await axios.post(urls.activityPlanner.generate(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveVideoDuration: async ({
    data,
  }: Config): Promise<APIResponse<VideoDurationType>> => {
    try {
      const res = await axios.post(
        urls.videoSummary.retrieveVideoDuration(),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateUnitPlanPrompt: async ({
    data,
  }: Config): Promise<APIResponse<UnitPlanGenerateResponse>> => {
    try {
      const res = await axios.post(
        urls.unitPlanner.generateUnitPlanPrompt(),
        data
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateUnitPlan: async ({
    data,
  }: Config): Promise<APIResponse<UnitPlanGenerateResponse>> => {
    try {
      const res = await axios.post(urls.unitPlanner.generateUnitPlan(), data)

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUnitDivisionData: async ({
    urlParams: { id },
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(
        urls.unitPlanner.updateUnitDivisionData(id),
        data
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateUnitDivision: async ({
    data,
  }: Config): Promise<APIResponse<UnitPlanGenerateResponse>> => {
    try {
      const res = await axios.post(
        urls.unitPlanner.generateUnitDivision(),
        data
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveUnitDivisionResponse: async ({
    urlParams: { id },
  }: ConfigWithURLParams): Promise<APIResponse<UnitPlanDivisionResponse>> => {
    try {
      const res = await axios.get(
        urls.unitPlanner.retrieveUnitDivisionResponse(id)
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveUnitPlanResponse: async ({
    urlParams: { id },
  }: ConfigWithURLParams): Promise<APIResponse<UnitPlanResponse>> => {
    try {
      const res = await axios.get(urls.unitPlanner.retrieveUnitPlanResponse(id))

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveUnitPlanStructure: async ({
    data,
  }: Config): Promise<APIResponse<UnitPlanResponse>> => {
    try {
      const res = await axios.post(
        urls.unitPlanner.retrieveUnitPlanStructure(),
        data
      )

      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  regenerateUnitPlanDivision: async ({
    data,
    urlParams: { id },
  }: ConfigWithURLParams): Promise<
    APIResponse<RetrievePrompt<ToolType.unitPlanner>>
  > => {
    try {
      const res = await axios.post(
        urls.unitPlanner.regenerateUnitPlanDivision(id),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateReactionUnitPlanDivision: async ({
    data,
    urlParams: { id },
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(
        urls.unitPlanner.updateReactionUnitPlanDivision(id),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listHistory: async ({
    type,
    params,
  }: {
    type: ToolType | null | undefined
  } & Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
    const url: string = (() => {
      switch (type) {
        case ToolType.lessonPlanPro:
        case ToolType.lessonPlan:
          return urls.lessonPlan.list()
        case ToolType.rubricsPro:
        case ToolType.rubrics:
          return urls.rubrics.list()
        case ToolType.quizPro:
        case ToolType.quiz:
          return urls.quiz.list()
        case ToolType.handout:
          return urls.handout.list()
        case ToolType.narration:
          return urls.narration.list()
        case ToolType.subjectiveAssessment:
        case ToolType.subjectiveAssessmentPro:
          return urls.subjectiveAssessment.list()
        case ToolType.assignment:
        case ToolType.assignmentPro:
          return urls.assignment.list()
        case ToolType.activityPlanner:
          return urls.activityPlanner.list()
        case ToolType.videoSummary:
          return urls.videoSummary.list()
        case ToolType.unitPlanner:
          return urls.unitPlanner.list()
        case ToolType.coverLetter:
          throw Error(
            `This function listHistory should not be called with tool type cover letter`
          )
        case null:
        case undefined:
          return urls.history()
      }
    })()

    try {
      const res = await axios.get(url, { params })
      return formatSuccessResponse(res, { paginatedResponse: true })
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },

  deleteHistory: async ({
    urlParams: { id, type },
  }: ConfigWithURLParams): Promise<NoContentType> => {
    try {
      const res = await axios.delete(urls.retrieve(id, type))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  getPromptData: async ({
    urlParams: { id, type },
  }: ConfigWithURLParams): Promise<AIToolPrompts> => {
    try {
      const url: string = (() => {
        switch (type) {
          case ToolType.lessonPlan:
          case ToolType.lessonPlanPro:
            return urls.lessonPlan.getPromptData(id)
          case ToolType.rubrics:
            return urls.rubrics.getPromptData(id)
          case ToolType.quiz:
            return urls.quiz.getPromptData(id)
          case ToolType.handout:
            return urls.handout.getPromptData(id)
          case ToolType.narration:
            return urls.narration.getPromptData(id)
          case ToolType.subjectiveAssessment:
            return urls.subjectiveAssessment.getPromptData(id)
          case ToolType.assignment:
            return urls.assignment.getPromptData(id)
          case ToolType.activityPlanner:
            return urls.activityPlanner.getPromptData(id)
          case ToolType.videoSummary:
            return urls.videoSummary.getPromptData(id)
          case ToolType.unitPlanner:
            return urls.unitPlanner.getPromptData(id)
          default:
            throw new Error("Invalid tool type")
        }
      })()
      const res = await axios.get(url)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUserReaction: async ({
    urlParams: { id, type },
    data,
  }: ConfigWithURLParams): Promise<NoContentType> => {
    try {
      const url: string = (() => {
        switch (type) {
          case ToolType.lessonPlan:
            return urls.lessonPlan.updateUserReaction(id)
          case ToolType.rubrics:
            return urls.rubrics.updateUserReaction(id)
          case ToolType.quiz:
            return urls.quiz.updateUserReaction(id)
          case ToolType.handout:
            return urls.handout.updateUserReaction(id)
          case ToolType.narration:
            return urls.narration.updateUserReaction(id)
          case ToolType.subjectiveAssessment:
            return urls.subjectiveAssessment.updateUserReaction(id)
          case ToolType.assignment:
            return urls.assignment.updateUserReaction(id)
          case ToolType.activityPlanner:
            return urls.activityPlanner.updateUserReaction(id)
          case ToolType.videoSummary:
            return urls.videoSummary.updateUserReaction(id)
          case ToolType.unitPlanner:
            return urls.unitPlanner.updateUserReaction(id)
          default:
            throw new Error("Invalid tool type")
        }
      })()
      const res = await axios.post(url, data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  retrieveContent: async <T>({
    urlParams: { id, type },
  }: ConfigWithURLParams): Promise<APIResponse<RetrievePrompt<T>>> => {
    try {
      const url: string = (() => {
        switch (type) {
          case ToolType.lessonPlan:
          case ToolType.lessonPlanPro:
            return urls.lessonPlan.retrieve(id)
          case ToolType.rubrics:
            return urls.rubrics.retrieve(id)
          case ToolType.quiz:
            return urls.quiz.retrieve(id)
          case ToolType.handout:
            return urls.handout.retrieve(id)
          case ToolType.narration:
            return urls.narration.retrieve(id)
          case ToolType.subjectiveAssessment:
            return urls.subjectiveAssessment.retrieve(id)
          case ToolType.assignment:
            return urls.assignment.retrieve(id)
          case ToolType.activityPlanner:
            return urls.activityPlanner.retrieve(id)
          case ToolType.videoSummary:
            return urls.videoSummary.retrieve(id)
          case ToolType.unitPlanner:
            return urls.unitPlanner.retrieve(id)
          default:
            throw new Error("Invalid tool type")
        }
      })()
      const res = await axios.get(url)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  regenerateResponse: async <T>({
    data,
    urlParams: { id, type },
  }: ConfigWithURLParams): Promise<APIResponse<RetrievePrompt<T>>> => {
    try {
      const url: string = (() => {
        switch (type) {
          case ToolType.lessonPlan:
            return urls.lessonPlan.regenerateResponse(id)
          case ToolType.rubrics:
            return urls.rubrics.regenerateResponse(id)
          case ToolType.quiz:
            return urls.quiz.regenerateResponse(id)
          case ToolType.handout:
            return urls.handout.regenerateResponse(id)
          case ToolType.narration:
            return urls.narration.regenerateResponse(id)
          case ToolType.subjectiveAssessment:
            return urls.subjectiveAssessment.regenerateResponse(id)
          case ToolType.assignment:
            return urls.assignment.regenerateResponse(id)
          case ToolType.activityPlanner:
            return urls.activityPlanner.regenerateResponse(id)
          case ToolType.videoSummary:
            return urls.videoSummary.regenerateResponse(id)
          case ToolType.unitPlanner:
            return urls.unitPlanner.regenerateResponse(id)
          default:
            throw new Error("Invalid tool type")
        }
      })()

      const res = await axios.post(url, data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  // regenerateResponse2: async ({
  //   data,
  //   urlParams: { id, type },
  // }: ConfigWithURLParams): Promise<
  //   APIResponse<{
  //     id: number
  //   }>
  // > => {
  //   try {
  //     const url: string = (() => {
  //       switch (type) {
  //         case ToolType.lessonPlan:
  //           return urls.lessonPlan.regenerateResponse(id)
  //         case ToolType.rubrics:
  //           return urls.rubrics.regenerateResponse(id)
  //         case ToolType.quiz:
  //           return urls.quiz.regenerateResponse(id)
  //         case ToolType.handout:
  //           return urls.handout.regenerateResponse(id)
  //         case ToolType.narration:
  //           return urls.narration.regenerateResponse(id)
  //         case ToolType.subjectiveAssessment:
  //           return urls.subjectiveAssessment.regenerateResponse(id)
  //         case ToolType.assignment:
  //           return urls.assignment.regenerateResponse(id)
  //         case ToolType.activityPlanner:
  //           return urls.activityPlanner.regenerateResponse(id)
  //         case ToolType.videoSummary:
  //           return urls.videoSummary.regenerateResponse(id)
  //         case ToolType.unitPlanner:
  //           return urls.unitPlanner.regenerateResponse(id)
  //         default:
  //           throw new Error("Invalid tool type")
  //       }
  //     })()

  //     const res = await axios.post(url, data)
  //     return formatSuccessResponse(res)
  //   } catch (e) {
  //     return formatErrorResponse(e)
  //   }
  // },
  updateData: async ({
    data,
    urlParams: { id, type },
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const url: string = (() => {
        switch (type) {
          case ToolType.lessonPlan:
            return urls.lessonPlan.updateData(id)
          case ToolType.rubrics:
            return urls.rubrics.updateData(id)
          case ToolType.quiz:
            return urls.quiz.updateData(id)
          case ToolType.handout:
            return urls.handout.updateData(id)
          case ToolType.narration:
            return urls.narration.updateData(id)
          case ToolType.subjectiveAssessment:
            return urls.subjectiveAssessment.updateData(id)
          case ToolType.assignment:
            return urls.assignment.updateData(id)
          case ToolType.activityPlanner:
            return urls.activityPlanner.updateData(id)
          case ToolType.videoSummary:
            return urls.videoSummary.updateData(id)
          case ToolType.unitPlanner:
            return urls.unitPlanner.updateData(id)
          default:
            throw new Error("Invalid tool type")
        }
      })()

      const res = await axios.post(url, data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  export: async ({
    urlParams: { id, type },
    params,
    responseType,
  }: ConfigWithURLParams): Promise<APIResponse<Blob | { url: string }>> => {
    const url: string = ((type: ToolType) => {
      switch (type) {
        case ToolType.lessonPlan:
        case ToolType.lessonPlanPro:
          return urls.lessonPlan.export(id)
        case ToolType.rubrics:
        case ToolType.rubricsPro:
          return urls.rubrics.export(id)
        case ToolType.quiz:
        case ToolType.quizPro:
          return urls.quiz.export(id)
        case ToolType.handout:
          return urls.handout.export(id)
        case ToolType.narration:
          return urls.narration.export(id)
        case ToolType.subjectiveAssessment:
        case ToolType.subjectiveAssessmentPro:
          return urls.subjectiveAssessment.export(id)
        case ToolType.assignment:
        case ToolType.assignmentPro:
          return urls.assignment.export(id)
        case ToolType.activityPlanner:
          return urls.activityPlanner.export(id)
        case ToolType.videoSummary:
          return urls.videoSummary.export(id)
        case ToolType.unitPlanner:
          return urls.unitPlanner.export(id)
        case ToolType.coverLetter:
          return urls.coverLetter.export(id)
      }
    })(type as ToolType)

    try {
      const res = await axios.get(url, {
        params,
        responseType,
      })
      return formatSuccessResponse(res, { keepOriginalResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  exportPost: async ({
    urlParams: { type },
    responseType,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<Blob | { url: string }>> => {
    const url: string = ((type: ToolType) => {
      switch (type) {
        case ToolType.lessonPlan:
        case ToolType.lessonPlanPro:
          return urls.lessonPlan.exportPost()
        case ToolType.rubrics:
        case ToolType.rubricsPro:
          return urls.rubrics.exportPost()
        case ToolType.quiz:
        case ToolType.quizPro:
          return urls.quiz.exportPost()
        case ToolType.handout:
          return urls.handout.exportPost()
        case ToolType.narration:
          return urls.narration.exportPost()
        case ToolType.subjectiveAssessment:
        case ToolType.subjectiveAssessmentPro:
          return urls.subjectiveAssessment.exportPost()
        case ToolType.assignment:
        case ToolType.assignmentPro:
          return urls.assignment.exportPost()
        case ToolType.activityPlanner:
          return urls.activityPlanner.exportPost()
        case ToolType.videoSummary:
          return urls.videoSummary.exportPost()
        case ToolType.unitPlanner:
          return urls.unitPlanner.exportPost()
        case ToolType.coverLetter:
          return urls.coverLetter.exportPost()
      }
    })(type as ToolType)

    try {
      const res = await axios.post(url, data, { responseType })
      return formatSuccessResponse(res, { keepOriginalResponse: true })
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  activityPlanner: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<ActivityPlannerPromptData> => {
      try {
        const res = await axios.get(urls.activityPlanner.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<ActivityPlannerResponse>> => {
      try {
        const res = await axios.post(urls.activityPlanner.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<ActivityPlannerResponse>> => {
      try {
        const res = await axios.patch(urls.activityPlanner.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.activityPlanner.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.activityPlanner.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<ActivityPlannerResponse>> => {
      try {
        const res = await axios.get(urls.activityPlanner.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.activityPlanner.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  unitPlanner: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<UnitPlanPromptData> => {
      try {
        const res = await axios.get(urls.unitPlanner.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generateUnitPlanPrompt: async ({
      data,
    }: Config): Promise<APIResponse<UnitPlanPromptData>> => {
      try {
        const res = await axios.post(
          urls.unitPlanner.generateUnitPlanPrompt(),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generateUnitPlan: async ({
      data,
    }: Config): Promise<APIResponse<UnitPlanGenerateResponse>> => {
      try {
        const res = await axios.post(urls.unitPlanner.generateUnitPlan(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.unitPlanner.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUnitDivisionData: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(
          urls.unitPlanner.updateUnitDivisionData(id),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generateUnitDivision: async ({
      data,
    }: Config): Promise<APIResponse<UnitPlanGenerateResponse>> => {
      try {
        const res = await axios.post(
          urls.unitPlanner.generateUnitDivision(),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveUnitDivisionResponse: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<UnitPlanDivisionResponse>> => {
      try {
        const res = await axios.get(
          urls.unitPlanner.retrieveUnitDivisionResponse(id)
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveUnitPlanResponse: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<UnitPlanResponse>> => {
      try {
        const res = await axios.get(
          urls.unitPlanner.retrieveUnitPlanResponse(id)
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveUnitPlanStructure: async ({
      data,
    }: Config): Promise<APIResponse<UnitPlanResponse>> => {
      try {
        const res = await axios.post(
          urls.unitPlanner.retrieveUnitPlanStructure(),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateUnitPlanDivision: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<RetrievePrompt<ToolType.unitPlanner>>
    > => {
      try {
        const res = await axios.post(
          urls.unitPlanner.regenerateUnitPlanDivision(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateReactionUnitPlanDivision: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.unitPlanner.updateReactionUnitPlanDivision(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.unitPlanner.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.unitPlanner.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<UnitPlanResponse>> => {
      try {
        const res = await axios.get(urls.unitPlanner.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.unitPlanner.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  subjectiveAssessment: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<SubjectiveAssessmentPromptData> => {
      try {
        const res = await axios.get(urls.subjectiveAssessment.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<SubjectiveAssessmentGenerateResponse>> => {
      try {
        const res = await axios.post(urls.subjectiveAssessment.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generatePro: async ({
      data,
    }: Config): Promise<
      APIResponse<SubjectiveAssessmentGenerateProResponse>
    > => {
      try {
        const res = await axios.post(
          urls.subjectiveAssessment.generatePro(),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(
          urls.subjectiveAssessment.updateData(id),
          data
        )

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.subjectiveAssessment.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.subjectiveAssessment.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<SubjectiveAssessmentResponse>
    > => {
      try {
        const res = await axios.get(urls.subjectiveAssessment.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.subjectiveAssessment.list(), {
          params,
        })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  quiz: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<QuizPromptData> => {
      try {
        const res = await axios.get(urls.quiz.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<QuizGenerateResponse>> => {
      try {
        const res = await axios.post(urls.quiz.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generatePro: async ({
      data,
    }: Config): Promise<APIResponse<QuizGenerateProResponse>> => {
      try {
        const res = await axios.post(urls.quiz.generatePro(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.quiz.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(urls.quiz.regenerateResponse(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(urls.quiz.updateUserReaction(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<QuizResponse>> => {
      try {
        const res = await axios.get(urls.quiz.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.quiz.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  assignment: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<AssignmentPromptData> => {
      try {
        const res = await axios.get(urls.assignment.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<AssignmentGenerateResponse>> => {
      try {
        const res = await axios.post(urls.assignment.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generatePro: async ({
      data,
    }: Config): Promise<APIResponse<AssignmentProGenerateResponse>> => {
      try {
        const res = await axios.post(urls.assignment.generatePro(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.assignment.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.assignment.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.assignment.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<AssignmentResponse>> => {
      try {
        const res = await axios.get(urls.assignment.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.assignment.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  rubrics: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<RubricsPromptData> => {
      try {
        const res = await axios.get(urls.rubrics.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<RubricsGenerateResponse>> => {
      try {
        const res = await axios.post(urls.rubrics.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generatePro: async ({
      data,
    }: Config): Promise<APIResponse<RubricsGenerateProResponse>> => {
      try {
        const res = await axios.post(urls.rubrics.generatePro(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.rubrics.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(urls.rubrics.regenerateResponse(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(urls.rubrics.updateUserReaction(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<RubricsResponse>> => {
      try {
        const res = await axios.get(urls.rubrics.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.rubrics.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  videoSummary: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<VideoSummaryPromptData> => {
      try {
        const res = await axios.get(urls.videoSummary.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<VideoSummaryGenerateResponse>> => {
      try {
        const res = await axios.post(urls.videoSummary.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },

    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.videoSummary.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.videoSummary.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.videoSummary.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<VideoSummaryResponse>> => {
      try {
        const res = await axios.get(urls.videoSummary.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.videoSummary.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  handout: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<HandoutPromptData> => {
      try {
        const res = await axios.get(urls.handout.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<HandoutGenerateResponse>> => {
      try {
        const res = await axios.post(urls.handout.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.handout.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(urls.handout.regenerateResponse(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(urls.handout.updateUserReaction(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<HandoutResponse>> => {
      try {
        const res = await axios.get(urls.handout.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.handout.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  lessonPlanner: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<LessonPlanPromptData> => {
      try {
        const res = await axios.get(urls.lessonPlan.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<LessonPlanResponse>> => {
      try {
        const res = await axios.post(urls.lessonPlan.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generatePro: async ({
      data,
    }: Config): Promise<APIResponse<LessonPlanProResponse>> => {
      try {
        const res = await axios.post(urls.lessonPlan.pro.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.lessonPlan.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.lessonPlan.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.lessonPlan.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<RetrieveLessonPlan>> => {
      try {
        const res = await axios.get(urls.lessonPlan.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContentPro: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<RetrieveLessonPlan>> => {
      try {
        const res = await axios.get(urls.lessonPlan.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.lessonPlan.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  narration: {
    getPromptData: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<NarrationPromptData> => {
      try {
        const res = await axios.get(urls.narration.getPromptData(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    generate: async ({
      data,
    }: Config): Promise<APIResponse<NarrationGenerateResponse>> => {
      try {
        const res = await axios.post(urls.narration.generate(), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateData: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.patch(urls.narration.updateData(id), data)

        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    regenerateResponse: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<
      APIResponse<{
        id: number
      }>
    > => {
      try {
        const res = await axios.post(
          urls.narration.regenerateResponse(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    updateUserReaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<NoContentType> => {
      try {
        const res = await axios.post(
          urls.narration.updateUserReaction(id),
          data
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieveContent: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NarrationResponse>> => {
      try {
        const res = await axios.get(urls.narration.retrieve(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    listHistory: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<ListPromptHistory[]>> => {
      try {
        const res = await axios.get(urls.narration.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
  },
  coverLetter: {
    reaction: async ({
      urlParams: { id },
      data,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(urls.coverLetter.reaction(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<RetrieveCoverLetter>> => {
      try {
        const res = await axios.get(urls.coverLetter.detail(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    list: async ({
      params,
    }: Config): Promise<PaginatedAPIResponse<CoverLetterHistory[]>> => {
      try {
        const res = await axios.get(urls.coverLetter.list(), { params })
        return formatSuccessResponse(res, { paginatedResponse: true })
      } catch (e) {
        throw formatErrorResponse(e)
      }
    },
    create: async ({
      data,
    }: Config): Promise<APIResponse<RetrieveCoverLetter>> => {
      try {
        const res = await axios.post(urls.coverLetter.list(), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.coverLetter.detail(id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    download: async ({
      urlParams: { id },
      params,
    }: ConfigWithURLParams): Promise<APIResponse<RetrieveCoverLetter>> => {
      try {
        const res = await axios.get(urls.coverLetter.export(id), {
          params,
          responseType: "blob",
        })
        return formatSuccessResponse(res, { keepOriginalResponse: true })
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  resumeFiller: {
    create: async (): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(urls.resumeFiller.create())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    update: async ({
      data,
      urlParams: { id },
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.put(urls.resumeFiller.update(id), data)
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    retrieve: async (): Promise<
      APIResponse<{
        dateCreated: string
        id: number
        output: ResumeFillerType | null
        status: "Queued" | "Completed"
      }>
    > => {
      try {
        const res = await axios.get(urls.resumeFiller.retrieve())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
}
