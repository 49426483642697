import axios from "api/axios"
import {
  APIResponse,
  APIResponseOld,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import {
  formatErrorResponse,
  formatErrorResponseOld,
  formatSuccessResponse,
  formatSuccessResponseOld,
} from "api/utils"

import {
  AuthData,
  BlockedUser,
  LearningUser,
  RefreshTokenResponse,
  UpdateUserPreferenceResponse,
  UserAttributes,
  UserPreference,
} from "./types"
import { urls } from "./urls"
// import { getFingerprint } from "utils/fingerprint"

export default {
  refreshToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<RefreshTokenResponse>> => {
    try {
      const res = await axios.post(urls.refreshToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  getUserToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<AuthData>> => {
    try {
      const res = await axios.post(urls.getUserToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  verifyAccessToken: async ({
    data,
    ...options
  }: Config): Promise<APIResponse<{ verified: boolean }>> => {
    try {
      const res = await axios.post(urls.verifyAccessToken(), data, options)
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
  block: {
    list: async (): Promise<APIResponse<BlockedUser[]>> => {
      try {
        const res = await axios.get(urls.block.list())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    delete: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.delete(urls.block.delete(urlParams.id))
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  getUserPreferences: async (): Promise<APIResponse<UserPreference[]>> => {
    try {
      const res = await axios.get(urls.config.preferences())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUserPreferences: async ({
    data,
  }: Config): Promise<APIResponseOld<UpdateUserPreferenceResponse[]>> => {
    try {
      const res = await axios.post(urls.config.preferences(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },

  getUserAttributes: async (): Promise<APIResponse<UserAttributes>> => {
    try {
      const res = await axios.get(urls.config.attributes())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUserAttributes: async ({
    data,
  }: Config): Promise<APIResponse<UserAttributes>> => {
    try {
      const res = await axios.put(urls.config.attributes(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  changePassword: async ({
    data,
  }: Config): Promise<APIResponseOld<NoContentType>> => {
    try {
      const res = await axios.post(urls.changePassword(), data)
      return formatSuccessResponseOld(res)
    } catch (e) {
      return formatErrorResponseOld(e)
    }
  },
  getUser: async (): Promise<APIResponse<LearningUser>> => {
    try {
      const res = await axios.get(urls.user())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  updateUser: async ({ data }: Config): Promise<APIResponse<LearningUser>> => {
    try {
      const res = await axios.put(urls.user(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  generateAuthCode: async ({
    data,
  }: Config): Promise<APIResponse<{ code: string; expiresAt: string }>> => {
    try {
      const res = await axios.post(urls.generateAuthCode(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
  createLead: async ({ data }: Config): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.post(urls.leads(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },
}
