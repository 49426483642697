import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getSolisURL = getServiceURL(SERVICE_PREFIX.solis)
const getHermesURL = getServiceURL(SERVICE_PREFIX.hermes)
const getGravityURL = getServiceURL(SERVICE_PREFIX.gravity)
const getPhoenixURL = getServiceURL(SERVICE_PREFIX.phoenix)
const getCygnus = getServiceURL(SERVICE_PREFIX.cygnus)

export const urls = validateUrls({
  profile: {
    detail: () => getSolisURL(`/v1/profile/`),
    resume: () => getSolisURL(`/v1/profile/download-resume/`),
    public: username =>
      getSolisURL(`/v1/profile/public/${username ? `${username}/` : ""}`),
  },

  languages: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/languages/${username ? `${username}/` : ""}`
      ),
    create: () => getSolisURL("/v1/profile/languages/"),
    detail: id => getSolisURL(`/v1/profile/languages/${id}/`),
  },

  locations: {
    create: () => getSolisURL("/v1/profile/preferred-work-locations/"),
    detail: id => getSolisURL(`/v1/profile/preferred-work-locations/${id}/`),
  },

  awards: {
    detail: id => getSolisURL(`/v1/profile/awards/${id}/`),
    list: () => getSolisURL("/v1/profile/awards/"),
  },

  publications: {
    detail: id => getSolisURL(`/v1/profile/publications/${id}/`),
    list: () => getSolisURL("/v1/profile/publications/"),
  },

  projects: {
    detail: id => getSolisURL(`/v1/profile/projects/${id}/`),
    list: () => getSolisURL("/v1/profile/projects/"),
  },

  testScores: {
    detail: id => getSolisURL(`/v1/profile/test-scores/${id}/`),
    list: () => getSolisURL("/v1/profile/test-scores/"),
  },
  skills: {
    delete: id => getSolisURL(`/v1/profile/skills/${id}/`),
    create: () => getSolisURL("/v1/profile/skills/"),
    list: username =>
      getSolisURL(
        `/v1/profile/public/skills/${username ? `${username}/` : ""}`
      ),
    listSuggestions: () => getHermesURL(`/v1/suggested-skills/`),
  },
  subjects: {
    create: () => getSolisURL("/v1/profile/subjects/"),
    detail: id => getSolisURL(`/v1/profile/subjects/${id}/`),
  },

  interests: {
    detail: id => getSolisURL(`/v1/profile/interests/${id}/`),
    create: () => getSolisURL("/v1/profile/interests/"),
    list: username =>
      getSolisURL(
        `/v1/profile/public/interests/${username ? `${username}/` : ""}`
      ),
  },

  achievements: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/achievements/${username ? `${username}/` : ""}`
      ),
  },

  workExperiences: {
    detail: id => getSolisURL(`/v1/profile/work-experiences/${id}/`),
    list: username =>
      getSolisURL(
        `/v1/profile/public/work-experiences/${username ? `${username}/` : ""}`
      ),
    create: () => getSolisURL("/v1/profile/work-experiences/"),
  },
  academics: {
    list: username =>
      getSolisURL(
        `/v1/profile/public/academics/${username ? `${username}/` : ""}`
      ),
  },
  qualificationLevels: {
    list: () => getGravityURL("/qualification-levels/"),
  },
  qualification: {
    create: () => getSolisURL(`/v1/profile/qualifications/`),
    detail: id => getSolisURL(`/v1/profile/qualifications/${id}/`),
    evidence: id => getSolisURL(`/v1/profile/qualifications/${id}/evidences`),
  },
  certification: {
    create: () => getSolisURL(`/v1/profile/certifications/`),
    detail: id => getSolisURL(`/v1/profile/certifications/${id}/`),
    evidence: id => getSolisURL(`/v1/profile/certifications/${id}/evidences`),
  },
  videoPortfolio: {
    detail: () => `/profile/video-portfolio/`,
    retrieve: username => `/profile/public/${username}/video-portfolio/`,
  },
  general: {
    retrieve: () => getSolisURL(`/v1/profile/public/`),
    detail: () => getSolisURL(`/v1/profile/`),
  },
  skillEvidences: {
    list: username =>
      username
        ? `/analytics/public/user-skill-portfolio/${username}/`
        : `/analytics/public/user-skill-portfolio/`,
    overview: username =>
      username
        ? `analytics/public/${username}/skill-evidences/`
        : `/analytics/skill-evidences/`,
    toggleVisibility: () => `/assignments/toggle-visibility/`,
  },
  credentials: {
    getItemCertificate: courseId =>
      getPhoenixURL(`/v1/course/${courseId}/credentials/`),

    download: (itemType, itemSlug) =>
      getPhoenixURL(`/v1/${itemType}/${itemSlug}/credentials/download/`),

    listCertificates: () => getPhoenixURL("/v1/credentials/"),

    listMasterclassCertificates: username =>
      username
        ? getCygnus(`/v1/masterclasses/public/users/${username}/certificates/`)
        : getCygnus("/v1/masterclasses/users/certificates/"),

    checkExistence: (username, itemType, itemSlug) =>
      getPhoenixURL(
        `/v1/users/${username}/${itemType}/${itemSlug}/credentials/`
      ),
  },
})
