import {
  Button,
  DialogBody,
  DialogFooter,
  Typography,
} from "@suraasa/placebo-ui"
import { ReattemptType } from "api/resources/assessments/types"
import React from "react"

const Note = ({
  type,
  onClick,
}: {
  type: ReattemptType
  onClick: () => void
}) => {
  const isAutomatic = type === ReattemptType.AUTOMATIC

  return (
    <>
      <DialogBody className="py-2.5">
        <Typography variant="body" className="mb-1">
          <ul className="list-disc ps-2">
            {isAutomatic ? (
              <>
                <li className="mb-1">
                  You can now re-attempt your assignment.
                </li>
              </>
            ) : (
              <>
                <li className="mb-1">
                  Your request will be reviewed by your program manager within
                  48 hours.
                </li>
                <li className="mb-1">
                  You will be notified via email once your request is reviewed.
                </li>
              </>
            )}

            <li className="mb-1">
              Remember to include the complete submission in your re-attempt.
            </li>
          </ul>
        </Typography>
      </DialogBody>
      <DialogFooter>
        <Button onClick={onClick} size="sm">
          {isAutomatic ? "Re-attempt Now" : "I understand"}
        </Button>
      </DialogFooter>
    </>
  )
}

export default Note
