import {
  Avatar,
  Button,
  DialogBody,
  DialogFooter,
  Typography,
} from "@suraasa/placebo-ui"
import { UserReattemptCounterType } from "api/resources/assessments/types"
import { Batch } from "api/resources/batch/types"
import clsx from "clsx"
import { calculateReattemptsLeft } from "features/ReattemptCount"
import { buildUserName, getAuthInfo, pluralize } from "utils/helpers"

const getTitle = (type: "assessment" | "assignment") => {
  switch (type) {
    case "assessment":
      return "Assessment"
    case "assignment":
      return "Assignment"
  }
}

const Request = ({
  data,
  title,
  type,
  userReattemptCounter,
  qualificationTitle,
  onClick,
}: {
  data: Batch[]
  title: string
  type: "assessment" | "assignment"
  userReattemptCounter: UserReattemptCounterType
  qualificationTitle?: string
  onClick: () => void
}) => {
  const { reattemptsAllowed } = userReattemptCounter

  const reAttemptsLeft = calculateReattemptsLeft(userReattemptCounter)

  const auth = getAuthInfo()
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const fullName = buildUserName(auth!.user)

  const generateMailContent = (
    coordinatorName: string,
    coordinatorEmail: string
  ) => {
    const subject = `Subject: Request to Re-attempt ${getTitle(type)}`
    const encodedText = encodeURIComponent(
      `Respected ${coordinatorName},

I hope this message finds you well. I am writing to request an opportunity to re-attempt the ${type} “${title}”.

Your consideration of this request would be greatly appreciated.

Thank you for your understanding and support.

Best regards,
${fullName}`
    )
    const mailText = encodedText.replaceAll("\n", "%0D%0A")
    return `mailto:${coordinatorEmail}?subject=${subject}&body=${mailText}`
  }
  return (
    <>
      <DialogBody className="py-2.5">
        {reAttemptsLeft !== undefined && (
          <div
            className={clsx(
              "mb-1 flex flex-wrap items-center justify-between gap-2 rounded-full px-2 py-1",
              {
                "bg-interactive-50 text-interactive-900": reAttemptsLeft > 0,
                "bg-critical-50 text-critical-900": reAttemptsLeft <= 0,
              }
            )}
          >
            <Typography variant="title4">Re-attempts left</Typography>
            <Typography variant="title4">
              {reAttemptsLeft > 0 ? reAttemptsLeft : 0} / {reattemptsAllowed}
            </Typography>
          </div>
        )}
        {reAttemptsLeft !== undefined && reAttemptsLeft > 0 ? (
          <Typography variant="smallBody" className="text-onSurface-600">
            You get total{" "}
            <Typography variant="strongSmallBody" className="inline">
              {reattemptsAllowed === -1 ? 20 : reattemptsAllowed} re-attempts
            </Typography>{" "}
            for your assessment and assignment submissions throughout{" "}
            {qualificationTitle}.
          </Typography>
        ) : (
          <>
            <Typography variant="smallBody" className="text-onSurface-600">
              You have used all your reattempts for your assessment and
              assignment submissions for {qualificationTitle}.
              <br />
              <br />
              Please contact{" "}
              {pluralize("your program co-ordinator", data.length, {
                plural: "one of your program co-ordinators",
                skipCount: true,
              })}{" "}
              for further assistance.
              <div className="mt-2 flex flex-col gap-1">
                {data.map((item, index) => {
                  const mailToContent = generateMailContent(
                    item.coordinator.fullName,
                    item.coordinator.email
                  )
                  return (
                    <a
                      key={index}
                      href={mailToContent}
                      className="flex items-center gap-1 rounded-xl border p-2 hover:bg-interactive-50"
                    >
                      <Avatar
                        className="size-6"
                        name={item.coordinator.fullName}
                      />

                      <Typography>
                        <strong>{item.coordinator.fullName}</strong>
                        <br />
                        {item.coordinator.email}
                      </Typography>
                    </a>
                  )
                })}
              </div>
            </Typography>
          </>
        )}
      </DialogBody>
      {(reAttemptsLeft || 0) > 0 && (
        <DialogFooter>
          <Button onClick={onClick} size="sm">
            Continue
          </Button>
        </DialogFooter>
      )}
    </>
  )
}

export default Request
