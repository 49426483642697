import { toast } from "@suraasa/placebo-ui"
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
} from "@tanstack/react-query"
import api from "api"
import { ToolType } from "api/resources/aiTools/types"
import { CartlessOrder } from "api/resources/orders/types"
import {
  Subscription,
  SubscriptionStatus,
} from "api/resources/subscriptions/types"
import { APIResponse, PaginatedAPIResponse } from "api/types"
import WorldTeachersMonthFreeClaimPopup from "features/WorldTeachersMonth"
import { context } from "global/Context/context"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { trackingService } from "utils/tracking"

import { FREE_CLAIM_PRODUCT_SLUG } from "../constants"

type showGetPlusSheetArgs = {
  claimCallback?: () => Promise<void>
  tool?: ToolType
  show: boolean
}

type Context = {
  status: SuraasaPlusSubscriptionStatus | undefined
  mode: SuraasaPlusSubscriptionMode | undefined
  isActive: boolean | undefined
  showGetPlusSheet: (options?: Omit<showGetPlusSheetArgs, "show">) => void
  refreshState: () => void
  ensurePlusAccess: (cb: () => Promise<void>) => void
  claimSuraasaPlus: UseMutateAsyncFunction<
    APIResponse<CartlessOrder>,
    unknown,
    {
      hasFilledProfile: boolean
    },
    unknown
  >
}

const SuraasaProContext = createContext<Context>({
  isActive: undefined,
  status: undefined,
  mode: undefined,
  showGetPlusSheet: () => {},
  refreshState: () => {},
  ensurePlusAccess: () => {},
  // @ts-expect-error this is fine to write.
  claimSuraasaPlus: async () => {},
})

export const useSuraasaPlusContext = () => useContext(SuraasaProContext)

enum SuraasaPlusSubscriptionStatus {
  active,
  paused,
  expired,
  inActive,
}

enum SuraasaPlusSubscriptionMode {
  paid,
  freeTrial,
}

const getSubscriptionStatus = (
  subscriptions: PaginatedAPIResponse<Subscription[]> | undefined
): {
  mode: SuraasaPlusSubscriptionMode | undefined
  status: SuraasaPlusSubscriptionStatus | undefined
} => {
  if (!subscriptions) {
    return { status: undefined, mode: undefined }
  }

  if (subscriptions.data && subscriptions.data.length > 0) {
    return {
      status: SuraasaPlusSubscriptionStatus.active,
      mode: SuraasaPlusSubscriptionMode.paid,
    }
  } else {
    return { status: SuraasaPlusSubscriptionStatus.inActive, mode: undefined }
  }
}

export const ProAccessProvider = (props: { children: React.ReactNode }) => {
  const [status, setStatus] = useState<SuraasaPlusSubscriptionStatus>()
  const [mode, setMode] = useState<SuraasaPlusSubscriptionMode>()

  const { isAuthenticated } = useContext(context)

  const [searchParams] = useSearchParams()
  const preventAutomaticPopup = searchParams.get("initialClaimPopup") === "0"

  const [isGetPlusSheetOpen, setIsGetPlusSheetOpen] =
    useState<showGetPlusSheetArgs>({
      show: false,
    })

  const subscriptions = useQuery({
    enabled: isAuthenticated,
    queryFn: () =>
      api.subscriptions.list({
        params: {
          status: SubscriptionStatus.active,
        },
      }),
    queryKey: [
      "subscriptions",
      {
        status: SubscriptionStatus.active,
      },
    ],
  })

  useEffect(() => {
    const { status, mode } = getSubscriptionStatus(subscriptions.data)
    setStatus(status)
    setMode(mode)
  }, [subscriptions.data])

  useEffect(() => {
    // If status has not loaded yet, return
    if (status === undefined) return
    if (status === SuraasaPlusSubscriptionStatus.active) return
    if (preventAutomaticPopup) return

    const hasSeenAutomaticWTMClaimPopup =
      sessionStorage.getItem("hasSeenAutomaticWTMClaimPopup") === "true"

    setIsGetPlusSheetOpen(prev => ({
      show: prev.show || (isAuthenticated && !hasSeenAutomaticWTMClaimPopup),
    }))
  }, [status, isAuthenticated, preventAutomaticPopup])

  const isActive =
    status !== undefined
      ? status === SuraasaPlusSubscriptionStatus.active
      : undefined

  const ensurePlusAccess = async (cb: () => Promise<void>) => {
    const freshOrders = await subscriptions.refetch()
    const { status } = getSubscriptionStatus(freshOrders.data)

    if (status === SuraasaPlusSubscriptionStatus.active) {
      await cb()
    } else {
      setIsGetPlusSheetOpen({
        show: true,
        claimCallback: cb,
      })
    }
  }

  const claimSuraasaPlus = useMutation({
    mutationFn: ({ hasFilledProfile }: { hasFilledProfile: boolean }) =>
      api.orders.createOrder({
        data: {
          pf: hasFilledProfile,
          product_slug: FREE_CLAIM_PRODUCT_SLUG,
        },
      }),
    onSuccess: () => {
      trackingService.trackEvent("claimed_suraasa_plus", {
        campaign: "world teachers month",
      })
      toast.success("Your Suraasa Plus subscription is active!")
      subscriptions.refetch()
    },
    onError: () => {
      toast.error("We're unable to process your request")
      subscriptions.refetch()
    },
  })

  return (
    <SuraasaProContext.Provider
      value={{
        isActive,
        status,
        mode,
        showGetPlusSheet: (options?: Omit<showGetPlusSheetArgs, "show">) => {
          setIsGetPlusSheetOpen({ show: true, ...options })
        },
        ensurePlusAccess,
        refreshState: subscriptions.refetch,
        claimSuraasaPlus: claimSuraasaPlus.mutateAsync,
      }}
    >
      {/* <GetProSheet
        open={isGetPlusSheetOpen.show}
        tool={isGetPlusSheetOpen.tool}
        claimCallback={isGetPlusSheetOpen.claimCallback}
        handleClose={() => {
          sessionStorage.setItem(
            "hasSeenAutomaticSuraasaPlusClaimPopup",
            "true"
          )
          setisGetPlusSheetOpen({ show: false })
        }}
      /> */}
      <WorldTeachersMonthFreeClaimPopup
        open={isGetPlusSheetOpen.show}
        claimCallback={isGetPlusSheetOpen.claimCallback}
        handleClose={() => {
          sessionStorage.setItem("hasSeenAutomaticWTMClaimPopup", "true")
          setIsGetPlusSheetOpen({ show: false })
        }}
      />

      {props.children}
    </SuraasaProContext.Provider>
  )
}
