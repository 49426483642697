import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import { AssignmentReturnType } from "../../AssignmentGenerator/useAssignment"
import InputDisplay from "../../helpers/InputDisplay"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"
import styles from "./AssignmentOutput.module.css"

const AssignmentOutput = (
  props: Pick<
    AssignmentReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props

  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            grade: overviewData.grade,
            rubricScale: overviewData.rubricScale,
            subject: overviewData.subject,
            curriculum: overviewData.curriculum,
            topic: overviewData.topic,
            book: overviewData.book,
            isPro: overviewData.isPro,
            responseIds: overviewData.responseIds || [],
            title: overviewData.title || "",
            //To handle this
            unitDivisionResponses: [],
            classDuration: "",
          }}
        />
      )}

      <Paper
        className="p-0"
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div className={clsx("overflow-x-auto", styles.tableContainer)}>
          <ToolOutput isLoading={isLoading} onClick={onBack}>
            {output}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default AssignmentOutput
