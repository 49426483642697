import {
  Button,
  CircularProgress,
  ErrorMessage,
  RadioGroup,
  RadioGroupItem,
  Select,
  TextField,
  toast,
  Typography,
} from "@suraasa/placebo-ui"
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
} from "@suraasa/placebo-ui-legacy"
import { useMutation } from "@tanstack/react-query"
import api from "api"
import { APIError } from "api/utils"
import GoogleLocationSelect, {
  AddressComponents,
} from "components/GoogleLocationSelect.tsx"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { Controller, useForm } from "react-hook-form"
import { useResource } from "utils/hooks/useResource"
import { getUTMParams } from "utils/tracking/utmParams"

const formId = "personalise-your-experience"

const Options = {
  yes: "Yes",
  no: "No",
}

const ProfileDataDialog = ({
  open,
  onClose,
  onSubmit,
  userPhoneNumber,
}: {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  userPhoneNumber: string | null
}) => {
  const {
    register,
    control,
    clearErrors,
    handleSubmit,
    setError,
    formState: { errors, isLoading },
    watch,
  } = useForm<{
    hasTeachingQualification: "Yes" | "No" | null
    hasTeachingExperience: "Yes" | "No" | null
    teachingExperienceInYears: string
    subjects: string[]
    grades: string
    location: AddressComponents | null
  }>()

  const { subjects } = useResource(["subjects"])

  const hasTeachingExperience = watch("hasTeachingExperience")

  const { claimSuraasaPlus } = useSuraasaPlusContext()

  const createLead = useMutation({
    mutationFn: (data: any) =>
      api.users.createLead({
        data: {
          phoneNumber: userPhoneNumber!,
          data: data,
          leadSource: "world-teachers-month-lms-popup",
          utmParams: getUTMParams(),
          skipCrmSync: true,
        },
      }),
    onError: err => {
      if (err instanceof APIError) {
        toast.error(err.errors.message)
      } else toast.error("We're unable to process your request")
    },
  })

  const onFormSubmit = handleSubmit(async formData => {
    clearErrors()

    let hasError = false
    if (formData.hasTeachingExperience === "Yes") {
      if (!formData.teachingExperienceInYears) {
        hasError = true
        setError("teachingExperienceInYears", { message: "Required" })
      }
      if (!formData.grades) {
        hasError = true
        setError("grades", { message: "Required" })
      }
    }
    if (hasError) return

    try {
      await createLead.mutateAsync({
        hasTeachingQualification: formData.hasTeachingQualification,
        hasTeachingExperience: formData.hasTeachingExperience,
        ...(formData.hasTeachingExperience === Options.yes
          ? {
              teachingExperienceInYears: formData.teachingExperienceInYears,
              grades: formData.grades,
            }
          : {}),
        subjects: formData.subjects.join(", "),
        location: formData.location
          ?.map(item => item.longText || item.shortText)
          .join(", "),
      })

      await claimSuraasaPlus({ hasFilledProfile: true })
      onSubmit()
    } catch (err) {
      console.log(err)
    }
  })

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="strong">Personalise Your Experience</Typography>
      </DialogTitle>
      <DialogContent className="!p-0">
        <div className="p-2.25">
          {(isLoading || createLead.isLoading) && (
            <div className="absolute left-0 top-0 z-1 grid size-full place-items-center bg-white/75">
              <CircularProgress />
            </div>
          )}
          <Typography variant="body" className="mb-3">
            Fill out the following details to get 2 months of Suraasa Plus free
            right away.
          </Typography>
          <form
            id={formId}
            onSubmit={onFormSubmit}
            className="flex flex-col gap-2"
          >
            <div>
              <Typography variant="strong" className="mb-0.5">
                Do you have a teaching qualification (like B.Ed, M.Ed, PGCE)?
              </Typography>

              <Controller
                control={control}
                name="hasTeachingQualification"
                render={({ field: { onChange } }) => (
                  <RadioGroup
                    onValueChange={option => {
                      onChange(
                        option === Options.yes ? Options.yes : Options.no
                      )
                    }}
                    className="flex flex-wrap items-center"
                  >
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label={Options.yes}
                      value={Options.yes}
                    />
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label={Options.no}
                      value={Options.no}
                    />
                  </RadioGroup>
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {errors.hasTeachingQualification && (
                <ErrorMessage title={errors.hasTeachingQualification.message} />
              )}
            </div>
            <div>
              <Typography variant="strong" className="mb-0.5">
                Do you have professional teaching experience in a school?
              </Typography>

              <Controller
                control={control}
                name="hasTeachingExperience"
                render={({ field: { onChange } }) => (
                  <RadioGroup
                    className="flex flex-wrap items-center"
                    onValueChange={option => {
                      onChange(
                        option === Options.yes ? Options.yes : Options.no
                      )
                    }}
                  >
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label={Options.yes}
                      value={Options.yes}
                    />
                    <RadioGroupItem
                      containerClass="flex items-center gap-1"
                      label={Options.no}
                      value={Options.no}
                    />
                  </RadioGroup>
                )}
                rules={{
                  required: { value: true, message: "Required" },
                }}
              />
              {errors.hasTeachingExperience && (
                <ErrorMessage title={errors.hasTeachingExperience.message} />
              )}
            </div>
            {hasTeachingExperience === Options.yes && (
              <>
                <TextField
                  label="School Teaching Experience (Years)"
                  required
                  placeholder="Ex: 10"
                  endIcon={
                    <Typography
                      variant="smallBody"
                      className="text-onSurface-500"
                    >
                      years
                    </Typography>
                  }
                  errors={errors.teachingExperienceInYears?.message}
                  {...register("teachingExperienceInYears")}
                />
                <TextField
                  label="Grades"
                  required
                  placeholder="Ex: 6th, 7th, 8th"
                  helperText="Grades/Classes you teach in your school"
                  errors={errors.grades?.message}
                  {...register("grades")}
                />
              </>
            )}
            {hasTeachingExperience !== undefined && (
              <>
                <Controller
                  control={control}
                  name="subjects"
                  render={({ field: { value, onChange, onBlur } }) => (
                    <Select
                      errors={errors.subjects?.message}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.uuid}
                      helperText={
                        hasTeachingExperience === Options.yes
                          ? "Subjects you teach in your school"
                          : ""
                      }
                      required
                      label={
                        hasTeachingExperience === Options.yes
                          ? "Subject"
                          : "Preferred Subjects"
                      }
                      options={subjects}
                      placeholder="Select Subject"
                      value={
                        value
                          ? subjects.filter(c => value.includes(c.uuid))
                          : null
                      }
                      mountOnBody
                      isMulti
                      onBlur={onBlur}
                      onChange={val => onChange(val.map(x => x.uuid))}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
                <Controller
                  control={control}
                  name="location"
                  render={({ field: { onChange } }) => (
                    <GoogleLocationSelect
                      fullWidth
                      label={
                        hasTeachingExperience === Options.yes
                          ? "Your School Name"
                          : "Your City"
                      }
                      required
                      className="w-full"
                      placeHolder={
                        hasTeachingExperience === Options.yes
                          ? "Enter school name"
                          : "Enter city name"
                      }
                      error={Boolean(errors.location?.message)}
                      helperText={
                        hasTeachingExperience === Options.yes
                          ? errors.location?.message ||
                            "If you can't find your school then select your city"
                          : errors.location?.message
                      }
                      onChange={val => {
                        onChange(val)
                      }}
                    />
                  )}
                  rules={{
                    required: { value: true, message: "Required" },
                  }}
                />
              </>
            )}
          </form>
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="flex items-center justify-end gap-2">
          <Button onClick={onClose} variant="outlined" color="null" size="sm">
            Cancel
          </Button>
          <Button
            form={formId}
            loading={isLoading || createLead.isLoading}
            type="submit"
            size="sm"
          >
            Get Suraasa Plus free for 2 Months
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  )
}

export default ProfileDataDialog
