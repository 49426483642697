import { useCamDetection } from "./useCamDetection"
import { useCopyDisable } from "./useCopyDisable"
import { useDevToolDetection } from "./useDevToolsDetection"
import { useDisableContextMenu } from "./useDisableContextMenu"
// import { useFaceDetection } from "./useFaceDetection"
// import { useFullScreenDetection } from "./useFullScreenDetection"
import { useTabUnfocusDetection } from "./useTabUnfocusDetection"

type Props = {
  disabled?: boolean
}

export function useProctoring({ disabled = false }: Props) {
  useDisableContextMenu({ disabled })
  useCopyDisable({ disabled })
  const { webCamStatus, videoRef } = useCamDetection({
    disabled,
  })
  // const { violationType, clearViolations } = useFaceDetection({
  //   videoRef,
  //   disabled,
  // })

  // Disabled devtools detection
  const { devToolsOpen } = useDevToolDetection({ disabled })
  // const { fullScreenStatus } = useFullScreenDetection({ disabled })
  const { tabUnfocusStatus } = useTabUnfocusDetection({ disabled })
  return {
    webcam: {
      webCamStatus,
      videoRef,
      // violationType,
      // clearViolations,
    },
    devTools: { devToolsOpen },
    fullScreen: { fullScreenStatus: true },
    tabUnfocus: { tabUnfocusStatus },
  } as const
}
