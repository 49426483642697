import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"

type Resource = (
  | "languages"
  | "subjects"
  | "countries"
  | "curriculum"
  | "currencies"
)[]

export const useResource = (resource: Resource) => {
  const languages = useQuery({
    refetchOnMount: false,
    enabled: resource.includes("languages"),
    queryKey: queries.global.languageList().queryKey,
    queryFn: () => api.global.listLanguages({ params: { page: "-1" } }),
  })
  const subjects = useQuery({
    refetchOnMount: false,
    enabled: resource.includes("subjects"),
    queryKey: queries.global.subjectList().queryKey,
    queryFn: () => api.global.listSubjects({ params: { page: "-1" } }),
  })
  const countries = useQuery({
    refetchOnMount: false,
    enabled: resource.includes("countries"),
    queryKey: queries.global.countryList().queryKey,
    queryFn: () => api.global.listCountries({ params: { page: "-1" } }),
  })
  const curriculum = useQuery({
    refetchOnMount: false,
    enabled: resource.includes("curriculum"),
    queryKey: queries.global.curriculumList().queryKey,
    queryFn: () => api.global.listCurriculum({ params: { page: -1 } }),
  })
  const currencies = useQuery({
    refetchOnMount: false,
    enabled: resource.includes("currencies"),
    queryKey: queries.global.currencyList().queryKey,
    queryFn: () => api.global.listCurrencies({ params: { page: -1 } }),
  })

  return {
    languages: languages.data || [],
    subjects: subjects.data || [],
    countries: countries.data || [],
    currencies: currencies.data || [],
    curriculum: curriculum.data || [],
  }
}
