export function generateUuidV4(): string {
  const bytes = new Uint8Array(16)
  window.crypto.getRandomValues(bytes) // Secure random values

  // Set the version to 4 (UUID v4)
  bytes[6] = (bytes[6] & 0x0f) | 0x40

  // Set the variant to 2 (RFC 4122)
  bytes[8] = (bytes[8] & 0x3f) | 0x80

  return (
    `${bytesToHex(bytes.slice(0, 4))}-` +
    `${bytesToHex(bytes.slice(4, 6))}-` +
    `${bytesToHex(bytes.slice(6, 8))}-` +
    `${bytesToHex(bytes.slice(8, 10))}-` +
    `${bytesToHex(bytes.slice(10, 16))}`
  )
}

function bytesToHex(bytes: Uint8Array): string {
  return Array.from(bytes, byte => byte.toString(16).padStart(2, "0")).join("")
}
