import { toast } from "@suraasa/placebo-ui"
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import {
  SubjectiveAssessmentFormType,
  SubjectiveAssessmentGenerateProResponse,
  SubjectiveAssessmentGenerateResponse,
  SubjectiveAssessmentProFormType,
  SubjectiveAssessmentPromptData,
  ToolType,
} from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import { getToolName } from "features/AItools/helper"
import {
  StreamFetchProps,
  StreamQuery,
  StreamResponseEnum,
  useStreamQuery,
} from "features/AItools/hooks/useStreamQuery"
import { useEffect, useState } from "react"
import { flushSync } from "react-dom"
import { UseFormSetError } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { mapErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

const toolType = ToolType.subjectiveAssessment

export type SubjectiveAssessmentReturnType = {
  currentResponseId: number | null
  setCurrentResponseId: (id: number | null) => void
  promptDataId: string | number | null
  setPromptDataId: React.Dispatch<React.SetStateAction<string | number | null>>
  responseIds: number[] | null
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | null>>
  overviewData: SubjectiveAssessmentPromptData | undefined

  userVote: UseMutationResult<
    never,
    unknown,
    boolean | null | undefined,
    unknown
  >
  output: string | undefined
  isLoading: boolean
  stream: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }

  regenerateStream: StreamQuery
  generateTool: UseMutationResult<
    APIResponse<SubjectiveAssessmentGenerateResponse>,
    any,
    {
      data: any
      setError: UseFormSetError<SubjectiveAssessmentFormType>
    },
    unknown
  >
  generateProTool: UseMutationResult<
    APIResponse<SubjectiveAssessmentGenerateProResponse>,
    any,
    {
      data: any
      setError: UseFormSetError<SubjectiveAssessmentProFormType>
    },
    unknown
  >
  regenerateResponse: UseMutationResult<
    APIResponse<{
      id: number
    }>,
    unknown,
    string,
    unknown
  >
  isPositiveResponse: boolean | null | undefined
  resetState: () => void
  pageLoading: boolean
  onBack: () => void
  handleCancelApi: () => void
}

export const useSubjectiveAssessment = (): SubjectiveAssessmentReturnType => {
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const navigate = useNavigate()
  const [overviewData, setOverviewData] = useState<
    SubjectiveAssessmentPromptData | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)
  const [isPositiveResponse, setIsPositiveResponse] = useState<
    boolean | null | undefined
  >()
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const [promptDataId, setPromptDataId] = useState<string | number | null>(id)
  const [responseIds, setResponseIds] = useState<number[] | null>(null)
  const [pageLoading, setPageLoading] = useState(false)
  const [output, setOutput] = useState<string>("")

  const resetState = () => {
    setOutput("")
    setPromptDataId(null)
    setCurrentResponseId(null)
    setIsPositiveResponse(undefined)
    setOverviewData(undefined)
    setResponseIds(null)
  }
  const handleCancelApi = () => {
    if (streamTitle.isLoading) streamTitle.handleCancel()
    if (streamOutput.isLoading) streamOutput.handleCancel()
    if (regenerateStream.isLoading) regenerateStream.handleCancel()
  }
  const onBack = () => {
    navigate(routes.aiTools.subjectiveAssessment, { replace: true })
    resetState()
    handleCancelApi()
  }
  // This flow is for when user clicks on history item
  // If that is the case then we just fetch the overview and the latest generated output
  useEffect(() => {
    if (id) {
      setPageLoading(true)
      api.aiTools.subjectiveAssessment
        .getPromptData({
          urlParams: {
            id: id!,
          },
        })
        .then(async data => {
          setPromptDataId(id)
          const { responseIds } = data

          if (responseIds) {
            const [latestResponseId] = responseIds
            setCurrentResponseId(latestResponseId)
            setResponseIds(responseIds)
            await getContent(latestResponseId)
          }
          setOverviewData(data)
          setPageLoading(false)
        })
        .catch(error => {
          console.error("Error fetching prompt data:", error)
          setPageLoading(false)
        })
    }
  }, [id])

  const overviewAPI = async (id: string | number | null) => {
    const res = await api.aiTools.subjectiveAssessment.getPromptData({
      urlParams: {
        id: id!,
      },
    })
    if (res instanceof APIError) {
      toast.error("Prompt failed to generate")
      return
    }

    const { responseIds } = res

    if (responseIds) {
      const [latestResponseId] = responseIds
      setCurrentResponseId(latestResponseId)
      setResponseIds(responseIds)
    }

    setOverviewData(res)
  }

  const generateTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: any
      setError: UseFormSetError<SubjectiveAssessmentFormType>
    }) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.subjectiveAssessment.generate({ data })
    },
    onSuccess: async res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      const { responseId, id } = res

      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
        setOverviewData({
          id: res.id,
          subject: res.subject,
          grade: res.grade,
          curriculum: res.curriculum,
          topic: res.topic,
          country: res.country,
          totalMarks: res.totalMarks,
          totalQuestions: res.totalQuestions,
          lessonPlanResponseId: res.lessonPlanResponseId,
          title: `New ${getToolName(toolType)}`,
          isPro: false,
        })
      })

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
        toolType,
        responseType: StreamResponseEnum.generate,
      })
      await streamTitle.refetchAsync({
        currentResponseId: responseId,
        responseType: StreamResponseEnum.title,
        toolType,
      })
      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.subjectiveAssessment)
          .queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: toolType,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["duration"],
          ["grade"],
          ["subject"],
          ["curriculum"],
          ["topic"],
          ["country"],
          ["assessmentObjectives"],
          ["structure"],
          ["selectedLessonPlan"],
          ["questionDetails", "structure"],
          ["lessonPlanResponse", "selectedLessonPlan"],
        ])
      }
    },
  })
  const generateProTool = useMutation({
    mutationFn: async ({
      data,
    }: {
      data: any
      setError: UseFormSetError<SubjectiveAssessmentProFormType>
    }) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: ToolType.subjectiveAssessmentPro,
      })
      return api.aiTools.subjectiveAssessment.generatePro({ data })
    },
    onSuccess: async res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: ToolType.subjectiveAssessmentPro,
      })
      const { responseId, id } = res

      flushSync(() => {
        setCurrentResponseId(responseId)
        setPromptDataId(id)
        setOverviewData({
          id: res.id,
          subject: res.subject,
          curriculum: res.curriculum,
          country: res.country,
          grade: res.grade,
          topic: res.topic,
          //   book: res.book,
          //   difficultyLevel: res.difficultyLevel,
          lessonPlanResponseId: res.lessonPlanResponseId,
          //   questionDetails: res.questionDetails,
          title: `New ${getToolName(ToolType.subjectiveAssessmentPro)}`,
          isPro: true,
        })
      })

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
        toolType: ToolType.subjectiveAssessmentPro,
        responseType: StreamResponseEnum.generate,
      })
      await streamTitle.refetchAsync({
        currentResponseId: responseId,
        responseType: StreamResponseEnum.title,
        toolType: ToolType.subjectiveAssessmentPro,
      })
      await overviewAPI(id)
      queryClient.refetchQueries({
        queryKey: queries.aiTools.listHistory(ToolType.subjectiveAssessment)
          .queryKey,
        type: "active",
        exact: true,
      })
    },
    onError: (err: any, { setError }) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: ToolType.subjectiveAssessmentPro,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        mapErrors(setError, err, [
          ["grade"],
          ["subject"],
          ["curriculum"],
          ["topic"],
          ["country"],
          ["assessmentObjectives"],
          ["difficultyLevel"],
          ["structure"],
          ["questionDetails", "structure"],
          ["lessonPlanResponse", "selectedLessonPlan"],
        ])
      }
    },
  })

  const streamTitle = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: toolType,
  })

  const streamOutput = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: toolType,
    onError: () => {
      toast.error("Generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const regenerateStream = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: toolType,
    onSuccess: () => {
      toast.success("Re-generated Successfully")
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
    },
  })

  const userVote = useMutation({
    mutationFn: async (reaction: boolean | undefined | null) =>
      api.aiTools.subjectiveAssessment.updateData({
        urlParams: { id: currentResponseId!, type: toolType },
        data: {
          isPositiveResponse: reaction,
        },
      }),
  })

  const getContent = async (id: number) => {
    // resetStream()
    setOutput("")
    setIsLoading(true)
    const res = await api.aiTools.subjectiveAssessment.retrieveContent({
      urlParams: {
        id: id,
        type: toolType,
      },
    })
    if (res instanceof APIError) {
      toast.error(res.message || "Something went wrong")
      setIsLoading(false)
      return
    }

    setIsPositiveResponse(res.isPositiveResponse)
    setOutput(res.output)
    setIsLoading(false)
    // resetStream()
  }

  const regenerateResponse = useMutation({
    mutationFn: (data: string) =>
      api.aiTools.subjectiveAssessment.regenerateResponse({
        urlParams: {
          id: currentResponseId!,
        },
        data: {
          regenerateInstruction: data,
        },
      }),
    onSuccess: async res => {
      setCurrentResponseId(res.id)
      setOutput("")
      await regenerateStream.refetchAsync({
        currentResponseId: res.id,
        toolType,
        responseType: StreamResponseEnum.regenerate,
      })
      await overviewAPI(id || promptDataId)
    },
    onError: err => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  return {
    promptDataId,
    generateTool,
    setPromptDataId,
    currentResponseId,
    setCurrentResponseId: id => {
      setCurrentResponseId(id)
      if (id) {
        getContent(id)
      }
    },
    responseIds,
    setResponseIds,
    stream: {
      ...streamOutput,
      refetchAsync: ({ currentResponseId: id, responseType }) =>
        streamOutput.refetchAsync({
          currentResponseId: id,
          responseType,
          toolType,
        }),
    },

    regenerateResponse,
    overviewData,
    userVote,
    regenerateStream,
    generateProTool,
    output,
    isLoading:
      isLoading || regenerateStream.isLoading || streamOutput.isLoading,
    isPositiveResponse,
    resetState,
    pageLoading,
    onBack,
    handleCancelApi,
  }
}
