import { CircularProgress } from "@suraasa/placebo-ui"
import { ToolType } from "api/resources/aiTools/types"
import ContentActions from "features/AItools/components/Generator/GenerationOutput/Export/ContentActions"
import Heading from "features/AItools/components/Heading"
import { getToolName } from "features/AItools/helper"
import { usePlusFeature } from "features/AItools/PRO/utils/usePlusFeature"

import TogglePro from "../../TogglePro"
import RubricOutput from "../GenerationOutput/RubricsOutput"
import BasicForm from "./BasicForm"
import ProForm from "./ProForm"
import { useRubrics } from "./useRubrics"

const RubricsGenerator = () => {
  const [enableProMode, setEnableProMode] = usePlusFeature({
    ensurePlusAccess: false,
  })

  const {
    currentResponseId,
    setCurrentResponseId,
    onBack,
    pageLoading,
    promptDataId,
    ...rubricsContext
  } = useRubrics()

  if (promptDataId && pageLoading) {
    return (
      <div className="grid place-items-center p-4">
        <CircularProgress />
      </div>
    )
  }

  if (currentResponseId) {
    return (
      <>
        <ContentActions
          onBack={onBack}
          currentResponseId={currentResponseId}
          toolType={ToolType.rubrics}
          title={rubricsContext.overviewData?.title}
        />
        <RubricOutput
          {...rubricsContext}
          currentResponseId={currentResponseId}
          setCurrentResponseId={setCurrentResponseId}
          onBack={onBack}
        />
      </>
    )
  }

  return (
    <>
      <Heading
        title={`${getToolName(ToolType.rubrics)} Generator`}
        subtitle="Simplify assessment creation with the AI Rubrics Generator, enabling precise assignment evaluation."
      />
      <TogglePro
        isPro={enableProMode}
        onChange={state => setEnableProMode(state)}
        className="mb-2"
      />
      {enableProMode ? (
        <ProForm {...rubricsContext} />
      ) : (
        <BasicForm {...rubricsContext} />
      )}
    </>
  )
}

export default RubricsGenerator
