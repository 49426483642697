import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"
const getPolarisURL = getServiceURL(SERVICE_PREFIX.polaris)
const getSolisURL = getServiceURL(SERVICE_PREFIX.solis)
const getMercuryURL = getServiceURL(SERVICE_PREFIX.mercury)

export const urls = {
  block: {
    list: () => `/user/block/`,
    delete: (id: string | number) => `/user/block/${id}/`,
  },
  refreshToken: () => getSolisURL("/v1/auth/refresh-token/"),
  getUserToken: () => getSolisURL("/v1/auth/user-tokens/"),
  changePassword: () => getSolisURL("/v1/auth/change-password/"),
  generateAuthCode: () => getSolisURL("/v1/auth/codes/"),
  user: () => getSolisURL("/v1/users/"),
  verifyAccessToken: () => getSolisURL("/v1/auth/access-tokens/verify/"),
  config: {
    attributes: () => getSolisURL(`/v1/users/attributes/`),
    preferences: () => getPolarisURL(`/v1/users/settings/`),
  },
  leads: () => getMercuryURL(`/v2/leads/`),
}
