import { BROWSER_STORAGE_KEYS } from "utils/constants"

export const getUTMParams = (useCache = true) => {
  const params = new URLSearchParams(window.location.search)

  const keys = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ]

  // Object.entries(searchParams).forEach(([key, value]) => {
  //   params[key] = value
  // })
  const utmParams = {}

  keys.forEach(key => {
    const value =
      params.get(key) || (useCache ? getUTMParamValueFromStorage(key) : null)
    if (value) utmParams[key] = value
  })

  return utmParams
}

const getUTMParamValueFromStorage = (key: string) => {
  const storedParams = sessionStorage.getItem(
    BROWSER_STORAGE_KEYS.sessionUtmParams
  )
  if (!storedParams) return null

  try {
    const params = JSON.parse(storedParams)
    return params[key] || null
  } catch (e) {
    return null
  }
}
