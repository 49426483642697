import { Typography } from "@suraasa/placebo-ui"
import Paper from "features/AItools/components/Paper"

import { VideoSummaryReturnType } from "../../VideoSummaryGenerator/useVideoSummary"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"
// import styles from "./ActivityPlanner.module.css"

const VideoSummaryOutput = (
  props: Pick<
    VideoSummaryReturnType,
    | "output"
    | "isLoading"
    | "isPositiveResponse"
    | "currentResponseId"
    | "setCurrentResponseId"
    | "regenerateResponse"
    | "overviewData"
    | "responseIds"
    | "userVote"
    | "onBack"
  >
) => {
  const {
    output,
    overviewData,
    responseIds,
    currentResponseId,
    isPositiveResponse,
    setCurrentResponseId,
    userVote,
    regenerateResponse,
    isLoading,
    onBack,
  } = props
  return (
    <div className="mb-4 flex flex-col gap-2">
      <Paper
        endSlot={
          isLoading ? undefined : (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          )
        }
      >
        <div className="px-2">
          <Typography variant="title2" className="my-3">
            {overviewData?.title}
          </Typography>
          <Typography variant="title3" className="my-3">
            Summary
          </Typography>
        </div>

        <ToolOutput isLoading={isLoading} onClick={onBack}>
          {output}
        </ToolOutput>
      </Paper>
    </div>
  )
}

export default VideoSummaryOutput
