import TopNavigation from "components/Navigation/TopNavigation"
import React from "react"
import { useSearchParams } from "react-router-dom"

import Profile from "."

const PublicProfile = () => {
  const [searchParams] = useSearchParams()
  const isEmbedded = searchParams.get("embed") === "true"
  return (
    <>
      <TopNavigation showSignInButton={!isEmbedded} />
      <Profile />
    </>
  )
}

export default PublicProfile
