import { Typography } from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import clsx from "clsx"
import { useCallback, useEffect, useRef, useState } from "react"
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts"

// eslint-disable-next-line no-warning-comments
//TODO:fix this page typescript
const data = [
  { name: "Attendance", value: 20, color: "#475569" },
  { name: "Assignments", value: 50, color: "#1E293B" },
  { name: "Live Assessments", value: 10, color: "#E2E8F0" },
  { name: "Self-Paced Score", value: 20, color: "#94A3B8" },
]

const RADIAN = Math.PI / 180

const renderWebActiveShape = (props: any) => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
  } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius - 20) * cos
  const sy = cy + (outerRadius - 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? "start" : "end"

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        className="text-2xl font-bold leading-[29.05px] tracking-[-0.02em] text-onSurface-500"
        textAnchor="middle"
        stroke="#64748B"
        strokeWidth={0}
        fill="#64748B"
      >
        Grade Weightage
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        fill="none"
        strokeWidth={2}
        className="text-wrap stroke-onSurface-800"
      />
      <circle
        cx={ex}
        cy={ey}
        r={4}
        stroke="none"
        className="text-wrap fill-onSurface-800"
      />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        className="text-wrap fill-onSurface-800"
      >
        <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="0">
          {payload.name}
        </tspan>
        <tspan x={ex + (cos >= 0 ? 1 : -1) * 12} dy="1.2em">
          ({payload.value}%)
        </tspan>
      </text>
      {/* <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        className="text-wrap fill-onSurface-800"
      >
        {payload.name}
        {`\n`}({payload.value}%)
      </text> */}
    </g>
  )
}
const renderMobileActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        className="text-2xl font-bold leading-[29.05px] tracking-[-0.02em] text-onSurface-500"
        textAnchor="middle"
        stroke="#64748B"
        strokeWidth={0}
        fill="#64748B"
      >
        Grade Weightage
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}

const Weightage = ({ className }: { className?: string }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [hasVisited, setHasVisited] = useState<boolean>(false)
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"))

  const checkDivVisiting = useCallback(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect()
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setHasVisited(true)
      }
    }
  }, [])

  useEffect(() => {
    if (ref.current) {
      window.addEventListener("scroll", checkDivVisiting)
    }
    return () => {
      window.removeEventListener("scroll", checkDivVisiting)
    }
  }, [checkDivVisiting, ref])

  return (
    <div className={clsx("pb-10", className)}>
      <Typography variant="title4" className="mb-1">
        Weightage
      </Typography>
      <Typography variant="smallBody" className="mb-1 text-onSurface-500">
        Your final grade is calculated based on a combination of different
        components, each contributing a specific percentage to your overall
        score. Understanding how each component affects your grade will help you
        focus on all areas of your coursework. Here is a breakdown of the
        weightage:
      </Typography>
      {isSmDown && (
        <div className="mt-2 flex flex-col items-start gap-1.5">
          {data.map(item => (
            <div key={item.name} className="flex items-center gap-2">
              <div
                className="size-2 rounded-full"
                style={{
                  backgroundColor: item.color,
                }}
              />
              <Typography variant="smallBody">
                {item.name}{" "}
                <Typography className="inline" variant="strongSmallBody">
                  ({item.value}%)
                </Typography>
              </Typography>
            </div>
          ))}
        </div>
      )}
      <div
        ref={ref}
        className="pointer-events-none mb-3 flex h-[370px] justify-center"
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart className="px-2">
            <Pie
              activeIndex={-1}
              inactiveShape={
                isSmDown ? renderMobileActiveShape : renderWebActiveShape
              }
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={110}
              outerRadius={140}
              paddingAngle={1}
              fill="#8884d8"
              dataKey="value"
              isAnimationActive={hasVisited}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <div>
        <Typography variant="title4" className="mb-1">
          Formulae
        </Typography>
        <Typography variant="smallBody" color="onSurface.500" className="mb-1">
          Points Earned = Grade Points × Credits
        </Typography>
        <Typography variant="smallBody" color="onSurface.500">
          CGPA = Total Points Earned / Total Credits
        </Typography>
      </div>
    </div>
  )
}

export default Weightage
