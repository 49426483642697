import { AIToolPrompts, ToolType } from "api/resources/aiTools/types"
import React from "react"

type Context = {
  responseIds?: number[]
  currentResponseId?: number
  setCurrentResponseId: React.Dispatch<React.SetStateAction<number | undefined>>
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | undefined>>
  onResponseIdChange: (responseId: number) => void
  type: ToolType
  overviewData: AIToolPrompts | undefined
  refetchOverviewData: () => void
  promptDataId?: number | null
  setPromptDataId: React.Dispatch<
    React.SetStateAction<number | null | undefined>
  >
  openHistorySideBar: boolean
  setOpenHistorySideBar: React.Dispatch<React.SetStateAction<boolean>>
  resetState: () => void
  streamTitle: {
    data: string
    refetch: () => void
  }
}

export const defaultAIToolsContextValue: Context = {
  currentResponseId: undefined,
  setCurrentResponseId: () => {},
  setResponseIds: () => {},
  onResponseIdChange: () => {},
  type: ToolType.lessonPlan,
  overviewData: undefined,
  refetchOverviewData: () => {},
  promptDataId: undefined,
  setPromptDataId: () => {},
  openHistorySideBar: false,
  setOpenHistorySideBar: () => {},
  resetState: () => {},
  streamTitle: {
    data: "",
    refetch: () => {},
  },
}

export const AIToolsContext = React.createContext<Context>(
  defaultAIToolsContextValue
)
