import React from "react"

import MarkdownRenderer from "../../../MarkdownRenderer"
import RegenerateOutput from "../../../RegenerateOutput"

const LessonPlanContent = ({
  output,
  refetchOverviewData,
  isPositiveResponse,
  regenerateResponse,
}: {
  output?: string
  refetchOverviewData: () => void
  isPositiveResponse?: boolean | null
  regenerateResponse?: ((reason: string) => void) | undefined
}) => {
  return (
    <>
      <div className="px-4">
        <MarkdownRenderer showTitle>{output}</MarkdownRenderer>
      </div>
      <RegenerateOutput
        refetchOverviewData={refetchOverviewData}
        copyText={output || ""}
        isPositiveResponse={isPositiveResponse}
        regenerate={{
          regenerateResponse: regenerateResponse,
        }}
      />
    </>
  )
}

export default LessonPlanContent
