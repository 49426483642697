import clsx from "clsx"
import Paper from "features/AItools/components/Paper"

import { ActivityPlannerReturnType } from "../../ActivityPlannerGenerator/useActivityPlanner"
import InputDisplay from "../../helpers/InputDisplay"
import RegenerateOutput from "../RegenerateOutput"
import ToolOutput from "../ToolOutput"
import styles from "./ActivityPlanner.module.css"

const ActivityPlannerOutput = ({
  regenerateResponse,
  isPositiveResponse,
  userVote,
  overviewData,
  responseIds,
  currentResponseId,
  setCurrentResponseId,
  output,
  isLoading,
  onBack,
}: Pick<
  ActivityPlannerReturnType,
  | "regenerateResponse"
  | "isPositiveResponse"
  | "userVote"
  | "overviewData"
  | "responseIds"
  | "currentResponseId"
  | "setCurrentResponseId"
  | "output"
  | "isLoading"
  | "onBack"
>) => {
  return (
    <div className="mb-4 flex flex-col gap-2">
      {overviewData && (
        <InputDisplay
          data={{
            id: overviewData.id,
            promptDataId: overviewData.id,
            curriculum: overviewData?.curriculum,
            duration: overviewData?.duration,
            grade: overviewData?.grade,
            subject: overviewData?.subject,
            topic: overviewData?.topic,
            title: overviewData?.title || "",
            responseIds: overviewData.responseIds || [],
          }}
        />
      )}
      <Paper
        className="p-0"
        endSlot={
          !isLoading ? (
            <RegenerateOutput
              responseIds={responseIds}
              currentResponseId={currentResponseId}
              copyText={output}
              isPositiveResponse={isPositiveResponse}
              onResponseIdChange={responseId => {
                setCurrentResponseId(responseId)
              }}
              userReaction={{
                userReaction: reaction => {
                  userVote.mutate(reaction)
                },
              }}
              regenerate={{
                regenerateResponse: reason => {
                  regenerateResponse.mutate(reason)
                },
              }}
            />
          ) : undefined
        }
      >
        <div className={clsx("overflow-x-auto", styles.tableContainer)}>
          <ToolOutput isLoading={isLoading} className="px-3" onClick={onBack}>
            {output}
          </ToolOutput>
        </div>
      </Paper>
    </div>
  )
}

export default ActivityPlannerOutput
