import { toast } from "@suraasa/placebo-ui"
import { useMutation, UseMutationResult } from "@tanstack/react-query"
import api from "api"
import { HandoutGenerateResponse, ToolType } from "api/resources/aiTools/types"
import { APIResponse } from "api/types"
import { APIError } from "api/utils"
import {
  StreamFetchProps,
  StreamQuery,
  StreamResponseEnum,
  useStreamQuery,
} from "features/AItools/hooks/useStreamQuery"
import { useEffect, useRef, useState } from "react"
import { flushSync } from "react-dom"
import { useNavigate, useSearchParams } from "react-router-dom"
import routes from "routes"
import { trackingService } from "utils/tracking"

const toolType = ToolType.handout

export type HandoutWithLessonPlannerReturnType = {
  currentResponseId: number | null
  setCurrentResponseId: (id: number | null) => void
  promptDataId: string | number | null
  setPromptDataId: React.Dispatch<React.SetStateAction<string | number | null>>
  responseIds: number[] | null
  setResponseIds: React.Dispatch<React.SetStateAction<number[] | null>>

  type: ToolType.handout

  userVote: UseMutationResult<
    never,
    unknown,
    {
      reaction: boolean | undefined | null
      id?: number | null
    },
    unknown
  >
  output: string | undefined
  isLoading: boolean
  stream: Omit<StreamQuery, "refetchAsync"> & {
    refetchAsync: <T>(
      props: Omit<StreamFetchProps<T>, "toolType">
    ) => Promise<void>
  }

  regenerateStream: StreamQuery

  regenerateResponse: UseMutationResult<
    APIResponse<{
      id: number
    }>,
    unknown,
    {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
    },
    unknown
  >
  generateToolWithLessonPlan: UseMutationResult<
    APIResponse<HandoutGenerateResponse>,
    any,
    {
      data: {
        lessonPlanResponse: number
        generatedWithLessonPlan: boolean
        groupId: string
      }
      onSuccess?: (id: number) => void
      skipOnSuccess?: boolean
    },
    unknown
  >
  isPositiveResponse: boolean | null | undefined

  resetState: () => void
  onBack: () => void
  handleCancelApi: () => void
  finalizedOutputs: {
    id: number
    output: string
    isPositiveResponse: boolean | null | undefined
  }[]
}

export const useHandoutWithLessonPlanner = ({
  handoutIds,
  onSuccess,
}: {
  handoutIds?: {
    id: number
    responseIds: number[]
  }[]
  onSuccess?: () => void
}): HandoutWithLessonPlannerReturnType => {
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [isPositiveResponse, setIsPositiveResponse] = useState<
    boolean | null | undefined
  >()
  const [currentResponseId, setCurrentResponseId] = useState<number | null>(
    null
  )
  const [promptDataId, setPromptDataId] = useState<string | number | null>(id)
  const [responseIds, setResponseIds] = useState<number[] | null>(null)

  const [output, setOutput] = useState<string>("")

  const currentValue = useRef("")
  const finalizedOutputs = useRef<
    {
      id: number
      output: string
      isPositiveResponse: boolean | null | undefined
    }[]
  >([])

  const resetState = () => {
    setOutput("")
    setPromptDataId(null)
    setCurrentResponseId(null)
    setIsPositiveResponse(undefined)

    setResponseIds(null)
  }
  const handleCancelApi = () => {
    if (streamOutput.isLoading) streamOutput.handleCancel()
    if (regenerateStream.isLoading) regenerateStream.handleCancel()
  }
  const onBack = () => {
    navigate(routes.aiTools.handout, { replace: true })
    resetState()
    handleCancelApi()
  }

  // This flow is for when user clicks on history item
  // If that is the case then we just fetch the overview and the latest generated output

  useEffect(() => {
    if (handoutIds && handoutIds.length > 0) {
      const processHandoutIds = async () => {
        for (const item of handoutIds) {
          if (item.responseIds.length > 0) {
            await getContent(item.responseIds[0])
          }
        }
      }

      processHandoutIds()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateToolWithLessonPlan = useMutation({
    mutationFn: ({
      data,
    }: {
      data: {
        lessonPlanResponse: number
        generatedWithLessonPlan: boolean
        groupId: string
      }
      onSuccess?: (id: number) => void
      skipOnSuccess?: boolean
    }) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: toolType,
      })
      return api.aiTools.handout.generate({ data })
    },
    onSuccess: async (
      res,
      { onSuccess: onGenSuccess, skipOnSuccess = false }
    ) => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: toolType,
      })
      const { responseId } = res
      if (onGenSuccess) onGenSuccess(responseId)

      await streamOutput.refetchAsync({
        currentResponseId: responseId,
        toolType,
        responseType: StreamResponseEnum.generate,
      })

      if (onSuccess && !skipOnSuccess) {
        onSuccess()
      }
    },
    onError: (err: any) => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: ToolType.handout,
        status_code: err?.statusCode,
        error: JSON.stringify(err),
      })
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) > 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      }
      toast.error("We're unable to process your request", {
        description: "Please try again later",
      })
    },
  })

  const streamOutput = useStreamQuery({
    responseType: StreamResponseEnum.title,
    toolType: toolType,
    onError: () => {
      toast.error("Generation Failed")
    },
    onSuccess(props) {
      if (props?.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      currentValue.current = ""
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
      currentValue.current += chunks
    },
  })

  const regenerateStream = useStreamQuery({
    responseType: StreamResponseEnum.regenerate,
    toolType: toolType,
    onSuccess: props => {
      toast.success("Re-generated Successfully")
      if (props?.currentResponseId) {
        finalizedOutputs.current.push({
          id: props.currentResponseId,
          output: currentValue.current,
          isPositiveResponse: null,
        })
      }
      currentValue.current = ""
    },
    onError: () => {
      toast.error("Re-generation Failed")
    },
    onStreaming(chunks) {
      setOutput(prev => (prev += chunks))
      currentValue.current += chunks
    },
  })

  const userVote = useMutation({
    mutationFn: async ({
      reaction,
      id,
    }: {
      reaction: boolean | undefined | null
      id?: number | null
    }) => {
      return api.aiTools.handout.updateData({
        urlParams: { id: id || currentResponseId!, type: toolType },
        data: {
          isPositiveResponse: reaction,
        },
      })
    },
  })

  const getContent = async (id: number) => {
    // resetStream()
    setIsLoading(true)
    const res = await api.aiTools.handout.retrieveContent({
      urlParams: {
        id: id,
        type: toolType,
      },
    })
    if (res instanceof APIError) {
      toast.error(res.message || "Something went wrong")
      setIsLoading(false)
      return
    }

    const findCurrentIndex = finalizedOutputs.current.findIndex(
      item => item.id === res.id
    )
    if (findCurrentIndex === -1) {
      finalizedOutputs.current.push({
        id,
        output: res.output,
        isPositiveResponse: res.isPositiveResponse,
      })
    } else {
      finalizedOutputs.current[findCurrentIndex].output = res.output
      finalizedOutputs.current[findCurrentIndex].isPositiveResponse =
        res.isPositiveResponse
    }

    setIsPositiveResponse(res.isPositiveResponse)
    setOutput(res.output)
    setIsLoading(false)
    // resetStream()
  }

  const regenerateResponse = useMutation({
    mutationFn: ({
      reason,
      id,
    }: {
      reason: string
      id?: number | null
      onSuccess?: (id: number) => void
    }) => {
      return api.aiTools.handout.regenerateResponse({
        urlParams: {
          id: id || currentResponseId!,
        },
        data: {
          regenerateInstruction: reason,
        },
      })
    },
    onSuccess: async (res, { onSuccess: onRegenSuccess }) => {
      flushSync(() => {
        setCurrentResponseId(res.id)
        setOutput("")
        if (onRegenSuccess) onRegenSuccess(res.id)
      })

      await regenerateStream.refetchAsync({
        currentResponseId: res.id,
        toolType,
        responseType: StreamResponseEnum.regenerate,
      })
      if (onSuccess) onSuccess()
    },
    onError: err => {
      if (err instanceof APIError) {
        if ((err?.statusCode || 0) >= 500) {
          toast.error("We're unable to process your request", {
            description: "Please try again later",
          })
          return
        }
        if (err.errors.message) {
          toast.error(err.errors.message)
          return
        }
      } else {
        toast.error("We're unable to process your request", {
          description: "Please try again later",
        })
      }
    },
  })

  return {
    promptDataId,
    // generateTool,
    setPromptDataId,
    currentResponseId,
    setCurrentResponseId: id => {
      setCurrentResponseId(id)
      if (id) {
        getContent(id)
      }
    },
    responseIds,
    setResponseIds,
    stream: {
      ...streamOutput,
      refetchAsync: ({ currentResponseId: id, responseType }) =>
        streamOutput.refetchAsync({
          currentResponseId: id,
          responseType,
          toolType,
        }),
    },

    regenerateResponse,
    type: toolType,
    userVote,
    regenerateStream,
    output,
    isLoading:
      isLoading || regenerateStream.isLoading || streamOutput.isLoading,
    isPositiveResponse,
    finalizedOutputs: finalizedOutputs.current,
    generateToolWithLessonPlan,

    resetState,
    onBack,
    handleCancelApi,
  }
}
