import {
  Dialog,
  DialogBody,
  DialogContent,
  Sheet,
  SheetBody,
  SheetContent,
} from "@suraasa/placebo-ui"
import { theme, useMediaQuery } from "@suraasa/placebo-ui-legacy"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { useEffect, useState } from "react"
import { getPhoneNumber } from "utils/helpers"

import Congratulations from "./Congratulations"
import Content from "./Content"
import PhoneNumberVerificationDialog from "./PhoneNumberVerificationDialog"
import ProfileDataDialog from "./ProfileDataDialog"

enum DialogEnum {
  mobileVerification = "mobileVerification",
  userDetails = "userDetails",
}

type Props = {
  open: boolean
  handleClose: () => void
  claimCallback?: () => void
}

const WorldTeachersMonthFreeClaimPopup = (props: Props) => {
  const [userPhoneNumber, setUserPhoneNumber] = useState<string | null>(null)

  const [step, setStep] = useState<"initial" | "congratulations">("initial")

  const [openDialog, setOpenDialog] = useState<DialogEnum | null>(null)

  const { isActive } = useSuraasaPlusContext()

  const openPhoneNumberVerificationDialog = () => {
    setOpenDialog(DialogEnum.mobileVerification)
    props.handleClose()
  }

  const openProfileDetailsDialog = () => {
    setOpenDialog(DialogEnum.userDetails)
    props.handleClose()
  }

  const closeDialog = () => {
    setOpenDialog(null)
  }

  const isXsDown = useMediaQuery(theme.breakpoints.down("xs"))

  const userInfo = useQuery({
    queryKey: ["retrieve-solis-user"],
    queryFn: () => api.users.getUser(),
  })

  useEffect(() => {
    const { data } = userInfo || {}
    const { phoneNumber } = data || {}

    if (phoneNumber?.code && phoneNumber?.number) {
      setUserPhoneNumber(getPhoneNumber(phoneNumber))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.data])

  useEffect(() => {
    if (props.open) {
      setStep("initial")
      setOpenDialog(null)
    }
  }, [props.open])

  if (isActive) return null

  const Dialogs = (
    <>
      <PhoneNumberVerificationDialog
        open={openDialog === DialogEnum.mobileVerification}
        onClose={closeDialog}
        onSubmit={openProfileDetailsDialog}
        userPhoneNumber={userPhoneNumber}
        setPhoneNumber={number => {
          setUserPhoneNumber(number)
        }}
      />
      <ProfileDataDialog
        open={openDialog === DialogEnum.userDetails}
        onClose={closeDialog}
        userPhoneNumber={userPhoneNumber}
        onSubmit={() => {
          setStep("congratulations")
          closeDialog()
        }}
      />
    </>
  )

  if (isXsDown) {
    return (
      <>
        {Dialogs}
        <Sheet open={props.open} onOpenChange={props.handleClose}>
          <SheetContent
            side="bottom"
            className="rounded-t-2xl bg-[#0F172A] p-0"
            height={80}
          >
            <SheetBody className="p-0">
              {step === "initial" && (
                <Content
                  hasVerifiedPhoneNumber={
                    !!userInfo.data?.phoneNumberVerifiedAt
                  }
                  handleClose={props.handleClose}
                  openProfileDetailsDialog={openProfileDetailsDialog}
                  openPhoneNumberVerificationDialog={
                    openPhoneNumberVerificationDialog
                  }
                />
              )}
              {step === "congratulations" && (
                <Congratulations handleClose={props.handleClose} />
              )}
            </SheetBody>
          </SheetContent>
        </Sheet>
      </>
    )
  }

  return (
    <>
      {Dialogs}
      <Dialog open={props.open} onOpenChange={props.handleClose}>
        <DialogContent className="rounded-2xl">
          <DialogBody className="rounded-2xl p-0">
            {step === "initial" && (
              <Content
                hasVerifiedPhoneNumber={!!userInfo.data?.phoneNumberVerifiedAt}
                handleClose={props.handleClose}
                openProfileDetailsDialog={openProfileDetailsDialog}
                openPhoneNumberVerificationDialog={
                  openPhoneNumberVerificationDialog
                }
              />
            )}
            {step === "congratulations" && (
              <Congratulations handleClose={props.handleClose} />
            )}
          </DialogBody>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default WorldTeachersMonthFreeClaimPopup
