export enum NotificationActionName {
  REDIRECT_TO_DISCUSSION_DETAIL = "REDIRECT_TO_DISCUSSION_DETAIL",
  REDIRECT_TO_DISCUSSIONS_DETAIL = "REDIRECT_TO_DISCUSSIONS_DETAIL",
  REDIRECT_TO_TRENDING_DISCUSSIONS = "REDIRECT_TO_TRENDING_DISCUSSIONS",
  REDIRECT_TO_ASSESSMENT_DETAIL = "REDIRECT_TO_ASSESSMENT_DETAIL",
  REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL = "REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL",
  REDIRECT_TO_CLASS_DETAIL = "REDIRECT_TO_CLASS_DETAIL",
  REDIRECT_TO_CENTRE_SCHEDULE_DETAIL = "REDIRECT_TO_CENTRE_SCHEDULE_DETAIL",
  REDIRECT_TO_MY_LIBRARY = "REDIRECT_TO_MY_LIBRARY",
  REDIRECT_TO_COURSE_CERTIFICATE_DETAIL = "REDIRECT_TO_COURSE_CERTIFICATE_DETAIL",
  REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL = "REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL",
  REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL = "REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL",
  REDIRECT_TO_HOMEPAGE = "REDIRECT_TO_HOMEPAGE",
  REDIRECT_TO_ORDER_DETAIL = "REDIRECT_TO_ORDER_DETAIL",
  OPEN_LEARNING_ITEM_OVERVIEW = "OPEN_LEARNING_ITEM_OVERVIEW",
  ITEMS_ALLOCATED = "ITEMS_ALLOCATED",
  TRANSACTION_SUCCESSFUL = "TRANSACTION_SUCCESSFUL",
  TRANSACTION_FAILED = "TRANSACTION_FAILED",
  OPEN_JOB = "OPEN_JOB",

  // From Marketing
  OPEN_SUBMISSION = "OPEN_SUBMISSION",
}
export type NotificationAction =
  | {
      name:
        | NotificationActionName.REDIRECT_TO_DISCUSSION_DETAIL
        | NotificationActionName.REDIRECT_TO_DISCUSSIONS_DETAIL
      data: { userDiscussionId: string; commentId?: string; replyId?: string }
    }
  | {
      name: NotificationActionName.OPEN_LEARNING_ITEM_OVERVIEW
      data: {
        slug: string
        learningItemId: string
        learningItemType: string
      }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_TRENDING_DISCUSSIONS
    }
  | {
      name: NotificationActionName.REDIRECT_TO_ASSESSMENT_DETAIL

      data: { assessmentUuid: string }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL
      data: {
        courseSlugs?: string[]
        submissionUuid?: string
        assignmentId: number
      }
    }
  | {
      name: NotificationActionName.OPEN_SUBMISSION
      data: {
        courseSlug: string
        submissionId?: string
        assignmentId: string
      }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_CLASS_DETAIL
      data: { classId: string }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_CENTRE_SCHEDULE_DETAIL
      data: { centerUuid: string }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_MY_LIBRARY
    }
  | {
      name: NotificationActionName.ITEMS_ALLOCATED
    }
  | {
      name: NotificationActionName.REDIRECT_TO_COURSE_CERTIFICATE_DETAIL
      data: {
        courseId: string
        courseName?: string
        certificateNumber?: number
      }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL
      data: {
        certificationId: string
        certificationName: string
        certificateNumber: string
      }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL
      data: {
        qualificationId: string
        qualificationName: string
        certificateNumber: string
      }
    }
  | {
      name: NotificationActionName.REDIRECT_TO_HOMEPAGE
    }
  | {
      name:
        | NotificationActionName.REDIRECT_TO_ORDER_DETAIL
        | NotificationActionName.TRANSACTION_FAILED
      data: { orderId: string }
    }
  | {
      name: NotificationActionName.TRANSACTION_SUCCESSFUL
      data: { orderId: string }
    }
  | {
      name: NotificationActionName.TRANSACTION_FAILED
      data: { orderId: string }
    }
  | {
      name: NotificationActionName.OPEN_JOB
      data: {
        city: string
        country: string
        subject: string
        jobTitle: string
        schoolName: string
        schoolSlug: string
        jobPostSlug: string
        pgctlLearner: string
        schoolBranch: string
      }
    }

export type Notification = {
  id: number
  title: string | null
  text: string
  // event: string | null // useless
  tags: string[]
  dateOpened: string
  dateRead: string
  dateCreated: string
  action: NotificationAction | null | Record<string, never>
}

export type NotificationResponse = {
  notifications: Notification[]
  unreadCount: number
}

export type NotificationsOverview = Record<string, number>
