"use client"

import { Button, IconButton, toast, Typography } from "@suraasa/placebo-ui"
import { useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { ResourceItem } from "api/resources/learningItems/types"
import MysteryBox from "assets/Fallback/mystery-box.svg"
import Fallback from "components/Fallback"
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "components/ShadBreadcrumbs"
import { getRestriction } from "features/AccessManagement/CheckRestrictions"
import { Lock } from "iconoir-react"
import { ChevronRight, Download } from "lucide-react"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { downloadFile, getAuthInfo, getPlatformURL } from "utils/helpers"

import { useCourseDetails } from "../Course"
import { useQualificationDetails } from "../Qualification"
import Doc from "./icons/doc.svg"
import Excel from "./icons/excel.svg"
import File from "./icons/file.svg"
import Folder from "./icons/folder.svg"
import Image from "./icons/image.svg"
import Pdf from "./icons/pdf.svg"
import Ppt from "./icons/powerpoint.svg"
import Text from "./icons/txt.svg"

const formatFileSize = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

  if (bytes === 0) return "0 Bytes"
  if (bytes === 1) return "1 Byte"

  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${parseFloat((bytes / Math.pow(1024, i)).toFixed(0))} ${sizes[i]}`
}

const getFileIcon = (resource: ResourceItem) => {
  const type = (() => {
    if (!resource.file) {
      return "folder"
    }
    const url = new URL(resource.file)
    const extension = url.pathname.split(".").pop()?.toLowerCase()
    return extension
  })()

  switch (type) {
    case "folder":
      return <img src={Folder} alt={type} />
    case "pdf":
      return <img src={Pdf} alt={type} />
    case "doc":
    case "docx":
      return <img src={Doc} alt={type} />
    case "ppt":
    case "pptx":
      return <img src={Ppt} alt={type} />
    case "xls":
    case "xlsx":
      return <img src={Excel} alt={type} />
    case "txt":
      return <img src={Text} alt={type} />
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "svg":
    case "webp":
    case "avif":
      return <img src={Image} alt={type} />
    default:
      return <img src={File} alt={type} />
  }
}

type Props = {
  course?: boolean
  qualification?: boolean
}

export const AdditionalResources = (props: Props) => {
  const { course } = useCourseDetails()
  const { qualification } = useQualificationDetails()

  const { learningItemType } = useParams() as { learningItemType: string }

  const itemType = props.course ? "course" : learningItemType
  const itemId = itemType === "course" ? course.id : qualification.id

  const { data, isLoading, isSuccess, isError } = useQuery({
    queryKey: queries.learningItems.listResources(itemType, itemId).queryKey,
    queryFn: () =>
      api.learningItems.listResources({ urlParams: { itemType, itemId } }),
    select: data => data.filter(item => item.resources.length > 0),
  })

  const [currentPath, setCurrentPath] = useState<ResourceItem[]>([])

  const getCurrentFolder = () => {
    return currentPath[currentPath.length - 1].resources || []
  }

  const navigateToFolder = (folder: ResourceItem) => {
    setCurrentPath([...currentPath, folder])
  }

  const navigateToBreadcrumb = (index: number) => {
    setCurrentPath(currentPath.slice(0, index + 1))
  }

  const handleFileOpen = async (file: ResourceItem) => {
    if (!file.file) return

    if (file.file.includes("https://proxy.lirn.net/IntlTeachersUniv")) {
      const newTab = window.open()
      if (newTab) {
        const response = await fetch(
          getPlatformURL("suraasa", "/api/lirn-access"),
          // "http://localhost:300/api/lirn-access",
          {
            method: "POST",
            headers: {
              "Content-type": "application/json",
            },
            body: JSON.stringify({
              username: getAuthInfo()?.user.username,
            }),
          }
        )

        const data = await response.json()
        const url = data?.url

        if (url) {
          newTab.location.href = url
        } else {
          toast.error("Something went wrong", {
            description: "Please contact care@suraasa.com",
          })
        }
      }
    } else {
      downloadFile(file.file, file.name)
    }
  }

  const renderBreadcrumbs = () => (
    <Breadcrumb>
      <BreadcrumbList>
        {currentPath.length > 0 && (
          <>
            <BreadcrumbItem>
              <BreadcrumbLink onClick={() => setCurrentPath([])} asChild>
                <a color="black" className="cursor-pointer p-0 hover:underline">
                  Home
                </a>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        )}

        {currentPath.map((folder, index) => (
          <>
            <BreadcrumbItem key={folder.name}>
              <BreadcrumbLink
                onClick={() => navigateToBreadcrumb(index)}
                asChild
              >
                <a color="black" className="cursor-pointer p-0 hover:underline">
                  {folder.name}
                </a>
              </BreadcrumbLink>
            </BreadcrumbItem>
            <BreadcrumbSeparator />
          </>
        ))}
      </BreadcrumbList>
    </Breadcrumb>
  )

  const renderFileItem = (file: ResourceItem, isRestricted = false) => {
    const isFile = file.file ? true : false

    if (!isFile && file.resources?.length === 0) {
      return null
    }

    return (
      <button
        key={file.name}
        className="flex w-full items-center justify-between p-2 text-start hover:bg-gray-50"
        onClick={
          isRestricted
            ? undefined
            : isFile
            ? () => handleFileOpen(file)
            : () => navigateToFolder(file)
        }
      >
        <div className="flex items-center space-x-2">
          {getFileIcon(file)}
          <div>
            <p className="text-sm font-medium text-gray-900">{file.name}</p>
            {file.fileSize ? (
              <p className="text-sm text-gray-500">
                {formatFileSize(file.fileSize)}
              </p>
            ) : null}
          </div>
        </div>
        {isRestricted ? (
          <Lock />
        ) : (
          <>
            {file.file ? (
              <Button variant="text">View</Button>
            ) : (
              <IconButton>
                <ChevronRight />
              </IconButton>
            )}
          </>
        )}
      </button>
    )
  }

  return (
    <div className="pb-5">
      <div>
        <Typography variant="title3" className="mb-2">
          Resources
        </Typography>

        {(isError || isLoading) && (
          <Fallback
            data={{
              image: MysteryBox,
              title: "Something went wrong!",
              description: "Please contact care@suraasa.com",
            }}
            className="my-9 sm:my-20 md:my-18"
            hasError={isError}
            isLoading={isLoading}
          />
        )}
      </div>

      {isSuccess && (
        <div>
          <div className="mb-1">{renderBreadcrumbs()}</div>

          {currentPath.length === 0 && (
            <div className="space-y-2">
              {data.map(item => (
                <div key={item.learningItemId}>
                  <Typography variant="title4" className="mb-1 text-muted">
                    {item.learningItemName}
                  </Typography>

                  <div className="w-full rounded-lg border bg-white shadow-sm">
                    <div className="divide-y">
                      {item.resources.map(i =>
                        renderFileItem(i, getRestriction(item))
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}

          {currentPath.length > 0 && (
            <div>
              <div className="w-full rounded-lg border bg-white shadow-sm">
                <div className="divide-y">
                  {getCurrentFolder().map(i => renderFileItem(i))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}
