import { Button, Select, TextField, Typography } from "@suraasa/placebo-ui"
import { LessonPlanProForm } from "api/resources/aiTools/types"
import {
  BLOOMS_TAXONOMY_OPTIONS,
  DURATION_MINUTES,
} from "features/AItools/helper"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { Controller, useForm } from "react-hook-form"
import { Link } from "react-router-dom"

import Paper from "../../../Paper"
import { LessonPlannerReturnType } from "../useLessonPlanner"

type Form = LessonPlanProForm

const ProForm = (props: Pick<LessonPlannerReturnType, "generateProTool">) => {
  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<Form>({
    defaultValues: {
      bloomTaxonomyLevel: ["remembering"],
      book: "NCERT",
      country: "India",
      curriculum: "CBSE",
      duration: "00:45:00",
      grade: "10th",
      numberOfClasses: "2",
      subject: "Biology",
      topic: "Cell Structure",
    },
  })

  const { ensurePlusAccess } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(async data => {
    if (!data.book) data.book = undefined
    if (!data.topic) data.topic = undefined
    if (!data.numberOfClasses) data.numberOfClasses = undefined

    ensurePlusAccess(async () => {
      await props.generateProTool.mutateAsync({ data, setError })
    })
  })

  return (
    <Paper>
      <form onSubmit={onSubmit} className="flex w-full flex-col gap-2">
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Class/Grade"
            placeholder="Ex: 10th Grade"
            errors={errors.grade?.message}
            {...register("grade", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("curriculum", {
              required: { value: true, message: "Required" },
            })}
            label="Curriculum"
            placeholder="Ex: IGCSE"
            errors={errors.curriculum?.message}
          />
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Subject"
            placeholder="Ex: Biology"
            errors={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <Controller
            control={control}
            name="duration"
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                label="Class Duration"
                errors={errors.duration?.message}
                options={DURATION_MINUTES}
                placeholder="60 minutes"
                value={DURATION_MINUTES.find(c => c.value === value)}
                mountOnBody
                onBlur={onBlur}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{ required: { value: true, message: "Required" } }}
          />
        </div>

        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            label="Chapter/Topic"
            placeholder="Ex: Cell Structure"
            errors={errors.topic?.message}
            {...register("topic", {
              required: { value: true, message: "Required" },
            })}
          />

          <TextField
            {...register("book")}
            label="Book Name"
            placeholder="Ex: Biology for Cambridge IGCSE"
            errors={errors.book?.message}
          />
        </div>
        <div className="flex flex-wrap gap-2 md:flex-nowrap">
          <TextField
            {...register("country", {
              required: { value: true, message: "Required" },
            })}
            label="Country"
            placeholder="Ex: United States"
            errors={errors.country?.message}
            helperText="We use this to tailor the lesson plan to your region"
          />
          <div className="flex flex-[49%] shrink-0 flex-col">
            <Controller
              control={control}
              name="bloomTaxonomyLevel"
              render={({ field: { onChange, onBlur, value } }) => (
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  label="Bloom's Taxonomy Level"
                  errors={errors.bloomTaxonomyLevel?.message}
                  options={BLOOMS_TAXONOMY_OPTIONS}
                  placeholder="Ex: Understand, Apply"
                  value={
                    value &&
                    BLOOMS_TAXONOMY_OPTIONS.filter(c => value.includes(c.value))
                  }
                  mountOnBody
                  onBlur={onBlur}
                  onChange={val => onChange(val.map(x => x.value))}
                />
              )}
              rules={{ required: { value: true, message: "Required" } }}
            />
            <Link
              to="https://www.suraasa.com/blog/learning-taxonomies"
              target="_blank"
            >
              <Typography
                className="mt-0.5 text-interactive-500 hover:underline"
                variant="smallBody"
              >
                What is Bloom&apos;s Taxonomy?
              </Typography>
            </Link>
          </div>
        </div>

        <TextField
          {...register("numberOfClasses")}
          label="Number of Lessons"
          placeholder="Ex: 4"
          errors={errors.numberOfClasses?.message}
        />

        <Button type="submit" loading={isSubmitting}>
          Generate Lesson Plan
        </Button>
      </form>
      {/* <AiToolsLoading type={toolType} open={isSubmitting} /> */}
    </Paper>
  )
}

export default ProForm
