import { Typography } from "@suraasa/placebo-ui"
import {
  Accordion,
  AccordionDetails,
  AccordionHeader,
} from "@suraasa/placebo-ui-legacy"
import {
  GradeBookScore,
  ScoreItemType,
} from "api/resources/learningItems/types"
import clsx from "clsx"
import { Lock, NavArrowDown } from "iconoir-react"
import { useState } from "react"
import { learningItemSlugs } from "utils/constants"

import { getAdjustedScore } from "../scoreHelpers"

const getTitle = (item: ScoreItemType) => {
  switch (item) {
    case "attendance":
      return "Attendance"
    case "assignment":
      return "Assignment Score"
    case "live_assessment":
      return "Assessment Score"
    case "self_paced":
      return "Self paced Score"

    default:
      return ""
  }
}
const Item = ({
  title,
  value,
  highlight,
}: {
  title: string
  value: string
  highlight?: boolean
}) => {
  return (
    <div className="flex grow flex-col gap-0.25">
      <span
        className={clsx("text-base font-bold leading-[20.8px]", {
          "text-primary-500": highlight,
          "text-onSurface-900": !highlight,
        })}
      >
        {value}
      </span>
      <span className="text-xs font-medium leading-[15.6px] text-onSurface-500">
        {title}
      </span>
    </div>
  )
}

const getGrade = (score: number | null, slug: string) => {
  if (score === null) return "-"

  if (slug === learningItemSlugs.med) {
    if (score >= 3.6 && score <= 4.0) {
      return "A"
    } else if (score >= 3.2 && score < 3.6) {
      return "B"
    } else if (score >= 2.8 && score < 3.2) {
      return "C"
    } else if (score >= 2.4 && score < 2.8) {
      return "D"
    } else if (score >= 2.0 && score < 2.4) {
      return "E"
    } else if (score >= 0 && score < 2.0) {
      return "F"
    } else {
      return "-"
    }
  }

  if (score >= 9.0 && score <= 10.0) {
    return "A"
  } else if (score >= 8.0 && score < 9.0) {
    return "B"
  } else if (score >= 7.0 && score < 8.0) {
    return "C"
  } else if (score >= 6.0 && score < 7.0) {
    return "D"
  } else if (score >= 5.0 && score < 6.0) {
    return "E"
  } else if (score >= 0 && score < 5.0) {
    return "F"
  } else {
    return "-"
  }
}

const CourseItem = ({
  data,
  slug,
}: {
  slug: string
  data: GradeBookScore["items"][0]
}) => {
  const [open, setOpen] = useState(false)
  const isDisable = data.score === null

  const gradePoints = (() => {
    if (data.score === null) return null
    if (slug == learningItemSlugs.med) {
      return getAdjustedScore(data.score.gpa, slug)
    }
    return data.score.gpa
  })()

  const totalPoints =
    data.score && gradePoints && data.score.creditsEarned !== null
      ? gradePoints * data.score.creditsEarned
      : 0

  return (
    <Accordion
      className="overflow-hidden rounded-xl border border-solid border-surface-200"
      expanded={isDisable ? false : open}
      onChange={() => {
        if (!isDisable) setOpen(!open)
      }}
    >
      <AccordionHeader
        className={clsx(
          "!p-2 text-start text-white [&>.AccordionHeader-content]:w-full",
          {
            "bg-onSurface-800 ": !isDisable,
            "bg-onSurface-400 !cursor-default": isDisable,
          }
        )}
        icon={
          <div className="flex size-3 items-center justify-center rounded-full bg-white">
            {isDisable ? (
              <Lock className="size-2 text-onSurface-400" />
            ) : (
              <NavArrowDown className="size-2 text-onSurface-900" />
            )}
          </div>
        }
      >
        <div className="inline-grid flex-col items-start justify-between gap-1 sm:flex sm:flex-row sm:items-center">
          <Typography
            className="max-w-[75%] truncate sm:max-w-[328px]"
            variant="title4"
          >
            {data.name}
          </Typography>
          {!isDisable && (
            <div className="mr-0 flex flex-wrap items-center gap-2  sm:mr-1">
              <span className="block text-xs font-medium leading-[15.6px]">
                <span className="pr-0.5 text-base font-bold leading-[20.8px]">
                  {data.score?.creditsEarned && gradePoints
                    ? gradePoints.toFixed(1)
                    : "-"}
                </span>
                Grade Points
              </span>
              <span className="block  text-xs font-medium leading-[15.6px]">
                <span className="pr-0.5 text-base font-bold leading-[20.8px]">
                  {data.score?.creditsEarned
                    ? getGrade(gradePoints, slug)
                    : "-"}
                </span>
                Grade
              </span>
              <span className="block  text-xs font-medium leading-[15.6px]">
                <span className="pr-0.5 text-base font-bold leading-[20.8px]">
                  {data.score?.creditsEarned ?? "-"}
                </span>
                Credits Earned
              </span>
            </div>
          )}
        </div>
      </AccordionHeader>

      <AccordionDetails
        className="!p-0"
        // className={clsx({
        //   "!px-2 !py-1.5":
        //     data.score === null || data.score.userItemScores.length === 0,
        //   "!px-3 !py-2.5": data.score !== null,
        // })}
      >
        {(data.score === null || data.score.userItemScores.length === 0) && (
          <div className="px-2 py-1.5">
            <span className="text-xs font-normal leading-[15.6px] text-onSurface-500">
              Course is in progress, you will be able to see your score, grade,
              credits after you complete this course.
            </span>
          </div>
        )}

        {data.score && data.score.userItemScores.length > 0 && (
          <div className="flex h-full min-h-5 flex-col gap-3 px-3 py-2.5 sm:flex-row sm:items-center">
            {totalPoints > 0 && (
              <div className="flex flex-col gap-3 sm:flex-row">
                <Item
                  value={Math.round(totalPoints).toString()}
                  highlight
                  title="Total Points"
                />
                <div className="h-px w-full rounded-full bg-onSurface-200  sm:h-full sm:min-h-5 sm:w-px " />
              </div>
            )}

            <div className="grid grid-cols-2 grid-rows-2 gap-3 sm:grid-cols-4 sm:grid-rows-1">
              {data.score?.userItemScores.map((item, index) => {
                const score =
                  item.score < 0 ? null : `${Math.round(item.score * 100)}%`

                return (
                  <Item
                    value={score || "-"}
                    title={getTitle(item.scoreType)}
                    key={index}
                  />
                )
              })}
            </div>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default CourseItem
