import { Button, Select, TextArea, TextField } from "@suraasa/placebo-ui"
import { RubricsForm, ToolType } from "api/resources/aiTools/types"
import Paper from "features/AItools/components/Paper"
import { getToolName, pointScaleOptions } from "features/AItools/helper"
import { Controller, useForm } from "react-hook-form"

import { RubricsReturnType } from "../useRubrics"

const toolType = ToolType.rubrics

type Props = Pick<RubricsReturnType, "generateTool">

const BasicForm = ({ generateTool }: Props) => {
  const {
    register,
    control,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<RubricsForm>({
    defaultValues: {
      grade: "11th",
      curriculum: "CBSE",
      objective: "Evaluate Answers for Simple Harmonic Motion",
      assignmentDescription: `Ask students to define SHM in their own words.
Have students identify examples of SHM in everyday life.
Give students a set of problems to solve using the equations of SHM`,
      pointOfScale: 3,
      additionalCustomisation: `Marking should be strict and learners should be able to apply the concepts in real life scenarios`,
    },
  })

  const onSubmit = handleSubmit(async data => {
    if (!data.additionalCustomisation) {
      data.additionalCustomisation = undefined
    }
    await generateTool.mutateAsync({ data, setError })
  })
  return (
    <>
      <Paper>
        <form onSubmit={onSubmit} className="flex flex-col gap-2">
          <div className="flex flex-wrap gap-2 md:flex-nowrap">
            <TextField
              {...register("grade", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Class/Grade"
              placeholder="Ex: 11th Grade"
              errors={errors.grade?.message}
            />
            <TextField
              {...register("curriculum", {
                required: { value: true, message: "Required" },
              })}
              required
              label="Curriculum"
              placeholder="Ex: ICSE"
              errors={errors.curriculum?.message}
            />
          </div>

          <TextField
            {...register("objective", {
              required: { value: true, message: "Required" },
            })}
            required
            placeholder="Ex: Evaluate Answers for Simple Harmonic Motion"
            label="Standard/Objective"
            errors={errors.objective?.message}
          />
          <TextArea
            {...register("assignmentDescription", {
              required: { value: true, message: "Required" },
            })}
            rows={3}
            required
            label="Description"
            placeholder={`Ex: Ask students to define SHM in their own words.\nHave students identify examples of SHM in everyday life.\nGive students a set of problems to solve using the equations of SHM`}
            errors={errors.assignmentDescription?.message}
          />
          <Controller
            control={control}
            name="pointOfScale"
            render={({ field: { value, onChange, onBlur } }) => (
              <Select
                required
                errors={errors.pointOfScale?.message}
                label="Performance Level"
                options={pointScaleOptions}
                placeholder="Ex: 5"
                mountOnBody
                onBlur={onBlur}
                value={pointScaleOptions.find(c => c.value === value)}
                onChange={val => onChange(val?.value)}
              />
            )}
            rules={{
              required: { value: true, message: "Required" },
            }}
          />
          <TextArea
            {...register("additionalCustomisation")}
            label="Additional Customisation (optional)"
            placeholder="Ex: Marking should be strict and learners should be able to apply the concepts in real life scenarios"
            errors={errors.additionalCustomisation?.message}
          />
          <Button type="submit" loading={isSubmitting}>
            Generate {getToolName(toolType)}
          </Button>
        </form>
      </Paper>
    </>
  )
}

export default BasicForm
