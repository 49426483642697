import { Divider } from "@suraasa/placebo-ui-legacy"
import ProfileContext from "features/Profile/context"
import { Fragment, useContext } from "react"
import { pluralize } from "utils/helpers"

import Section from "../Section"
import SectionContent from "../Section/SectionContent"
import SectionHeading from "../Section/SectionHeading"
import SectionTitle from "../Section/SectionTitle"
import ItemRow from "./ItemRow"

const MasterclassesAttended = () => {
  const { masterclasses } = useContext(ProfileContext)

  if (masterclasses.length === 0) {
    return null
  }

  return (
    <>
      <Section>
        <SectionHeading
          heading={
            <SectionTitle
              title={`${pluralize(
                "Masterclass Attended",
                masterclasses.length,
                {
                  skipCount: true,
                  plural: "Masterclasses Attended",
                }
              )}`}
              itemCount={masterclasses.length}
            />
          }
          xPadding={2}
        />
        <SectionContent xPadding={2}>
          <div className="flex flex-col gap-1.5">
            {masterclasses.map((item, index) => (
              <Fragment key={item.masterclass.slug}>
                <ItemRow {...item} />

                {index < masterclasses.length - 1 && <Divider weight="light" />}
              </Fragment>
            ))}
          </div>
        </SectionContent>
      </Section>
    </>
  )
}

export default MasterclassesAttended
