import { getServiceURL } from "api/utils"
import { SERVICE_PREFIX } from "utils/constants"

const getCelestialURL = getServiceURL(SERVICE_PREFIX.celestial)
const getCastorURL = getServiceURL(SERVICE_PREFIX.castor)

export const urls = {
  listBatches: () => `/v1/batches/users/coordinators/`,
  createReattemptRequest: () =>
    getCelestialURL(`/v1/centres/reattempt-requests/`),
  allocateReattemptRequest: () =>
    getCastorURL(`/v1/planners/users/assignments/reallocate/`),
  retrieveReattemptData: userPlannerItemId =>
    `/v1/users/planners/items/${userPlannerItemId}/`,
}

export default urls
