import { Typography } from "@suraasa/placebo-ui"
import {
  Button,
  IconButton,
  TextField,
  theme,
  useMediaQuery,
} from "@suraasa/placebo-ui-legacy"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "api"
import { queries } from "api/queries"
import { RetrieveCoverLetter, ToolType } from "api/resources/aiTools/types"
import { APIError } from "api/utils"
import LikeSvg from "assets/AITools/LikeSvg"
import clsx from "clsx"
import LoadingOverlay from "components/LoadingOverlay"
import { useSuraasaPlusContext } from "features/AItools/PRO/context/SuraasaPlusContext"
import { ArrowLeft } from "iconoir-react"
import { useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { formatDate, handleErrors } from "utils/helpers"
import { trackingService } from "utils/tracking"

import AiToolsLoading from "../../AiToolsLoading"
import { ExportOutputButton } from "../GenerationOutput/Export/ContentActions"
import TitleBar from "./TitleBar"
import { getFileName } from "./utils/helper"
import ViewCoverLetterDialog from "./ViewCoverLetterDialog"

const Vote = {
  LIKED: true,
  DISLIKED: false,
  NEUTRAL: null,
}

type FormType = Pick<
  RetrieveCoverLetter,
  "position" | "schoolName" | "subject" | "recipientName"
>

const Form = ({
  coverLetterId,
  updateCoverLetterId,
  onBack,
}: {
  coverLetterId?: number
  updateCoverLetterId: (id: number) => void
  onBack: () => void
}) => {
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"))

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormType>()

  const [openCoverLetterDialog, setOpenCoverLetterDialog] = useState(false)
  const [userVote, setUserVote] = useState<boolean | null | undefined>()

  const retrieveCoverLetter = useQuery({
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryKey: queries.aiTools.promptData(ToolType.coverLetter, coverLetterId!)
      .queryKey,
    queryFn: () =>
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      api.aiTools.coverLetter.retrieve({ urlParams: { id: coverLetterId! } }),
    enabled: Boolean(coverLetterId),
    onSuccess: res => {
      reset({
        position: res.position,
        subject: res.subject,
        schoolName: res.schoolName,
        recipientName: res.recipientName,
      })
      setUserVote(res.isPositiveResponse)
    },
  })

  const fileName = getFileName({
    fullName: retrieveCoverLetter.data?.fullName,
    schoolName: retrieveCoverLetter.data?.schoolName,
  })

  const { mutate: mutateUserReaction } = useMutation({
    mutationFn: (reaction: boolean | null | undefined) =>
      api.aiTools.coverLetter.reaction({
        urlParams: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: coverLetterId!,
        },
        data: {
          isPositiveResponse: reaction,
        },
      }),
  })
  const {
    mutate: createCoverLetter,
    isLoading,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (data: FormType) => {
      trackingService.trackEvent("ai_tools_generation_started", {
        tool_type: ToolType.coverLetter,
        model: "pro",
      })
      return api.aiTools.coverLetter.create({
        data: {
          schoolName: data.schoolName,
          position: data.position,
          subject: data.subject,
          ...(data.recipientName && { recipientName: data.recipientName }),
        },
      })
    },
    onSuccess: res => {
      trackingService.trackEvent("ai_tools_generation_success", {
        tool_type: ToolType.coverLetter,
        model: "pro",
      })
      if (res.id) updateCoverLetterId(res.id)
    },
    onError: err => {
      trackingService.trackEvent("ai_tools_generation_failed", {
        tool_type: ToolType.coverLetter,
        model: "pro",
      })
      if (err instanceof APIError) {
        handleErrors(err, { setter: setError })
      }
    },
  })

  const upVote = () => {
    mutateUserReaction(Vote.LIKED)
    setUserVote(Vote.LIKED)
  }
  const downVote = () => {
    mutateUserReaction(Vote.DISLIKED)
    setUserVote(Vote.DISLIKED)
  }

  const { isActive, showGetPlusSheet } = useSuraasaPlusContext()

  const onSubmit = handleSubmit(data => {
    if (!isActive) {
      showGetPlusSheet()
      return
    }

    createCoverLetter(data)
  })

  useEffect(() => {
    if (coverLetterId) {
      setOpenCoverLetterDialog(true)
    }
  }, [coverLetterId])

  return (
    <div className="lg:pointer-events-auto">
      <ViewCoverLetterDialog
        open={openCoverLetterDialog}
        data={retrieveCoverLetter.data}
        onClose={() => {
          setOpenCoverLetterDialog(false)
        }}
      />
      <AiToolsLoading type={ToolType.coverLetter} open={isLoading} />
      <TitleBar
        onClick={onBack}
        startAdornment={<ArrowLeft />}
        label="Back"
        endSlot={
          coverLetterId && retrieveCoverLetter.data?.dateCreated ? (
            <Typography variant="smallBody" className="text-onSurface-400">
              Created on{" "}
              {formatDate(
                retrieveCoverLetter.data?.dateCreated,
                "do MMMM yyyy"
              )}
            </Typography>
          ) : undefined
        }
      />
      <div className="px-2 pb-2">
        {retrieveCoverLetter.isFetching && <LoadingOverlay />}
        {!coverLetterId && (
          <Typography variant="largeBody" className="mb-2">
            Please enter the following details about the job you are applying
            for
          </Typography>
        )}
        <form className="flex flex-col space-y-2" onSubmit={onSubmit}>
          <TextField
            inputLabelProps={{ required: true }}
            label="Position"
            fullWidth
            disabled={Boolean(coverLetterId)}
            placeholder="Ex - Head of Department"
            error={Boolean(errors.position)}
            helperText={errors.position?.message}
            {...register("position", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            inputLabelProps={{ required: true }}
            label="Subject"
            fullWidth
            disabled={Boolean(coverLetterId)}
            placeholder="Ex - Mathematics"
            error={Boolean(errors.subject)}
            helperText={errors.subject?.message}
            {...register("subject", {
              required: { value: true, message: "Required" },
            })}
          />
          <TextField
            inputLabelProps={{ required: true }}
            label="School Name"
            fullWidth
            disabled={Boolean(coverLetterId)}
            placeholder="Ex - ABC Public School, Dubai"
            error={Boolean(errors.schoolName)}
            helperText={errors.schoolName?.message}
            {...register("schoolName", {
              required: { value: true, message: "Required" },
            })}
          />
          {coverLetterId && !retrieveCoverLetter.data?.recipientName ? null : (
            <TextField
              label="Name of Recipient"
              fullWidth
              disabled={Boolean(coverLetterId)}
              placeholder="Ex - Rajat Sharma or Head of HR"
              error={Boolean(errors.recipientName)}
              helperText={errors.recipientName?.message}
              {...register("recipientName")}
            />
          )}

          {!coverLetterId && (
            <div className="flex items-center justify-end space-x-2 pt-3">
              {/* <Button
                onClick={() => {
                  if (coverLetterId === undefined) {
                    reset()
                  }
                  onClose()
                }}
                size="sm"
                variant="text"
              >
                Cancel
              </Button> */}
              <Button type="submit" size="sm">
                Create Cover Letter
              </Button>
            </div>
          )}
        </form>
        {coverLetterId && (
          <div className="flex items-center justify-between space-x-2 pt-3">
            <div className="flex items-center gap-1">
              <IconButton onClick={upVote}>
                <LikeSvg
                  className={clsx({
                    "fill-onSurface-900": userVote === Vote.LIKED,
                  })}
                />
              </IconButton>
              <IconButton onClick={downVote}>
                <LikeSvg
                  className={clsx("-scale-100", {
                    "fill-onSurface-900": userVote === Vote.DISLIKED,
                  })}
                />
              </IconButton>
            </div>

            <div className="flex items-center gap-0.75">
              <ExportOutputButton
                title={fileName}
                currentResponseId={coverLetterId}
                toolType={ToolType.coverLetter}
              >
                <Button size="sm" rounded color="black">
                  Download
                </Button>
              </ExportOutputButton>
              {isMdDown && (
                <Button
                  size="sm"
                  rounded
                  color="black"
                  onClick={() => {
                    setOpenCoverLetterDialog(true)
                  }}
                >
                  View
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Form
