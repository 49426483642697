import { ToolType } from "api/resources/aiTools/types"
import { urls } from "api/resources/aiTools/urls"
import { useState } from "react"
import { AUTHORIZATION_HEADER_PREFIX } from "utils/config"
import { getCurrentToken } from "utils/platform"

export type StreamQueryProps<T = unknown> = {
  onSuccess?: (props?: StreamFetchProps<T>) => void
  onError?: () => void
  onStreaming?: (chunks: string, props?: StreamFetchProps<T>) => void
  disableUpdate?: boolean
  toolType: ToolType
  responseType: StreamResponseEnum
}

export enum StatusType {
  success = "success",
  failed = "failed",
}

export enum StreamResponseEnum {
  "generate" = "generate",
  "title" = "title",
  "regenerate" = "regenerate",
}

export type StreamFetchProps<T> = {
  currentResponseId: number
  toolType: ToolType
  responseType: StreamResponseEnum
  variables?: T
}

export type StreamQuery = {
  data: string
  isError: boolean
  isLoading: boolean
  isSuccess: boolean
  refetchAsync: <T>(props: StreamFetchProps<T>) => Promise<void>
  handleCancel: () => void
}

export const useStreamQuery = (props: StreamQueryProps): StreamQuery => {
  const [streamedData, setStreamedData] = useState("")
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [abortController, setAbortController] =
    useState<AbortController | null>(null)

  const baseURL = import.meta.env.VITE_API_ENDPOINT

  const fetchStream = async <T,>({
    currentResponseId: id,
    responseType,
    variables,
  }: StreamFetchProps<T>) => {
    const controller = new AbortController()
    setAbortController(controller)

    const url: string = (() => {
      switch (props.toolType) {
        case ToolType.lessonPlan:
        case ToolType.lessonPlanPro:
          return urls.lessonPlan.stream(id)
        case ToolType.rubrics:
          return urls.rubrics.stream(id)
        case ToolType.quiz:
          return urls.quiz.stream(id)
        case ToolType.handout:
          return urls.handout.stream(id)
        case ToolType.narration:
          return urls.narration.stream(id)
        case ToolType.subjectiveAssessment:
          return urls.subjectiveAssessment.stream(id)
        case ToolType.assignment:
          return urls.assignment.stream(id)
        case ToolType.activityPlanner:
          return urls.activityPlanner.stream(id)
        case ToolType.videoSummary:
          return urls.videoSummary.stream(id)
        case ToolType.unitPlanner:
          return urls.unitPlanner.stream(id)
        default:
          throw new Error("Invalid tool type")
      }
    })()
    try {
      setIsLoading(true)
      setStreamedData("")
      const token = getCurrentToken()

      const tokenString = `${AUTHORIZATION_HEADER_PREFIX} ${token}`

      const response = await fetch(
        baseURL
          .concat("/")
          .concat(url)
          .concat(
            `?${encodeURIComponent("response_type")}=${encodeURIComponent(
              responseType
            )}`
          ),
        {
          method: "GET",
          headers: {
            Accept: "application/json, text/plain, */*",
            Authorization: tokenString,
          },
          signal: controller.signal,
        }
      )

      if (!response.body) {
        throw new Error("ReadableStream not yet supported in this browser.")
      }

      const reader = response.body.getReader()
      const decoder = new TextDecoder("utf-8")
      let done = false

      while (!done) {
        const { value, done: streamDone } = await reader.read()
        if (streamDone) {
          done = streamDone
          setIsLoading(false)
        }
        const chunk = decoder.decode(value, { stream: !done })
        if (props?.onStreaming)
          props.onStreaming(chunk, {
            currentResponseId: id,
            toolType: props.toolType,
            responseType,
            variables,
          })

        if (chunk.includes("[[__ERROR__]]")) {
          setIsError(true)
        }
        setStreamedData(prevData => prevData + chunk)
      }
      if (done) {
        setIsSuccess(true)

        if (props?.onSuccess) {
          props?.onSuccess({
            currentResponseId: id,
            toolType: props.toolType,
            responseType,
            variables,
          })
        }
      }
    } catch (error) {
      setIsError(true)
      if (props?.onError) props.onError()
      console.error("Error fetching stream:", error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    if (abortController) {
      abortController.abort()
      setAbortController(null)
    }
  }
  return {
    data: streamedData,
    refetchAsync: fetchStream,
    isLoading,
    isSuccess,
    isError,
    handleCancel,
  }
}
