import axios from "api/axios"
import {
  APIResponse,
  Config,
  ConfigWithURLParams,
  NoContentType,
} from "api/types"
import { formatErrorResponse, formatSuccessResponse } from "api/utils"

import {
  Activity,
  Article,
  ClassDetailsType,
  CreatedArticleBookmark,
  CreatedVideoBookmark,
  Enrollment,
  GradeBookScore,
  IncompleteItemsCount,
  LearningItem,
  LearningItemDetails,
  LearningRestrictions,
  PlannerAccess,
  PlannerListItem,
  PlannerOverview,
  Resource,
  UpcomingPlannerItem,
} from "./types"
import { urls } from "./urls"

export default {
  plannerAccess: async ({
    urlParams: { itemType, itemId },
  }: ConfigWithURLParams): Promise<APIResponse<PlannerAccess>> => {
    try {
      const res = await axios.get(urls.retrievePlanner(itemType, itemId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listPlannerItems: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<LearningItem[]>> => {
    try {
      const res = await axios.get(urls.listPlannerItems(urlParams.plannerId), {
        params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getLastAccessedItem: async (
    options?: Config
  ): Promise<APIResponse<PlannerListItem | null>> => {
    try {
      const res = await axios.get(urls.getLastAccessedItem(), {
        params: options?.params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getLastUnlockedItem: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<LearningItem>> => {
    try {
      const res = await axios.get(
        urls.getLastUnlockedItem(urlParams.learningItemId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getItemById: async ({
    params,
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<LearningItem>> => {
    try {
      const res = await axios.get(
        urls.getItemById(urlParams.userPlannerItemId),
        { params }
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getNextItem: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<
    APIResponse<LearningItem | { plannerCompleted: true }>
  > => {
    try {
      const res = await axios.get(urls.getNextItem(urlParams.userPlannerItemId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getPreviousItem: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<LearningItem>> => {
    try {
      const res = await axios.get(
        urls.getPreviousItem(urlParams.userPlannerItemId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  updateUserPlannerItem: async ({
    urlParams,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.put(
        urls.updateUserPlannerItem(urlParams.userPlannerItemId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getArticle: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<Article>> => {
    try {
      const res = await axios.get(urls.getArticle(urlParams.articleId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  createArticleBookmark: async ({
    data,
  }: Config): Promise<APIResponse<CreatedArticleBookmark>> => {
    try {
      const res = await axios.post(urls.articleBookmarks(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  removeArticleBookmark: async ({
    urlParams,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(
        urls.articleBookmarkDetail(urlParams.courseId, urlParams.articleId),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  createVideoBookmark: async ({
    data,
  }: Config): Promise<APIResponse<CreatedVideoBookmark>> => {
    try {
      const res = await axios.post(urls.videoBookmarks(), data)
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  removeVideoBookmark: async ({
    urlParams,
    data,
  }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
    try {
      const res = await axios.delete(
        urls.videoBookmarkDetail(urlParams.id),
        data
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getPlannerList: async (
    options?: Config
  ): Promise<APIResponse<PlannerListItem[]>> => {
    try {
      const res = await axios.get(urls.listPlanner(), {
        params: options?.params,
      })
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listEnrollments: async (): Promise<APIResponse<Enrollment[]>> => {
    try {
      const res = await axios.get(urls.enrollmentsList())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getUpcomingPlannerItems: async ({
    urlParams: { learningItemType, learningItemId },
  }: ConfigWithURLParams): Promise<APIResponse<UpcomingPlannerItem[]>> => {
    try {
      const res = await axios.get(
        urls.upcomingItems(learningItemType, learningItemId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  getLearningItemDetails: async ({
    urlParams: { itemType, itemSlug },
  }: ConfigWithURLParams): Promise<APIResponse<LearningItemDetails>> => {
    try {
      const res = await axios.get(urls.getDetails(itemType, itemSlug))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  listResources: async ({
    urlParams: { itemType, itemId },
  }: ConfigWithURLParams): Promise<APIResponse<Resource[]>> => {
    try {
      const res = await axios.get(urls.listResources(itemType, itemId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  overview: async ({
    urlParams: { itemType, itemId },
  }: ConfigWithURLParams): Promise<APIResponse<PlannerOverview>> => {
    try {
      const res = await axios.get(urls.overview(itemType, itemId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  incompleteItemsCount: async ({
    urlParams: { itemType, itemId },
  }: ConfigWithURLParams): Promise<APIResponse<IncompleteItemsCount>> => {
    try {
      const res = await axios.get(urls.incompleteItems(itemType, itemId))
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  activity: {
    start: async ({ data }: Config): Promise<APIResponse<Activity>> => {
      const params = {
        fields: [
          "activity.id",
          "activity.title",
          "activity.activity_type",
          "activity.question_text",
          "activity.activity_items",
          "attempt_item_responses",
        ],
      }
      try {
        const res = await axios.post(urls.startActivity(), data, { params })
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getItemResponse: async ({
      urlParams,
    }: ConfigWithURLParams): Promise<Blob> => {
      try {
        const res = await axios.get(
          urls.getItemResponse(urlParams.itemResponseId),
          {
            responseType: "blob",
          }
        )
        return res.data
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    markAnswer: async ({
      data,
      urlParams,
      headers,
    }: ConfigWithURLParams): Promise<APIResponse<NoContentType>> => {
      try {
        const res = await axios.post(
          urls.markActivityAnswer(urlParams.attemptId),
          data,
          {
            headers,
          }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    submit: async ({
      data,
      urlParams,
    }: ConfigWithURLParams): Promise<APIResponse<Activity>> => {
      const params = {
        fields: [
          "activity.id",
          "activity.title",
          "activity.activity_type",
          "activity.question_text",
          "activity.activity_items",
          "attempt_item_responses",
        ],
      }
      try {
        const res = await axios.put(
          urls.submitActivity(urlParams.attemptId),
          data,
          { params }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  listRestrictions: async (): Promise<APIResponse<LearningRestrictions[]>> => {
    try {
      const res = await axios.get(urls.restrictions())
      return formatSuccessResponse(res)
    } catch (e) {
      return formatErrorResponse(e)
    }
  },

  classes: {
    list: async ({
      urlParams: { learningItemType, learningItemId },
      params,
    }: ConfigWithURLParams): Promise<APIResponse<ClassDetailsType[]>> => {
      try {
        const res = await axios.get(
          urls.listClasses(learningItemType, learningItemId),
          { params }
        )
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
    getLastAttended: async (): Promise<
      APIResponse<{
        id: number
        classSchedule: {
          id: number
          classId: {
            id: number
            name: string
          }
          startTime: string
          endTime: string
        }
        uuid: string
        status: string
        user: string
      }>
    > => {
      try {
        const res = await axios.get(urls.latestClassAttendance())
        return formatSuccessResponse(res)
      } catch (e) {
        return formatErrorResponse(e)
      }
    },
  },
  retrieveGradeBookScore: async ({
    urlParams,
  }: ConfigWithURLParams): Promise<APIResponse<GradeBookScore>> => {
    try {
      const res = await axios.get(
        urls.retrieveGradeBookScore(urlParams.qualificationId)
      )
      return formatSuccessResponse(res)
    } catch (e) {
      throw formatErrorResponse(e)
    }
  },
}
