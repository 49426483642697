import { AuthData } from "api/resources/users/types"
import posthog from "posthog-js"
import { getAuthInfo } from "utils/helpers"
import { webengage } from "utils/webengage"

import { GA } from "./ga"
import { getUTMParams } from "./utmParams"

type Service = "WebEngage" | "GA" | "PostHog"

const allServices: Service[] = ["WebEngage", "GA", "PostHog"]

export const trackingService = {
  initUser: (
    authInfo: {
      user: Pick<AuthData["user"], "firstName" | "lastName" | "uuid" | "email">
    } & Pick<AuthData, "shadow">,
    services = allServices
  ) => {
    if (authInfo.shadow) return

    GA.setUserId(authInfo.user.uuid)

    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.login(authInfo.user.uuid)
        webengage.user.setAttribute("we_email", authInfo.user.email)
        webengage.user.setAttribute("we_first_name", authInfo.user.firstName)
        webengage.user.setAttribute("we_last_name", authInfo.user.lastName)
      }
    } catch (e) {
      console.warn("Error initialising user on WebEngage", e)
    }
  },
  trackEvent: (eventName: string, data?: object, services = allServices) => {
    if (getAuthInfo()?.shadow) return

    const utmParams = getUTMParams()

    // Adding UTM params into all events (picked up from cache if not present in the URL)
    data = {
      ...utmParams,
      ...(data || {}),
    }

    // console.log(`Tracking ${eventName} on services `, services, data)
    try {
      if (services.includes("PostHog")) {
        posthog.capture(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on PostHog: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (services.includes("GA")) {
        // @ts-expect-error we can't type this
        GA.trackEvent(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on GA: ${eventName}`, data)
      console.warn(e)
    }

    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.track(eventName, data)
      }
    } catch (e) {
      console.warn(`Error tracking event on WebEngage: ${eventName}`, data)
      console.warn(e)
    }
  },
  setUserAttribute: (key: string, value: any, services = allServices) => {
    if (getAuthInfo()?.shadow) return

    // console.log(`> Tracking user attribute: ${key}=${value}`)
    try {
      if (webengage && services.includes("WebEngage")) {
        webengage.user.setAttribute(key, value)
      }
    } catch (e) {
      console.warn(`Error tracking user attribute on WebEngage: ${key}`, value)
      console.warn(e)
    }
  },
}
